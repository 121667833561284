import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fold-out-presenter',
    host: {
        class: 'w-full inline-block',
    },
  templateUrl: './fold-out-presenter.component.html',
})
export class FoldOutPresenterComponent implements OnInit {
    nestedOpenValue: boolean = false;
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/375160856/Fold+out"
  constructor() { }

  ngOnInit() {
  }

}
