<div class="component-nav w-full sm:w-1/6 pt-10 pl-5 float-left inline-block" *ngIf="loggedIn">
    <h2 class="font-medium text-lg">Komponenter</h2>
    <ul class="pl-0 list-none">
        <li class="list-reset py-2 text-sm">
            <a routerLink="buttons" routerLinkActive="font-medium text-primary">Knapper</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="multi-button-presenter" routerLinkActive="font-medium text-primary">Multi Knap</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="cards" routerLinkActive="font-medium text-primary">Cards</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="custom-input" routerLinkActive="font-medium text-primary">Custom input</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="toggle-presenter" routerLinkActive="font-medium text-primary">Toggle</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="scrollspy" routerLinkActive="font-medium text-primary">Scroll</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="inputfield" routerLinkActive="font-medium text-primary">Input felter</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="labeled-toggle-presenter" routerLinkActive="font-medium text-primary">Toggle med titel</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="modal-presenter" routerLinkActive="font-medium text-primary">Modals</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="accordion-presenter" routerLinkActive="font-medium text-primary">Accordion</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="list-fold-out-presenter" routerLinkActive="font-medium text-primary">List options</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="fold-out-presenter" routerLinkActive="font-medium text-primary">Fold out</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="loader-presenter" routerLinkActive="font-medium text-primary">Loaders</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="datepicker-presenter" routerLinkActive="font-medium text-primary">Datepicker</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="bottom-menu" routerLinkActive="font-medium text-primary">Bottom menu</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="growl-presenter" routerLinkActive="font-medium text-primary">Growls</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="slider-presenter" routerLinkActive="font-medium text-primary">Slider</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="login-presenter" routerLinkActive="font-medium text-primary">Login komponent</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="icons" routerLinkActive="font-medium text-primary">Ikoner</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="text-presenter" routerLinkActive="font-medium text-primary">Fonte</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="chart-circular-percentage" routerLinkActive="font-medium text-primary">Chart - Circular
                percentage</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="locker-presenter" routerLinkActive="font-medium text-primary">Locker (The locksmith)</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="group-list-presenter" routerLinkActive="font-medium text-primary">Group list (Categorize with style)</a>
        </li>
        <li class="list-reset py-2 text-sm">
            <a routerLink="group-list-checked-presenter" routerLinkActive="font-medium text-primary">Group list checked (Extension - Categorize with checked style)</a>
        </li>
    </ul>
</div>


<section class=" mx-auto w-full sm:w-5/6 float-left inline-block pl-5">
    <div class="flex flex-wrap components w-full">
        <router-outlet class="w-full inline-block"></router-outlet>
    </div>
</section>
<app-growler></app-growler>