import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './user';
import { LoginModel } from './loginModel';
import * as jwt_decode from 'jwt-decode';

const jwtdecode = jwt_decode;
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        var user = this.currentUserSubject.value;

        if (user) {
          const timeout = parseInt(localStorage.getItem("expires_at"));
            if (Number.isNaN(timeout) || timeout < new Date().getTime()) {
                this.logout();
            }
        }

        return this.currentUserSubject.value;
    }

    login(model: LoginModel) {
        return this.http.post<any>(`/api/auth/login`, model)
            .toPromise()
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('id_token', user.token);
                localStorage.setItem("expires_at", user.expiration);
                this.currentUserSubject.next(user);
                return user;
            });
    }

    public get currentUserClaims(): any {
        const token = localStorage.getItem('id_token');
        return jwtdecode(token);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.currentUserSubject.next(null);
    }
}
