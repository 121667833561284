import { Directive, Input, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { LoaderService } from "./loader.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
    selector: '[incomLoader]'
})
export class LoaderDirective implements OnInit, OnDestroy {
    @Input('incomLoader') loaderArea: string;
    @Input('loaderSize') size: "md" | "sm" = "md";
    @Input('loaderType') type: "spinner" | "buildings" = "spinner";
    @Input('showBackground') background: boolean = true;

    constructor(private loaderService: LoaderService, private el: ElementRef) {

    }

    private subscriptionBreaker = new Subject();

    ngOnInit(): void {
        (this.el.nativeElement as HTMLElement).classList.add("loaderArea");
        this.loaderService.loaders$
            .pipe(
                takeUntil(this.subscriptionBreaker)
            )
            .subscribe(loaders => {
                var matchingLoaders = loaders.filter(loader => loader.area === this.loaderArea);
                if (matchingLoaders.length > 0) {
                    this.activateLoader();

                }
                else {
                    this.deactivateLoader();
                }
            });
    }

    ngOnDestroy(): void {
        this.deactivateLoader();
        this.subscriptionBreaker.next({});
    }

    /**
     * Removes classes to make the loader inactive.
     */
    private deactivateLoader(): void {
        (this.el.nativeElement as HTMLElement).classList.remove("showLoader", "no-loader-background", "small-loader");
        if (this.type == "buildings") {
            (this.el.nativeElement as HTMLElement).classList.remove("building-loader");
            const svgLoader = (this.el.nativeElement as HTMLElement).querySelector("#building-loader");      
            if (svgLoader) {
                svgLoader.remove();
            }
        }
        
    }

    /**
     * Adds classes to make the loader active.
     */
    private activateLoader(): void {
        (this.el.nativeElement as HTMLElement).classList.add("showLoader");
        if (this.type != "spinner") {
            (this.el.nativeElement as HTMLElement).classList.add("building-loader");
            (this.el.nativeElement as HTMLElement).innerHTML +=
                ` <div id="building-loader" class="absolute inset-0 w-full h-full">
                <svg id="building-light" class="loader-building absolute top-0 bottom-0 left-0 right-0 m-auto" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1151.91 512">
                    <path class="floor" d="M827.57,480h20a12,12,0,0,1,12,12v20h-448V492a12,12,0,0,1,12-12h404Z" />
                    <path class="building-path-high" d="M543.57,107v40a12,12,0,0,1-12,12h-40a12,12,0,0,1-12-12V107a12,12,0,0,1,12-12h40A12,12,0,0,1,543.57,107Zm116-12h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V107A12,12,0,0,0,659.57,95Zm-128,96h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V203A12,12,0,0,0,531.57,191Zm128,0h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V203A12,12,0,0,0,659.57,191Zm-128,96h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V299A12,12,0,0,0,531.57,287Zm128,0h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V299A12,12,0,0,0,659.57,287Zm-276,193V24a24,24,0,0,1,24-24h336a24,24,0,0,1,24,24V480Zm352,0V32h-320V480h128V395a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v85Z" />
                    <path class="floor" d="M416,480h20a12,12,0,0,1,12,12v20H0V492a12,12,0,0,1,12-12H416Z" />
                    <path class="building-path-medium" d="M192,298v40a12,12,0,0,1-12,12H140a12,12,0,0,1-12-12V298a12,12,0,0,1,12-12h40A12,12,0,0,1,192,298Zm116-12H268a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V298A12,12,0,0,0,308,286ZM32,480V215a24,24,0,0,1,24-24H392a24,24,0,0,1,24,24V480Zm352,0V223H64V480H192V395a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v85Z" />
                    <path class="floor" d="M1119.91,480h20a12,12,0,0,1,12,12v20h-448V492a12,12,0,0,1,12-12h404Z" />
                    <path  class="building-path-small" d="M895.91,202.5v40a12,12,0,0,1-12,12h-40a12,12,0,0,1-12-12v-40a12,12,0,0,1,12-12h40A12,12,0,0,1,895.91,202.5Zm116-12h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12v-40A12,12,0,0,0,1011.91,190.5Zm-128,96h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12v-40A12,12,0,0,0,883.91,286.5Zm128,0h-40a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12v-40A12,12,0,0,0,1011.91,286.5ZM735.91,480V119.5a24,24,0,0,1,24-24h336a24,24,0,0,1,24,24V480Zm352,0V127.5h-320V480h128V395a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v85Z" />
                </svg>
            </div>
            `
        }
        else {

        }
        if (this.size != "md") {
            this.addSmallLoaderClass();
        }
        if (this.background === false) {
            this.removeBackground();
        }
    }

    /**
     * Adds classes to make the loader active.
     */
    private addSmallLoaderClass(): void {
        (this.el.nativeElement as HTMLElement).classList.add("small-loader");
    }

    /**
   * Adds classes to make the loader active.
   */
    private removeBackground(): void {
        (this.el.nativeElement as HTMLElement).classList.add("no-loader-background");
    }

}
