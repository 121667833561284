import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-button-presenter',
  templateUrl: './multi-button-presenter.component.html',
})
export class MultiButtonPresenterComponent implements OnInit {
  hasCopied: boolean;
  hasCopiedDown: boolean;
  hasCopiedUp: boolean;
  hasCopiedSettings: boolean;
  docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/579043329/Multiknap"

  constructor() { }

  ngOnInit() {
  }

  generateMultiFoldUpStringContent() {
    let textbox = document.createElement('textarea')
    textbox.style.position = "fixed";
    textbox.value = `
        <multi-button class="inline-block" [color]="'text-white'">
          <ng-container mainButton>
            <button-component [type]="'accept'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium truncate pr-6 text-sm">Gem og aktiver</span>
            </button-component>
          </ng-container>
          <div class="flex md:flex-row-reverse flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'outline'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        `
    document.body.appendChild(textbox);
    textbox.focus();
    textbox.select();
    document.execCommand('copy');
    document.body.removeChild(textbox);
    this.hasCopiedUp = true;
    setTimeout(() => { this.hasCopiedUp = false }, 3000);
  }

  generateMultiFoldDownStringContent() {
    let textbox = document.createElement('textarea')
    textbox.style.position = "fixed";
    textbox.value = `
        <multi-button class="inline-block" [foldUp]="false" [hDirection]="'right'" [color]="'text-green-600'">
          <ng-container mainButton>
            <button-component [type]="'accept'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium truncate pr-6 text-sm">Gem og aktiver</span>
            </button-component>
          </ng-container>
          <div class="flex md:flex-row flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        `
    document.body.appendChild(textbox);
    textbox.focus();
    textbox.select();
    document.execCommand('copy');
    document.body.removeChild(textbox);
    this.hasCopiedDown = true;
    setTimeout(() => { this.hasCopiedDown = false }, 3000);
  }

  generateMultiSettingsStringContent() {
    let textbox = document.createElement('textarea')
    textbox.style.position = "fixed";
    textbox.value = `
        <multi-button class="inline-block" [color]="'text-green-600'" [settingsOnMobile]="true" [settingsButtonName]="'Muligheder'" [settingsButtonType]="'outline'">
          <div class="flex md:flex-row-reverse flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'outline'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        `
    document.body.appendChild(textbox);
    textbox.focus();
    textbox.select();
    document.execCommand('copy');
    document.body.removeChild(textbox);
    this.hasCopiedSettings = true;
    setTimeout(() => { this.hasCopiedSettings = false }, 3000);
  }
}
