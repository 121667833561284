<div>
    <group-list class="flex flex-col" [useTransclusion]="true">
        <div *GroupedListCategory="let category" class="flex justify-between items-center pr-6">
            <custom-input [style]="'solid'" [color]="'text-primary'" [bg]="'bg-gray-400'" [type]="'checkbox'"
                          [indeterminate]="category.checkedIndeterminate">
                <input class="w-0 h-0 hidden" [(ngModel)]="category.visualChecked" type="checkbox" (change)="onCategoryCheckBoxChange($event, category)" />
                <span class="ml-2 text-xl font-medium leading-none">{{category.name}}</span>
            </custom-input>

            <button class="btn btn-primary" (click)="whatAmI(category)">CLick me</button>
            <p>{{category.checkedCount}} kriterier valgt</p>
        </div>

        <div *GroupedListCategoryItem="let category">
            <group-list [categoryClass]="''"
                        [categoryItemClass]="'flex flex-col space-y-1 pl-6'"
                        class="flex flex-col"
                        *ngIf="category.subCategories && category.subCategories.length > 0; else noSubCategories"
                        [model]="getSubCategoryModel(category)"
                        [useTransclusion]="true">
                <div *GroupedListCategory="let subCategory" class="flex justify-between items-center pr-6">
                    <custom-input [style]="'solid'" [color]="'text-primary'" [bg]="'bg-gray-400'" [type]="'checkbox'"
                                  [indeterminate]="subCategory.checkedIndeterminate">
                        <input class="w-0 h-0 hidden" [(ngModel)]="subCategory.visualChecked" type="checkbox"
                               (change)="onCategoryCheckBoxChange($event, subCategory)" />
                        <span class="ml-2">{{subCategory.name}}</span>
                    </custom-input>
                    <p>{{subCategory.checkedCount}} kriterier valgt</p>
                </div>

                <div *GroupedListCategoryItem="let category">

                    <custom-input [style]="'solid'" [color]="'text-primary'" [bg]="'bg-gray-400'" [type]="'checkbox'">
                        <input class="w-0 h-0 hidden" [(ngModel)]="category.visualChecked"
                               (change)="onCategoryItemCheckBoxChange($event, category)" type="checkbox" />
                        <span class="ml-2">{{category.name}}</span>
                    </custom-input>
                </div>
            </group-list>
            <ng-template #noSubCategories>
                <custom-input [style]="'solid'" [color]="'text-primary'" [bg]="'bg-gray-400'" [type]="'checkbox'">
                    <input class="w-0 h-0 hidden" [(ngModel)]="category.visualChecked" (change)="onCategoryItemCheckBoxChange($event, category)" type="checkbox" />
                    <span class="ml-2">{{category.name}}</span>
                </custom-input>
            </ng-template>
        </div>
    </group-list>
</div>

<div>
    <button class="btn btn-primary" (click)="isValid()">isValid</button>
</div>
