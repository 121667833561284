<div class="center text-center px-3 sm:px-0 bg-gray-600 py-32" incom-loader loaderArea="LoginArea">
  <h1 class="uppercase pb-5 text-3xl text-white">Log ind</h1>

  <div class="sm:max-w-lg w-full mx-auto rounded-lg p-6 relative">
    <div class="absolute inset-0 bg-black opacity-75 rounded-lg"></div>
    <div role="tabpanel" class="relative z-10">
      <div class="tab-header flex text-gray-600">
        <span class="flex p-2 border-b  w-1/2" [ngClass]="activeTab == 'password' ? 'text-primary border-primary' : 'border-gray-800'">
          <a (click)="activeTab = 'password'" class="text-xs mx-auto font-bold">Interessentnr./e-mail</a>
        </span>
        <span class="flex p-2 border-b w-1/2" [ngClass]="activeTab == 'nemid' ? 'text-primary border-primary' : 'border-gray-800'">
          <a (click)="activeTab = 'nemid'" class="mx-auto text-xs font-bold">Nem-ID</a>
        </span>
      </div>
      <div class="my-4 px-2">
        <div *ngIf="activeTab == 'password'">
          <div class="flex items-baseline flex-wrap justify-between">
            <div class="mb-5 input-wrapper input-border-radius bg-white inline-block">
              <input id="username" name="username"
                     class="input-border-radius input-base focus:border-primary w-56"
                     type="text" placeholder=" " [(ngModel)]="model.username">
              <label class="input-label-base" for="username"> Interessentnummer eller email </label>
            </div>

            <div class="mb-5 input-wrapper input-border-radius bg-white inline-block">
              <input *ngIf="!passwordRevealed" id="password" name="password"
                     class="input-border-radius input-base focus:border-primary"
                     type="password" placeholder=" " [(ngModel)]="model.password">
              <input *ngIf="passwordRevealed" id="password" name="password"
                     class="input-border-radius input-base focus:border-primary"
                     type="text" placeholder=" " [(ngModel)]="model.password">
              <label class="input-label-base" for="password"> Adgangskode</label>
              <a class="absolute inset-y-0 right-0 flex items-center pr-3 text-xs sm:text-sm text-gray-800 z-20 cursor-pointer" (click)="togglePasswordReveal()"> {{passwordRevealed ? 'Skjul': 'Vis'}} </a>
            </div>
          </div>

          <div class="w-full text-center">
            <button class="btn btn-primary btn-solid inline-block btn-border-radius">
              <span class="font-medium">Log ind</span>
              <fa-icon class="text-base ml-2 leading-none" [icon]="['fal', 'long-arrow-alt-right']"> </fa-icon>
            </button>
          </div>
        </div>
        <p *ngIf="activeTab == 'nemid'" class="text-white">login-with-nem-id er under udvikling</p>
      </div>
    </div>


  </div>

  <div [hidden]="activeTab != 'password'" class="z-10 text-white mt-5 text-xs sm:text-basen cursor-pointer">
    <a>Jeg har glemt min adgangskode</a>
  </div>

</div>
