<div [ngClass]="[cardType, width]" class="{{color}} card-border-radius">
  <div [ngClass]="{'card-small-icon': type == 'small', 'card-big-icon': type == 'big'}">
    <ng-content select="[card-icon]"></ng-content>
  </div>
  <div [ngClass]="{'card-small-body': type == 'small', 'card-big-body': type == 'big'}">
    <ng-content select="[card-body]"></ng-content>
  </div>
  <div [ngClass]="{'card-small-footer': type == 'small'}">
    <ng-content select="[card-footer]"></ng-content>
  </div>
</div>
