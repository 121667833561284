<div class="w-full py-12 inline-block">
  <h1>
    Toggle med titel
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="mt-10 w-full sm:w-1/2">
    <div class=" py-5 inline-block w-full">

      <labeled-toggle>
        <ng-container>
          <input type="radio" class="switch-input hidden" name="view" [(ngModel)]="toggle" value="true" id="email" checked>
          <label for="email" class="switch-label relative z-10 float-left w-1/2 text-center cursor-pointer switch-label-off" [ngClass]="toggle == true ? 'text-white' : 'text-gray-700'">
            <fa-icon class="pr-1" [icon]="['fal','file-pdf']"></fa-icon>
            E-mail
          </label>

          <input type="radio" class="switch-input hidden" name="view" [(ngModel)]="toggle" value="false" id="letter">
          <label for="letter" class="switch-label relative z-10 float-left w-1/2 text-center cursor-pointer switch-label-on" [ngClass]="toggle == false ? 'text-white' : 'text-gray-700'">
            <fa-icon class="pr-1" [icon]="['fal','envelope']"></fa-icon>
            Brev
          </label>
        </ng-container>
      </labeled-toggle>
    </div>
    <div class="text-primary">
      <fa-icon (click)="generateStringContent()" class="text-xl" [icon]="[hasCopied ? 'fas' : 'fal', 'copy']"></fa-icon>
    </div>
    <p class="mb-5 font-medium text-xs">{{hasCopied ? 'Den er kopieret' : 'Klik på ovenstående knap for at kopier toggle!'}}</p>
  </div>
</div>




