<div class=" list-item-base xl:relative w-full border-gray-700 pt-2 mb-8 xl:mb-0 xl:pt-0 xl:overflow-x-hidden text-xs md:text-sm">
  <div class="flex xl:flex-col xl:w-3/12 w-full border-b border-gray-700 xl:border-b-0 items-start">
    <div class="w-1/3 xl:w-full xl:hidden  min-h-8 flex items-center list-header text-gray-700">
      <p class="inline-block">Spørgsmål</p>
    </div>
    <div class="xl:w-full w-2/3 pr-10 flex xl:pr-0 min-h-8 flex items-center xl:pr-3 leading-snug ">
      <fa-icon class="xl:inline-block hidden" [icon]="['fal', 'question-circle']"></fa-icon>
      <p class="inline-block xl:pl-1">{{item.Question}}</p>
    </div>
  </div>
  <div class="flex xl:flex-col xl:w-2/12 w-full border-b border-gray-700 xl:border-b-0 items-start">
    <div class="w-1/3 xl:w-full xl:hidden  min-h-8 flex items-center  list-header text-gray-700">
      <p class="inline-block">Type</p>
    </div>
    <div class="xl:w-full w-2/3  flex min-h-8 flex items-center">
      <img src="{{item.Type}}" class=" h-6" alt="checkbox questionType" />
    </div>
  </div>
  <div class="flex xl:flex-col xl:w-2/12 w-full border-b border-gray-700 xl:border-b-0">
    <div class="w-1/3 xl:full xl:hidden min-h-8 flex items-center list-header text-gray-700 ">
      <p class="inline-block">Følgespørgsmål</p>
    </div>
    <div class="xl:full w-2/3 min-h-8 flex items-center">
      {{item.FollowQuestion}}
    </div>
  </div>

  <fold-out [isOpen]="showMore" class="w-full xl:w-3/12">
    <div class=" flex flex-row w-full flex-wrap">

      <div class="flex xl:flex-col  xl:w-1/3 w-full border-b border-gray-700 xl:border-b-0 items-start">
        <div class="w-1/3 xl:w-full xl:hidden min-h-8 flex items-center  list-header text-gray-700">
          <p class="inline-block">Resourcer</p>
        </div>
        <div class="xl:w-full w-2/3 min-h-8 flex items-center">
          <p class="inline-block">{{item.Resources}}</p>
        </div>
      </div>
      <div class="flex xl:flex-col xl:w-2/3 w-full border-gray-700  items-start">
        <div class="w-1/3 xl:w-full xl:hidden  min-h-8 flex items-center list-header text-gray-700">
          <p class="inline-block">Oprettet af</p>
        </div>
        <div class="xl:w-full w-2/3 min-h-8 flex items-center">
          <p class="inline-block">{{item.Creator}}</p>
        </div>
      </div>
    </div>
  </fold-out>
  <div class="w-full xl:w-1/12 flex justify-end absolute xl:static top-0 right-0 ml-auto">
    <fa-icon class="inline-block text-xl px-2 " (click)="foldThis = !foldThis" [icon]="['fal', 'ellipsis-v']"></fa-icon>
    <fa-icon class="inline-block text-xl px-2 xl:hidden" (click)="showMore = !showMore" [icon]="['fal', showMore ? 'angle-up' : 'angle-down']"></fa-icon>

    <line-fold-out *ngIf="foldThis" @foldOutParent [width]="'w-1/2 md:w-1/5 xl:w-56'">
      <div class="bg-gray-900 pl-3 text-white text-sm flex flex-col xl:py-0 py-3 xl:flex-row xl:items-center xl:h-full xl:rounded-l-full rounded-lg pr-10 xl:pr-0">
        <button class="leading-snug flex justify-between xl:items-center w-full xl:w-auto pb-1 xl:pb-0 xl:hover:bg-gray-800">
          Slet
          <fa-icon class="inline-block px-2" [icon]="['fal', 'trash']"></fa-icon>
        </button>
        <button class="leading-snug xl:border-l w-full xl:w-auto xl:border-t-0 border-t border-gray-800 xl:pl-2 flex justify-between xl:items-center pt-1 xl:pt-0 xl:hover:bg-gray-800">
          Vis
          <fa-icon class="inline-block px-2" [icon]="['fal', 'edit']"></fa-icon>
        </button>
        <fa-icon class="inline-block text-base text-white px-2 ml-3 absolute xl:relative ml-auto px-2 ml-3 top-0 right-0" (click)="foldThis = !foldThis" [icon]="['fal', 'times']"></fa-icon>
      </div>
    </line-fold-out>

  </div>
</div>
