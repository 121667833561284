
<div class="w-full py-12 inline-block">
    <h1>
        Toggle
    </h1>
    <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
        <p class="font-medium"> Se dokumentation</p>
    </a>
    <div class="mt-10 w-full sm:w-1/2 flex flex-wrap">
        <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-40" [ngClass]="{'active': selectedStyle.length > 0}">
            <select [(ngModel)]="selectedStyle" class="input-border-radius input-base w-40">
                <option *ngFor="let style of styles" [value]="style.Name">{{style.Name}}</option>
            </select>
            <label class="input-label-base">Vælg stil på linjen</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
        </div>

        <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-64 {{selectedPreColor}}" [ngClass]="{'active': selectedPreColor.length > 0}">
            <select [(ngModel)]="selectedPreColor" class="input-border-radius input-base w-64">
                <option *ngFor="let preColor of preColors" [value]="preColor.Name" class="text-white" [ngClass]="preColor.Name">{{preColor.Name}}</option>
            </select>
            <label class="input-label-base">Vælg farven på knoppen når den er unchecked</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
        </div>
    </div>
    <div class="mt-10 w-full sm:w-1/2 flex flex-wrap">
        <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-64 {{selectedLineBgColor}}" [ngClass]="{'active': selectedLineBgColor.length > 0}">
            <select [(ngModel)]="selectedLineBgColor" class="input-border-radius input-base w-64">
                <option *ngFor="let bgColor of lineBgColors" [value]="bgColor.Name" class="text-white" [ngClass]="bgColor.Name">{{bgColor.Name}}</option>
            </select>
            <label class="input-label-base">Vælg baggrundsfarve på linjen</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
        </div>
        <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-64 ml-10" [ngClass]="{'active': selectedColor.length > 0}">
            <select [(ngModel)]="selectedColor" class="input-border-radius input-base w-64 {{selectedColor}}">
                <option *ngFor="let color of Colors" [value]="color.Name" class="text-white" [ngClass]="color.Name">{{color.Name}}</option>
            </select>
            <label class="input-label-base">Vælg farve på knoppen når den er checked </label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
        </div>
    </div>
    <div class="flex w-full mt-8 flex-wrap">
        <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
            <input [(ngModel)]="selectedText" type="text" placeholder=" " class="input-border-radius focus:border-primary input-base" />
            <label class="input-label-base">Tilføj text</label>
        </div>
    </div>
    <div class="bg-gray-400 p-5 inline-block w-full">


        <div class="my-5 w-full">

            <lib-toggle [style]="selectedStyle" [color]="selectedColor" [preColor]="selectedPreColor" [lineBg]="selectedLineBgColor">
                <input class="w-0 h-0 opacity-0 hidden" tabindex="0" type='checkbox'>
                <span class="ml-2">{{selectedText}}</span>
            </lib-toggle>
        </div>
        <div class="w-full mt-24 flex flex-col" (click)="generateToggleContent()">
            <fa-icon class="inline-block cursor-pointer" [icon]="['fal', 'copy']"></fa-icon>
            <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedToggle">Den er kopieret</p>
        </div>

    </div>
</div>



