<section class="  w-full inline-block py-12 ">
  <h1>
    Fold out
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="bg-gray-300 w-full py-10">
    <div class="container mx-auto">
      <div class="w-full">
        <button-component [type]="'primary'" [style]="'solid'" (click)="nestedOpenValue = !nestedOpenValue" class="inline-block w-1/5"><fa-icon class="text-2xl mr-2 leading-none" [icon]="['fal', 'search']"> </fa-icon><span class="font-medium">Åben fold out</span></button-component>
        <fold-out [isOpen]="nestedOpenValue" [enableValidation]="true">
          <div class="w-full bg-white">
            <p>
              Pellentesque tortor purus metus ut lacus pellentesque portaest ac vivamus gravida vivamus maximus tortor et quam elementum erat bibendum interdum facilisis tortor tincidunt erat tincidunt.

              Purus tincidunt hendrerit phasellus nisl placerat portaest cursus nisl nunc cursus suspendisse diam leo placerat morbi ipsum tortor sed amet accumsan tempus congue sem arcu.
            </p>
          </div>
        </fold-out>
      </div>
    </div>
  </div>
</section>
