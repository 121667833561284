import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ILockerComponentModel, ILockerComponentSubject } from "./models";

@Injectable({
    providedIn: 'root'
})
export class LockerComponentStateService {

    private lockedModels = new Map<string, ILockerComponentModel>();



    /**
     * Reset model by key.
     * If no model is found DO NOTHING.
     * @param key identifier to earlier model create
     */
    public reset(key: string): void {
        const model = this.lockedModels.get(key);
        if (model) {
            model.isLockedSubject.complete();
            this.lockedModels.delete(key);
        }
    }

    /**
     * create lock if not already exists
     * @param key identifier to model
     * @param lockFunction anonymous callback function when model is locked
     * @param unLockFunction anonymous callback function when model is unlocked
     * @param isLocked pre-define state, default is true
     * @param allowUserlockAction user can lock/unlock by click, default is false
     * @param preventPropagation specify whether or NOT the lock component SHOULD prevent propagation at init.
     * @returns If model is NOT already initialized return a new instance of type `ILockerComponentModel`
     */
    public createLock(key: string, lockFunction: Function, unLockFunction: Function, isLocked: boolean = true, allowUserlockAction: boolean = false, preventPropagation: boolean = true): ILockerComponentModel {
        if (!this.lockedModels.has(key)) {
            const model = <ILockerComponentModel>{
                allowUserlockAction: allowUserlockAction,
                isLockedSubject: new BehaviorSubject<ILockerComponentSubject>({ isLocked: isLocked, preventPropagation: preventPropagation }),
                isLocked: isLocked,
                lockFunction: lockFunction,
                unLockFunction: unLockFunction
            };

            this.addSubscription(model);
            this.addModel(key, model);
        }

        const result = this.lockedModels.get(key);

        return result;
    }

    /**
     * lock by key
     * @param key 
     * @param preventPropagation default is false
     */
    public lock(key: string, preventPropagation: boolean = false) {
        this.setLockedValue(key, {
            isLocked: true,
            preventPropagation: preventPropagation
        });
    }

    /**
     * un-lock by key
     * @param key 
     * @param preventPropagation default is false
     */
    public unlock(key: string, preventPropagation: boolean = false) {
        this.setLockedValue(key, {
            isLocked: false,
            preventPropagation: preventPropagation
        });
    }

    /**
     * Get lock subject observable by name
     * @param key identifier
     * @returns 
     */
    public getLock(key: string): Observable<ILockerComponentSubject> {
        if (!this.lockedModels.has(key)) {
            return null;
        }

        return this.lockedModels.get(key).isLockedSubject;
    }

    /**
     * Add event listeners to model changes and call anonymous functions defined on model
     * @param model model to add eventlisteners on
     * @param preventPropagation prevent propagation of anonymous function call 
     */
    addSubscription(model: ILockerComponentModel) {
        model.isLockedSubject.subscribe((value) => {
            if(value.preventPropagation){
                model.isLocked = value.isLocked;
                return;
            }

            if (value.isLocked) {
                model.lockFunction((result) => {
                    model.isLocked = result;
                });
            } else {
                model.unLockFunction((result) => {
                    model.isLocked = !result
                });
            }
        });
    }



    /**
     * Muation - add/override model by key to internal hashmap
     * @param key identifier
     * @param model model to set on identifier
     */
    private addModel(key: string, model: ILockerComponentModel) {
        this.lockedModels.set(key, model);
    }



    /**
     * Mutation - set locked value by name
     * @param key identifier
     * @param value Specify the lock status and if the propagation SHOULD be prevented.
     */
    private setLockedValue(key: string, value: ILockerComponentSubject) {
        if (this.lockedModels.has(key)) {
            if (this.lockedModels.get(key).isLocked != value.isLocked) {
                this.lockedModels.get(key).isLockedSubject.next(value);
            }
        }
    }

    /**
     * For safety destroy the locked models
     */
    ngOnDestroy() {
        console.debug('locker state service ngOnDestroy: cleaning up...');
        this.lockedModels = new Map<string, ILockerComponentModel>()
    }
}