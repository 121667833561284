import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { smoothHeight } from '../directives/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'multi-button',
    templateUrl: './multi-button.component.html',
    animations: [smoothHeight]
})
export class MultiButtonComponent implements OnInit, OnDestroy {
    icon: string = "angle-down"
    @Input() isOpen: boolean = false;
    @Input() color: "text-green-600";
    @Input() hDirection: string = "left";
    @Input() foldUp: boolean = true;
    @Input() settingsOnMobile: boolean = false;
    @Input() settingsButtonName: string = "Indstillinger";
    @Input() settingsButtonType: string = "solid";
    componentDestroyed$ = new Subject<any>();
    constructor(public breakpointObserver: BreakpointObserver) { }

    ngOnInit() {
        if (this.foldUp) {
            this.icon = "angle-up"
        }
        this.breakpointObserver
            .observe(['(min-width: 768px)'])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isOpen = true;
                } else {
                    this.isOpen = false;
                }
            });
    }
    showButtons() {
        this.isOpen = !this.isOpen;
        if (this.icon === "angle-up") {
            this.icon = "angle-down"
        }
        else {
            this.icon = "angle-up"
        }
    }
    ngOnDestroy() {
        this.componentDestroyed$.next({});
        this.componentDestroyed$.complete();
    }

}
