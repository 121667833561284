import { Component, OnInit } from '@angular/core';
import { InComBaseClass } from 'incom-utility-library';
import { ModalBase } from 'projects/bexcomponent-design/src/lib/_modal/IModalBase.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-unlock-modal',
  templateUrl: './user-unlock-modal.component.html',
  styleUrls: ['./user-unlock-modal.component.css'],
  host: {
    class: 'flex h-full flex-col',
  },
})
export class UserUnlockModalComponent extends InComBaseClass implements OnInit, ModalBase {

  constructor() {
    super();
  }
  closed$: Subject<boolean> = new Subject;
  onClose: Function;
  onSuccess: Function;
  data: any;
  scrollStrategy: 'noop' | 'block' | 'reposition';

  ngOnInit() {
  }


  close() {
    this.closed$.next(false);
    this.onClose(false);
  }
  confirm($event) {
    this.closed$.next(true);
    this.onSuccess(true);
  }
}
