<div class="w-full py-12 inline-block">
  <h1>Scroll</h1>
  <h2 class=" mb-20 text-xl leading-none text-red-600">Experimental</h2>

  <h3 class="text-xl font-medium">Virtual scroll</h3>
  <ul class="pl-0 list-none w-full inline-block mb-10">
    <li *ngFor="let nav of TestArray2" (click)="scrollTo2(nav)" class="list-reset text-sm py-2 pr-4 font-medium float-left" [ngClass]="{'border-b border-green-500' : currentSection === nav.Name}">
      {{nav.Name}}
    </li>
  </ul>
  <br />
  <cdk-virtual-scroll-viewport class="h-64 overflow-x-auto" itemSize="96">
    <div id="{{section.Name}}" class="mb-10" *cdkVirtualFor="let section of TestArray2 ; templateCacheSize:0">
      <h2 style="margin:0">{{section.Name}}</h2>
      <p>
        {{section.Description}}
      </p>
    </div>
  </cdk-virtual-scroll-viewport>



  <h3 class="mt-20 text-xl font-medium">ScrollSpy</h3>
  <ul class="pl-0 list-none w-full inline-block mb-10">
    <li *ngFor="let nav of TestArray1" (click)="scrollTo1(nav)" class="list-reset py-2 pr-4 font-medium text-sm float-left" [ngClass]="{'border-b border-green-500' : currentSection === nav}">
      {{nav}}
    </li>
  </ul>
  <br />


  <div id="parentDiv" class="h-64 overflow-x-auto w-1/2 py-16" scrollSpy [spiedTags]="TestArray1" (sectionChange)="onSectionChange($event)">
    <div id="{{section}}" class="mb-10" *ngFor="let section of TestArray1">
      <h2 style="margin:0">{{section}}</h2>
      <p>
        Rutrum placerat purus et nisl scelerisque gravida purus lacus molestie maximus euismod condimentum metus purus consectetur lorem ipsum congue sem eros maximus sollicitudin proin placerat magna interdum quisque et massa congue consectetur et condimentum adipiscing suspendisse purus phasellus elementum leo massa varius portaest massa consectetur cursus rutrum consectetur aliquam sed amet proin vel maecenas aliquam condimentum dolor vel eu congue sit elit elit amet morbi eget sollicitudin quam phasellus eros nulla a leo urna lacus vivamus nisi felis sollicitudin ipsum tincidunt tristique quam sit pellentesque maximus a orci erat aliquam portaest sem morbi tristique ex eros purus hendrerit felis quam.

        A phasellus dolor aliquam orci vel tincidunt scelerisque metus congue vivamus metus ac sed tortor leo tortor lacus facilisis accumsan molestie interdum facilisis ut phasellus metus molestie dolor purus felis consectetur erat maecenas ex eu leo quis hendrerit mi nec maecenas morbi leo proin ipsum elementum fusce quisque diam pellentesque amet nec nisl elit arcu phasellus molestie molestie morbi orci tempus interdum commodo quam nisi commodo rutrum tortor urna et phasellus aliquam adipiscing ex sed proin phasellus nunc ipsum dolor felis elementum mi phasellus congue nulla proin quisque quis nunc accumsan aliquam ac condimentum adipiscing eu eros quam tortor quis.

        Massa scelerisque fusce arcu arcu facilisis nisi et ex quis commodo sem ut purus maecenas ac sollicitudin nunc hendrerit sollicitudin ex arcu orci adipiscing condimentum maecenas elit bibendum placerat suspendisse eget enim vivamus scelerisque quisque tincidunt ex rutrum cursus proin aliquam phasellus quisque eu ut hendrerit vivamus phasellus euismod cursus varius tortor ipsum nulla nec et ipsum magna lacus elit suspendisse nunc lorem eget pellentesque maximus facilisis elementum nec sit ac ipsum erat urna orci nec orci nisi ex erat massa rutrum tincidunt interdum massa felis interdum tempus massa nisi quis nisl quisque felis purus amet rutrum lorem facilisis enim.
      </p>
    </div>
  </div>
  <br />
</div>
