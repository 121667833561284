import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css']
})
export class CardComponent implements OnChanges {
    @Input() type: string;
    @Input() style: string = "solid";
    @Input() color: string;
    @Input() width: string = "";
    constructor() { }

    cardType: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type || changes.style) {
            if (this.type && this.style) {
                this.cardType = 'card-' + this.type + '-' + this.style;
            }
        }
    }

}
