import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-list-presenter',
  templateUrl: './group-list-presenter.component.html',
  styleUrls: ['./group-list-presenter.component.css']
})
export class GroupListPresenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
