<div class="flex items-center justify-center w-full incom-toggle">

    <!-- Toggle Button -->
    <label class="flex items-center cursor-pointer h-6">
        <!-- toggle -->
        <span class="relative block">
            <!-- input -->
            <ng-content select="input"></ng-content>
            <!-- line -->
            <span class="toggle__line w-10  rounded-full shadow-inner block" [ngClass]="[lineBg , style, size]"></span>
            <!-- dot -->
            <span class="toggle__dot absolute w-6 h-6 rounded-full shadow inset-y-0 left-0" [ngClass]="[color, preColor, style, size]"></span>
        </span>
        <!-- label -->
        <ng-content></ng-content>
    </label>

</div>
