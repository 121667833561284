import { Component, OnInit } from '@angular/core';
import { ModalTestComponent } from './modaltest.component';
import { ModalService } from '../../../../projects/bexcomponent-design/src/lib/_modal/modal.service';

@Component({
  selector: 'modal-presenter',
  templateUrl: './modal-presenter.component.html',
  styleUrls: ['./modal-presenter.component.css']
})
export class ModalPresenterComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit() {
  }

  docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/361627653/Modaler"

  OverlayStyling: string[] = [
    "incom-modal-overlay",
  ]
  ModalStyling: string[] = [
    "incom-modal", "container"
  ]
  confirmModalStyling: string[] = [
    "incom-confirm-modal"
  ]

  TabOverlayStyling: string[] = [
    "incom-modal-tab-overlay",
  ]

  openModal() {
    var onClose = () => console.log("Modal Closed without clicking the button");
    var onSuccess = () => console.log("Modal success! You clicked the button");
    var data
    this.modal.open(ModalTestComponent, data, onClose, onSuccess, this.ModalStyling);
  }

  openOverlay() {
    var data
    var onClose = () => console.log("Modal Closed without clicking the button");
    var onSuccess = () => console.log("Modal success! You clicked the button");
    this.modal.open(ModalTestComponent, data, onClose, onSuccess, this.OverlayStyling);
  }
  openTabOverlay() {
    var data
    var onClose = () => console.log("Modal Closed without clicking the button");
    var onSuccess = () => console.log("Modal success! You clicked the button");
    this.modal.open(ModalTestComponent, data, onClose, onSuccess, this.TabOverlayStyling);
  }

  openConfirmModalOverlay() {
    var data
    var onClose = () => console.log("Modal Closed without clicking the button");
    var onSuccess = () => console.log("Modal success! You clicked the button");
    this.modal.open(ModalTestComponent, data, onClose, onSuccess, this.confirmModalStyling);
  }
}
