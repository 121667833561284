import { Component, OnInit } from '@angular/core';
import { LoaderService, } from "projects/bexcomponent-design/src/public-api";

@Component({
    selector: 'loader-presenter',
    templateUrl: './loader-presenter.component.html',
})
export class LoaderPresenterComponent implements OnInit {


    //docLink: string = "https://incomit.atlassian.net/wiki/spaces/BOL/pages/343539713/Knapper"
    delay = 350;
    duration = 10000;
    hasCopiedStyle: boolean;
    loaderSize: "md" | "sm" = "md"
    loaderType: "spinner" | "buildings" = "spinner";
    loaderBackground: boolean = true;


    loaderSizes = [
        { Name: "Standard", Value: "md" },
        { Name: "Lille", Value: "sm" },
    ]

    loaderTypes = [
        { Name: "Spinner", Value: "spinner" },
        { Name: "Bygninger", Value: "buildings" },
    ]

    updateBackground(event) {
        if (event === "false") {
            this.loaderBackground = false;
        }
        else {
            this.loaderBackground = true;
        }
    }

    loaderBackgroundValues = [
        { Name: "Ja", Value: true },
        { Name: "Nej", Value: false },
    ]
    constructor(private loaderService: LoaderService) { }

    ngOnInit() {
    }

    toggleLoader(): void {
        const loaders: any[] = this.loaderService.loaders$.getValue();
        const loader = loaders.filter(l => l.area == "demo_area")[0];
        if (loader) {
            this.loaderService.removeLoader(loader);
        }
        else {
            this.loaderService.addLoader("demo_loader", "demo_area", this.duration, this.delay);
        }
    }
}
