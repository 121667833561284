import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { ModalSharedModule } from "./_modal/modal.module";
import { buttonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { customInputComponent } from './custom-input/custom-input.component';
import { FormsModule } from '@angular/forms';
import { FoldOutComponent } from './fold-out/fold-out.component';
import { LineFoldOutComponent } from './line-fold-out/line-fold-out.component';
import { LoaderService } from './loader/loader.service';
import { LoaderDirective } from './loader/loader.directive';
import { GrowlSharedModule } from "./growl/growler.module";
import { GrowlerComponent } from "./growl/growler.component";
import { IncomUtilityLibraryModule } from "incom-utility-library";
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageUploaderFileCountDirective } from './image-uploader/image-uploader-file-count.directive';
import { ImageUploaderFileSizeDirective } from './image-uploader/image-uploader-file-size.directive';
import { LabeledToggleComponent } from './labeled-toggle/labeled-toggle.component';
import { MultiButtonComponent } from './multi-button/multi-button.component';
import { ImageComponent } from './image/image.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ChartCircularPercentageComponent } from './chart-circular-percentage/chart-circular-percentage.component';
import { LockerComponent } from './locker/locker.component';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupedListCategoryDirective } from './group-list/directives/group-list-category.directive';
import { GroupedListCategoryItemDirective } from './group-list/directives/group-list-category-item.directive';
import { GroupedListCategoryExtendedDirective } from './group-list/directives/group-list-category-extended.directive';
import { GroupListSelectorComponent } from './group-list/group-list.api';
import { FaIconComponent } from './fa-icon/fa-icon.component';
import { AnimationsSharedModule } from './directives/animations.module';

@NgModule({
    declarations: [
        AccordionComponent,
        buttonComponent,
        CardComponent,
        customInputComponent,
        FoldOutComponent,        
        ScrollSpyDirective,
        LineFoldOutComponent,
        LoaderDirective,
        ImageUploaderComponent,
        ImageUploaderFileCountDirective,
        ImageUploaderFileSizeDirective,
        LabeledToggleComponent,
        MultiButtonComponent,
        ImageComponent,        
        ToggleComponent,
        ChartCircularPercentageComponent,
        LockerComponent,
        GroupListComponent,
        GroupedListCategoryDirective,
        GroupedListCategoryItemDirective,
        GroupedListCategoryExtendedDirective,
        GroupListSelectorComponent
    ],
    imports: [
        IncomUtilityLibraryModule,
        ModalSharedModule.forRoot(),
        CommonModule,
        FormsModule,
        GrowlSharedModule.forRoot(),
        FaIconComponent,
        AnimationsSharedModule.forRoot(),
    ],
    providers: [LoaderService],
    exports: [
        GrowlSharedModule,
        ModalSharedModule,
        AccordionComponent,
        buttonComponent,
        CardComponent,
        customInputComponent,
        FoldOutComponent,        
        ScrollSpyDirective,
        LineFoldOutComponent,
        LoaderDirective,
        GrowlerComponent,
        ImageUploaderComponent,
        ImageUploaderFileCountDirective,
        ImageUploaderFileSizeDirective,
        LabeledToggleComponent,
        MultiButtonComponent,
        ImageComponent,        
        ToggleComponent,
        ChartCircularPercentageComponent,
        LockerComponent,
        GroupListComponent,
        GroupedListCategoryDirective,
        GroupedListCategoryItemDirective,
        GroupedListCategoryExtendedDirective
    ]
})
export class BEXComponentDesignModule {

}
