import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { LoginModel } from './loginModel';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'incom-login-with-password',
    templateUrl: './login-with-password.component.html',
    host: {
        class: 'w-full inline-block h-full',
    },
    styleUrls: ['./login-with-password.component.css']
})
export class LoginWithPasswordComponent implements OnInit {

    constructor(private loginService: AuthenticationService, private route: ActivatedRoute, private router: Router) { }

    passwordRevealed: boolean = false;

    ngOnInit() {
    }

    togglePasswordReveal(): void {
        this.passwordRevealed = !this.passwordRevealed;
    }

    onSubmit(loginForm: NgForm): void {
        if (!loginForm.valid) { 
            return;
        }

        this.loginService.login(this.model)
            .then(success => {
                if (this.route.snapshot.queryParamMap.has("returnUrl")) {
                    this.router.navigate([this.route.snapshot.queryParamMap.get("returnUrl")]);
                } else {
                    this.router.navigate(['/']);
                }
            }, (err) => {
                console.log("Login failed", err);
            });
    }

    model = new LoginModel();
}
