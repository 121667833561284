
<div class="incom-modal-header">
  <h2>
    Super fed modal header
  </h2>
  <fa-icon class="incom-modal-close-icon" (click)="close()" [icon]="['fal', 'times']"> </fa-icon>
</div>
<div class="incom-modal-content">

  <p class="font-medium"> Fin lang body</p>
  <card [type]="'big'" [style]="'solid'" [color]="'text-red-400'" class="py-5 inline-block"><div card-icon><fa-icon [icon]="['far', 'ballot-check']"></fa-icon><fa-icon [icon]="['fas', 'star']" [ngClass]="isFavorit ? 'text-orange-500' : 'text-white'" class="absolute left-0 top-0 p-1 text-base" (click)="isFavorit = !isFavorit"></fa-icon></div><div card-body> <p class="font-medium text-center break-all">Den fedeste text!</p> </div></card>
  <p>
    Elit elit sed lacus condimentum condimentum gravida metus portaest bibendum orci scelerisque pellentesque pellentesque ex bibendum purus hendrerit scelerisque enim eget proin nec bibendum magna hendrerit massa aliquam morbi metus mi sollicitudin tristique ipsum nulla quisque dolor ac quam tincidunt tristique eu nulla nunc gravida portaest quam maximus gravida pellentesque dolor euismod hendrerit tristique amet elementum euismod sem tempus magna a rutrum mi gravida nunc quisque et rutrum proin proin diam mi pellentesque vel euismod scelerisque tempus nec maecenas nunc lorem

  </p>

</div>

<div class="incom-modal-footer">
  <button class="btn btn-deny btn-outline btn-border-radius float-left" (click)="success()"><fa-icon class="text-base mr-2 leading-none" [icon]="['far', 'times']"> </fa-icon>
  <span class="font-medium">Annuller</span></button>
  <button class="btn btn-accept btn-outline btn-border-radius float-right" (click)="close()"><fa-icon class="text-base mr-2 leading-none" [icon]="['far', 'plus']"> </fa-icon><span class="font-medium">Tilføj Ressource(r)</span></button>
</div>

