<div class="mb-10 relative w-full">
  <ng-container *ngIf="showImageBar">
    <h3 class="mb-3">Billeder</h3>
    <ng-container *ngIf="value">
      <div *ngFor="let imageurl of value.imageUrls; index as j;" class="md:w-1/3 lg:w-1/4 sm:w-full inline-block m-5">
        <div image-component [fallbackImageUrl]="fallbackImageUrl" [fallbackType]="fallbackImageType" [alt]="altText" [imageUrl]="imageurl" [imageClasses]="'object-cover'"></div>
        <div (click)="removeFile(j)" class="font-medium cursor-pointer">Fjern</div>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="isPhone">
    <input type="file" multiple #camera capture="camera" accept="image/*" id="cameraInput" name="cameraInput" [hidden]="true" (change)="onFileSelected($event)">
    <button-component [type]="'accept'" [style]="'outline'" (click)="upload('camera')"><fa-icon class="text-sm mr-2 leading-none" [icon]="['far', 'camera']"> </fa-icon><span class="font-semibold">Tag billed(er)</span></button-component>
  </div>
  <div *ngIf="!isPhone">
    <input type="file" multiple #file accept="image/*" id="fileInput" name="fileInput" [hidden]="true" (change)="onFileSelected($event)">
    <button-component [type]="'accept'" [style]="'outline'" (click)="upload('file')"><fa-icon class="text-sm mr-2 leading-none" [icon]="['far', 'images']"> </fa-icon><span class="font-semibold">Find billed(er)</span></button-component>
  </div>
</div>
