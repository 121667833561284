import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ILockerComponentModel } from './models';



@Component({
  selector: 'locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.css']
})
export class LockerComponent implements OnInit, OnDestroy {

  @Input() lockedIcon: string[] = ['fas', 'lock'];
  @Input() unLockedIcon: string[] = ['fas', 'unlock'];;

  @Input() description: string;
  @Input() model: ILockerComponentModel;


  constructor() { }


  ngOnInit() {
    if(!this.model) return;

    if (this.model.allowUserlockAction == undefined)
      this.model.allowUserlockAction = false;

  }

  /**
   * Toogle lock - only allow user click if this is allowed on model
   * @param $event
   */
  toggleLock($event) : void {
    if (!this.allowedToToggle()) return;

    const isLocked = !this.model.isLocked;
    if (isLocked) this.lock();
    else this.unLock();
  }

  /**
   * Check if user is allowed to toggle on lock element.
   * @returns true if element is locked or user is allowed click on element
   */
  allowedToToggle(): boolean {
    return this.model.allowUserlockAction || this.model.isLocked;
  }

  /**
   * Unlock
   */
  unLock(): void {
    this.model.isLockedSubject.next({
      isLocked: false,
      preventPropagation: false
    });
  }

  /**
   * Lock
   */
  lock(): void {
    this.model.isLockedSubject.next({
      isLocked: true,
      preventPropagation: false
    });
  }

  /**
   * On destory
   * !IMPORTANT - remember to destroy subscriptions to model subject
   * Implicit we destroy the initialized model here - as we cannot do this on the state service
   */
  ngOnDestroy(): void {
    this.model.isLockedSubject.complete();
    this.model = null;
  }

}
