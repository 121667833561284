import { trigger, transition, style, animate, query, animateChild } from "@angular/animations";

export const smoothHeight = trigger('grow', [
  transition('void <=> *', [style({ transform: 'translateY(-50%)', opacity: 0 }),animate('.3s ease')]),
  transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 1 }), animate('.3s ease')], {
    params: { startHeight: 0 }
  })
]);
export const foldOutParent = trigger('foldOutParent', [
    transition(':enter, :leave', [
        query('@*', animateChild())
    ])
]);
