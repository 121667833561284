<div class="w-full py-12 inline-block">
  <h1>
    Multi Knap
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <p> På mobilen kan man de sammenfoldede multibuttons</p>
  <div class="flex flex-wrap my-16 md:flex-col bg-gray-400 p-3">
    <div class=" pr-5 md:pr-0 md:pb-5 mb-8">
      <p class="font-medium mb-2"> Denne knap folder opad på mobilen og knapperne går til venstre på desktop</p>
      <div class="flex">
        <multi-button class="inline-block" [color]="'text-white'">
          <ng-container mainButton>
            <button-component [type]="'accept'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium truncate pr-6 text-sm">Gem og aktiver</span>
            </button-component>
          </ng-container>
          <div class="flex md:flex-row-reverse flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'outline'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        <div class="text-primary ml-2">
          <fa-icon (click)="generateMultiFoldUpStringContent()" class="text-xl" [icon]="[hasCopiedUp ? 'fas' : 'fal', 'copy']"></fa-icon>
        </div>
      </div>
      <p class="mt-5 font-medium text-xs w-full">{{hasCopiedUp ? 'Den er kopieret' : 'Klik på copy knap for at kopier toggle!'}}</p>
    </div>
    <div class=" pr-5 md:pr-0 md:pb-5 mb-8">
      <p class="font-medium mb-2"> Denne knap folder nedad på mobilen og knapperne går til højre på desktop</p>
      <div class="flex mb-2">
        <multi-button class="inline-block" [foldUp]="false" [hDirection]="'right'" [color]="'text-green-600'">
          <ng-container mainButton>
            <button-component [type]="'accept'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium truncate pr-6 text-sm">Gem og aktiver</span>
            </button-component>
          </ng-container>
          <div class="flex md:flex-row flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'solid'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        <div class="text-primary ml-2">
          <fa-icon (click)="generateMultiFoldDownStringContent()" class="text-xl" [icon]="[hasCopiedDown ? 'fas' : 'fal', 'copy']"></fa-icon>
        </div>
      </div>
      <p class="mt-5 font-medium text-xs w-full">{{hasCopiedDown ? 'Den er kopieret' : 'Klik på copy knap for at kopier toggle!'}}</p>
    </div>
    <div>
      <p class="font-medium mb-2"> Denne knap har ingen focus knap på desktop. På mobilen får den en "Indstillinger" knap, som bare folder de andre knapper ud og ind</p>
      <div class="flex">
        <multi-button class="inline-block" [color]="'text-green-600'" [settingsOnMobile]="true" [settingsButtonName]="'Muligheder'" [settingsButtonType]="'outline'">
          <div class="flex md:flex-row-reverse flex-col-reverse" buttons>
            <button-component [type]="'accept'" [style]="'outline'" class="md:ml-2">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']"> </fa-icon>
              <span class="font-medium text-sm">Gem som kladde</span>
            </button-component>
            <button-component [type]="'deny'" [style]="'outline'">
              <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'times']"> </fa-icon>
              <span class="font-medium text-sm">Annuler</span>
            </button-component>
          </div>
        </multi-button>
        <div class="text-primary ml-2">
          <fa-icon (click)="generateMultiSettingsStringContent()" class="text-xl" [icon]="[hasCopiedSettings ? 'fas' : 'fal', 'copy']"></fa-icon>
        </div>
      </div>
      <p class="mt-5 font-medium text-xs w-full">{{hasCopiedSettings ? 'Den er kopieret' : 'Klik på copy knap for at kopier toggle!'}}</p>

    </div>

  </div>
</div>
