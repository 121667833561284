import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupListCheckedComponent } from 'projects/bexcomponent-design/src/lib/group-list/group-list-extensions/group-list-checked/group-list-checked-component';
import { GroupListComponent } from 'projects/bexcomponent-design/src/lib/group-list/group-list.component';
import { IGroupedListCategoryItemModel, IGroupedListComponentModel } from 'projects/bexcomponent-design/src/lib/group-list/group-list.models';
import { IGroupedListCheckedCategoryModelExtended, smoothHeight } from 'projects/bexcomponent-design/src/public-api';

@Component({
    selector: 'app-group-list-checked-presenter',
    templateUrl: './group-list-checked-presenter.component.html',
    animations: [smoothHeight]
})
export class GroupListCheckedPresenterComponent extends GroupListCheckedComponent implements OnInit {

    @ViewChild(GroupListComponent, { static: false }) groupListComponent: GroupListComponent;

    constructor() {
        super();
    }

    ngOnInit() {
    }

    whatAmI(item: any): void {
        console.log(item)
    }

    /**
     * Retrive a sub category model
     * Use this to initialize a new group-list
     * @param categoryItem category item which SHOULD contain a collection of sub categories
     * @returns An instance of IGroupedListComponentModel
     */
    getSubCategoryModel(categoryItem: IGroupedListCategoryItemModel): IGroupedListComponentModel {
        return {
            categories: categoryItem.subCategories
        };
    }

    public isValid(): boolean {
        return this.groupListComponent.model.categories.some((category: IGroupedListCheckedCategoryModelExtended) => {
            const [allChecked, anyChecked] = this.hasCategoryAllOrAnyChecked(category);
            console.log('all checked', allChecked, 'any checked', anyChecked);
            if (allChecked || anyChecked)
                return true;
            return false;
        });
    }
}
