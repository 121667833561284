import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'list-fold-out-presenter',
    templateUrl: './list-fold-out-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    }
})
export class ListFoldOutPresenterComponent implements OnInit {
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/372146177/List+fold+out"
    constructor() { }

    ngOnInit() {
    }

    model = [
        {
            Question: "Er der toilet på værelset Er der toilet på værelset Er der toilet på værelset",
            Type: "/assets/Icons/JaNej.png",
            FollowQuestion: 1,
            Resources: "MK, CP",
            Creator: "Thomas Nielsen",
            open: true,
            optionsOpen: false
        },
        {
            Question: "Binder vinduet",
            Type: "/assets/Icons/JaNej.png",
            FollowQuestion: 2,
            Resources: "MK, CP, RM, CK, KLM",
            Creator: "Morten Carlsen",
            open: true,
            optionsOpen: false
        },
        {
            Question: "Hvor mange skruer er der i væggen",
            Type: "/assets/Icons/Rating.png",
            FollowQuestion: 3,
            Resources: "MK, CP, RM",
            Creator: "Thomas Nielsen",
            open: true,
            optionsOpen: false
        }
    ];

}
