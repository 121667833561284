/**
 * Grouplist - Standard minimal functionality to create a grouplist by model contract
 * With demo model, if not model is provided fallback to demo 
*/
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { smoothHeight } from '../directives/animations';
import { GroupedListCategoryExtendedDirective, GroupedListCategoryExtendedModelViewContext } from './directives/group-list-category-extended.directive';
import { GroupedListCategoryItemDirective, GroupedListCategoryItemModelViewContext } from './directives/group-list-category-item.directive';
import { GroupedListCategoryDirective, GroupedListCategoryModelViewContext } from './directives/group-list-category.directive';
import { IGroupedListCategoryItemModel, IGroupedListCategoryModel, IGroupedListComponentModel, ViewContext } from './group-list.models';


@Component({
    selector: 'group-list',
    templateUrl: './group-list.component.html',
    animations: [smoothHeight]
})
export class GroupListComponent implements OnInit {

    @Input() categoryClass: string = 'w-full h-full px-6 py-4 mb-4 bg-white rounded-none relative shadow-md';
    @Input() categoryItemClass: string = 'flex flex-col space-y-1';
    @Input() useTransclusion: boolean = false;
    @Input() model: IGroupedListComponentModel;

    //template references
    @ContentChild(GroupedListCategoryDirective, {
        read: TemplateRef,
        static: false
    }) categoryTemplateRef: TemplateRef<ViewContext<IGroupedListCategoryModel>>;

    //template references
    @ContentChild(GroupedListCategoryExtendedDirective, {
        read: TemplateRef,
        static: false
    }) categoryExtendedTemplateRef: TemplateRef<ViewContext<IGroupedListCategoryModel>>;


    //template references
    @ContentChild(GroupedListCategoryItemDirective, {
        read: TemplateRef,
        static: false
    }) categoryChildTemplateRef: TemplateRef<ViewContext<IGroupedListCategoryItemModel>>;


    constructor() { }

    ngOnInit() {
        this.model = this.model || this.demo();

    }


    /**
     * !IMPORTANT - remember this is RUN on every digest cycle. Therefor DO NOT add complex resolving here.
     * Retrive context by category
     * @param category 
     * @returns Instance of GroupedListCategoryModelViewContext
     */
    getCategoryContext(category: IGroupedListCategoryModel): GroupedListCategoryModelViewContext {
        return { $implicit: category };
    }

    /**
    * !IMPORTANT - remember this is RUN on every digest cycle. Therefor DO NOT add complex logic here.
    * Retrive context by category
    * @param category 
    * @returns Instance of GroupedListCategoryExtendedModelViewContext
    */
    getCategoryExtendedContext(category: IGroupedListCategoryModel): GroupedListCategoryExtendedModelViewContext {
        return { $implicit: category };
    }

    /**
     * !IMPORTANT - remember this is RUN on every digest cycle. Therefor DO NOT add complex resolving here.
     * Retrive context by category item
     * @param category 
     * @returns Instance of GroupedListCategoryItemModelViewContext
     */
    getCategoryItemContext(item: IGroupedListCategoryItemModel, parent: IGroupedListCategoryModel): GroupedListCategoryItemModelViewContext {
        return { $implicit: item, parent: parent };
    }


    /**
     * Toggle hide/show category items on a category
     * @param $event 
     * @param category Category
     */
    toggleShowChildren($event: any, category: IGroupedListCategoryModel): void {
        category.showChild = !category.showChild;
    }


    /**
     * Demo data
     * @returns model of type IGroupedListComponentModel
     */
    demo(): IGroupedListComponentModel {

        var cat1 = <IGroupedListCategoryModel>{
            name: 'Stamdata',
            description: 'stamdata..',
            key: 'Stamdata'
        };
        cat1.children = <IGroupedListCategoryItemModel[]>[
            {
                name: 'test1',
                description: 'test1',
                parent: cat1,
                key: 'test1'
            }
        ];

        var cat2 = <IGroupedListCategoryModel>{
            name: 'Category 1',
            description: 'category..'
        };
        cat2.children = <IGroupedListCategoryItemModel[]>[
            {
                name: 'test2',
                description: 'test2',
                parent: cat2,
                key: 'test2'
            }
        ];

        var cat3 = <IGroupedListCategoryModel>{
            name: 'Category 2',
            description: 'category..'
        };


        var cat3SubCategory1 = <IGroupedListCategoryModel>{
            name: 'Postnummer og by sub',
            description: 'Postnummer og by sub',
            parent: cat3,
        };

        cat3SubCategory1.children = <IGroupedListCategoryItemModel[]>
            [ //TODO!
                {
                    name: 'postnummer',
                    description: 'postnummer',
                    parent: cat3SubCategory1,
                },
                {
                    name: 'by',
                    description: 'by',
                    parent: cat3SubCategory1,
                }
            ];

        cat3.children = <IGroupedListCategoryItemModel[]>
            [
                {
                    name: 'test3',
                    description: 'test3',
                    parent: cat3,
                    key: 'test3',
                },
                {
                    name: 'Postnummer og by 1',
                    description: 'Postnummer og by 1',
                    key: 'Postnummer og by 1',
                    parent: cat3,
                    isSubCategory: true,
                    subCategories: [cat3SubCategory1]
                }

            ];

        var cat4 = <IGroupedListCategoryModel>{
            name: 'Ukategoriseret',
            description: 'Ukategoriseret',
            key: 'Ukategoriseret',
        };

        const demo = <IGroupedListComponentModel>{
            categories: <IGroupedListCategoryModel[]>[
                cat1,
                cat2,
                cat3,
                cat4
            ]
        }
        console.debug(demo);
        return demo;
    }
}


