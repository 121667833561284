import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormValidationService } from "incom-utility-library";

@Component({
  selector: 'accordion-presenter',
  host: {
    class: 'w-full inline-block',
  },
  templateUrl: './accordion-presenter.component.html',
  styleUrls: ['./accordion-presenter.component.css']
})
export class AccordionPresenterComponent implements OnInit {
    openValue: boolean = true;
    nestedOpenValue: boolean = false;
  docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/371818500/Accordion";
  issue = ({
  } as any);
  constructor(private formValidationService : FormValidationService) { }

  ngOnInit() {
  }
  /**
 * Save registration schema.
 * @param form the form to validate.
 */
  saveForm(form: NgForm): void {
    const firstInvalid = this.formValidationService.checkFormForInvalidElements(form);
    if (firstInvalid) {
      return;
    } else {
      console.log("Sucsses!");
    }

  }
}
