import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { ImageUploaderFileContainer } from "./image-uploader.component";

@Directive({
  selector: '[maxFileCount]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ImageUploaderFileCountDirective, multi: true }]
})
export class ImageUploaderFileCountDirective implements Validator {
  @Input() maxFileCount: number;

  validate(control: AbstractControl): { [key: string]: any; } {
    const val = control.value as ImageUploaderFileContainer;
    if (!val || !val.selectedFiles || val.selectedFiles.length === 0)
      return null;

    if (val.selectedFiles.length > this.maxFileCount)
      return { 'fileCount': true, 'maxFileCount': this.maxFileCount };

    return null;
  }

}
