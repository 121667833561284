import { Component, OnInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { smoothHeight } from '../directives/animations';
import { FormValidationService } from "incom-utility-library";
import { Subscription } from "rxjs"

@Component({
  selector: 'fold-out',
  templateUrl: './fold-out.component.html',
  animations: [smoothHeight]
})
export class FoldOutComponent implements OnInit, OnDestroy {
    @Input() isOpen: boolean;
    @Input() enableValidation: boolean = false;
  icon: string = "angle-up"
  constructor(private validationService: FormValidationService, private element: ElementRef) { }

  openAccordion() {
    this.isOpen = !this.isOpen;
    if (this.icon === "angle-up") {
      this.icon = "angle-down"
    }
    else {
      this.icon = "angle-up"
    }
  }

  ngOnInit() {
    if (this.enableValidation) {
      this.validationSubscription = this.validationService.invalidElements$.subscribe(x => {
        const hasInvalidElement = x.some(y => this.validationService.isParentsForInvalidElement(y, this.element.nativeElement));
        if (hasInvalidElement) {
          this.isOpen = hasInvalidElement;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this.enableValidation) {
      this.validationSubscription.unsubscribe();
    }
  }
  validationSubscription: Subscription;

}
