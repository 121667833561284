import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  providers: [MakeProvider(ImageUploaderComponent)]
})
export class ImageUploaderComponent implements OnInit, ControlValueAccessor {
  @ViewChild('camera', { static: false }) cameraElement: any;
  @ViewChild('file', { static: false }) fileElement: any;
  isPhone: boolean;
  @Input() fallbackImageUrl: string = '';
  @Input() fallbackImageType: string = '';
  @Input() altText: string = 'Billede mangler';
  currentSize: number = 0;
  @Input() showImageBar: boolean = true;
  @Output() onUpload = new EventEmitter();

  private _value: ImageUploaderFileContainer;
  get value(): ImageUploaderFileContainer { return this._value; };

  set value(v: ImageUploaderFileContainer) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this.value = value;
    return;
  }

  onChange = (_) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  constructor(private platform: Platform) { }
  ngOnInit() {
    this.isPhone = (this.platform.ANDROID || this.platform.IOS);
  }

  /**
   * Opens the upload function on the current device type
   * @param elementName The name of the element being opened
   */
  upload(elementName: string): void {
    if (elementName == "file") {
      this.fileElement.nativeElement.click();
    } else if (elementName == "camera") {
      this.cameraElement.nativeElement.click();
    }
  }

  /**
   * Callback function for inserting the selected pictures into the file and url arrays.
   * Also emits the given event through onUpload
   * @param event The event
   */
  onFileSelected(event: any): void {
    this.value.selectedFiles.push(...event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      const reader = new FileReader();
      reader.onload = e => {
        this.value.imageUrls.push((e.target as any).result);
      };
      reader.readAsDataURL(event.target.files[i]);
    }

    this.onChange(this.value);
    this.onUpload.emit(event);
  }
  /**
   * Removes the file at the given index
   * @param index The index of the file to remove
   */
  removeFile(index: number): void {
    this.value.selectedFiles.splice(index, 1);
    this.value.imageUrls.splice(index, 1);
    this.onChange(this.value);
  }
}

export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}

export class ImageUploaderFileContainer {
  public selectedFiles: any[];
  public imageUrls: any[];
}
