import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[image-component]',
  templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit {

  constructor() { }
  @Input() imageUrl: string;
  @Input() alt: string = ""
  @Input() fallbackImageUrl: string = "";
  @Input() fallbackType: "floorplan-black" | "floorplan-white" | "none" | "" = "";
  @Input() imageClasses: string;
  @Input() title: string = "";

  ngOnInit() {
    if (!this.imageUrl) {
      if (!this.fallbackImageUrl) {
        switch (this.fallbackType) {
          case "floorplan-black":
            this.imageUrl = "assets/fallback-images/floorplan_black.svg"
            break;

          case "floorplan-white":
            this.imageUrl = "assets/fallback-images/floorplan_white.svg"
            break;

          case "none":
            this.imageUrl = ""
            break;

          default:
            this.imageUrl = "assets/fallback-images/placeholder_image.png"
        }
      }
      else {
        this.imageUrl = this.fallbackImageUrl
      }

    }
  }

}
