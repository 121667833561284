
  <div class="w-full py-12 inline-block">
    <h1>
      Knapper
    </h1>
    <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
      <p class="font-medium"> Se dokumentation</p>
    </a>
    <div class="flex flex-wrap">
      <section>
        <h2 class="pt-5 pb-3" >Byg selv knapper</h2>
        <div>
          <div class="w-full flex">
            <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
              <input [(ngModel)]="selectedText" type="text" placeholder=" " class="input-border-radius focus:border-primary input-base" />
              <label class="input-label-base">Vælg text</label>
            </div>
          </div>
          <div class="w-full flex mt-5 flex-wrap">

            <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-40" [ngClass]="{'active': selectedType.length > 0}">
              <select [(ngModel)]="selectedType" class="input-border-radius input-base">
                <option *ngFor="let type of types" [value]="type.Name">{{type.Name}}</option>
              </select>
              <label class="input-label-base">Vælg type</label>
              <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
            </div>

            <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedStyle.length > 0}">
              <select [(ngModel)]="selectedStyle" class="input-border-radius input-base">
                <option *ngFor="let style of styles" [value]="style.Name">{{style.Name}}</option>
              </select>
              <label class="input-label-base">Vælg stil</label>
              <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
            </div>

            <div class="select-wrapper input-border-radius bg-white inline-block mb-3 active ml-3 w-40">
              <select [(ngModel)]="hasIcon" class="input-border-radius input-base">
                <option *ngFor="let options of hasIconOptions" [ngValue]="options.Value">{{options.Name}}</option>
              </select>
              <label class="input-label-base">Med icon</label>
              <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
            </div>
          </div>
          <div class="flex w-full mt-8">
            <div class="form-group " *ngIf="hasIcon">
              <div class="icon-picker-box relative">
                <fa-icon class="text-2xl text-green-500 bg-white" [icon]="[selectedIconStyle, selectedIcon]" (click)="iconSelectorOpen = !iconSelectorOpen"></fa-icon>
                <div class="absolute border rounded-lg flex w-64 flex-wrap border-gray-600 left-0 top-0 p-3 bg-white z-20" *ngIf="iconSelectorOpen">
                  <fa-icon class=" top-0 right-0 absolute w-5 h-5 flex items-center justify-center" [icon]="['fal', 'times']" (click)="iconSelectorOpen = !iconSelectorOpen"></fa-icon>
                  <fa-icon *ngFor="let icon of icons" class="text-xl w-8 text-green-500 bg-white p-1" (click)="[selectedIcon = icon.Name, iconSelectorOpen = false, generateIconMarkup() ]" [icon]="[selectedIconStyle, icon.Name]"></fa-icon>
                </div>
              </div>
            </div>
            <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedIconStyle.length > 0}" *ngIf="hasIcon">

              <select [(ngModel)]="selectedIconStyle" class="input-border-radius input-base" (ngModelChange)="generateIconMarkup()">
                <option *ngFor="let iconStyle of iconStyles" [value]="iconStyle.Name">
                  {{iconStyle.Name}}
                </option>
              </select>
              <label class="input-label-base">Vælg ikonstil</label>
              <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
            </div>
          </div>
        </div>
        <p class="font-medium mb-2 mt-10">Implementation</p>
        <div class="p-3 bg-gray-400">
          <p>Button component</p>
          <button-component [type]="selectedType" [style]="selectedStyle" class="mb-5 block" (click)="generateStringContent()">
            <fa-icon class="text-base mr-2 leading-none sm:text-base" [icon]="[selectedIconStyle, selectedIcon]" *ngIf="hasIcon"></fa-icon>
            <span class="font-medium">{{selectedText}}</span>
          </button-component>
          <p>Regular button</p>
          <button class="btn btn-border-radius" [ngClass]="'btn-' + selectedType +  ' ' + 'btn-' + selectedStyle" (click)="generateFormButtonStringContent()">
            <fa-icon class="text-base mr-2 leading-none sm:text-base" [icon]="[selectedIconStyle, selectedIcon]" *ngIf="hasIcon"></fa-icon>
            <span class="font-medium">{{selectedText}}</span>
          </button>

        </div>
        <p class="mb-5 font-medium text-xs ">{{hasCopied ? 'Den er kopieret' : 'Klik på knappen for at kopiere den!'}}</p>
        <p class="font-medium mb-2 mt-10">Snippet</p>
        <p class="text-gray-700">comButton</p>
      </section>
      <div class=" px-20"></div>
      <section>
        <h2 class="pt-5 pb-3">
          Prædefineret knapper
        </h2>
        <div class="w-full flex items-center">
          <p class="mb-5 font-medium text-base ">{{hasCopiedChosen ? 'Den er kopieret' : 'Klik på en knap for at kopiere den!'}}</p>
        </div>
        <section class=" mb-32 ">
          <div class="container mx-auto flex flex-wrap items-center">
            <div class="w-full sm:w-1/3 inline-block py-5">
              <p class="mb-2 text-gray-800">Standard opret knap</p>
              <button class="btn btn-border-radius btn-accept btn-outline" (click)="generateChosenStringContent(this.createBtn)">
                <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'plus']"></fa-icon>
                <span class="font-medium">Opret</span>
              </button>
            </div>
            <div class="w-full sm:w-1/3 inline-block py-5">
              <p class="mb-2 text-gray-800">Standard annuller knap</p>
              <button class="btn btn-border-radius btn-deny btn-outline" (click)="generateChosenStringContent(this.cancelBtn)">
                <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'times']"></fa-icon>
                <span class="font-medium">Annuller</span>
              </button>
            </div>
            <div class="w-full sm:w-1/3 inline-block py-5">
              <p class="mb-2 text-gray-800">Standard næste knap</p>
              <button class="btn btn-border-radius btn-accept btn-outline" (click)="generateChosenStringContent(this.goToBtn)">
                <span class="font-medium mr-2">Næste</span>
                <fa-icon class="text-base leading-none" [icon]="['fal', 'long-arrow-right']"></fa-icon>
              </button>
            </div>
            <div class="w-full sm:w-1/3 inline-block py-5">
              <p class="mb-2 text-gray-800">Standard tilbage knap</p>
              <button class="btn btn-border-radius btn-deny btn-outline" (click)="generateChosenStringContent(this.goBackBtn)">
                <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'long-arrow-left']"></fa-icon>
                <span class="font-medium">Tilbage</span>
              </button>
            </div>
            <div class="w-full sm:w-1/3 inline-block py-5">
              <p class="mb-2 text-gray-800">Standard gem knap</p>
              <button class="btn btn-border-radius btn-accept btn-outline" (click)="generateChosenStringContent(this.saveBtn)">
                <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'save']"></fa-icon>
                <span class="font-medium">Gem plan</span>
              </button>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
