import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bottom-menu',
    templateUrl: './bottom-menu.component.html',
    host: {
        class: 'w-full inline-block',
    },
  styleUrls: ['./bottom-menu.component.css']
})
export class BottomMenuComponent implements OnInit {
    hasCopied = false;
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/382107653/Bottom+menu"
  constructor() { }

  ngOnInit() {
  }

   


  generateStringContent() {
      let textbox = document.createElement('textarea')
      textbox.value = `<div class="fixed bottom-20 inset-x-0 bottom-15 flex items-center justify-center">
        <div class=" bg-gray-900 text-white flex items-center justify-center shadow-lg  rounded-full px-4 py-2" (click)="generateStringContent()">
          <button class="inline-block cursor-pointer px-5 border-r border-white">



            <fa-icon [icon]="['fal','cog']"></fa-icon>
            <span class="pl-2">Sorteringsindstillinger</span>
          </button>
          <button class="inline-block cursor-pointer px-5">



            <fa-icon [icon]="['fal','copy']"></fa-icon>
            <span class="pl-2">{{hasCopied ? 'Den er kopieret' : 'Klik på Menuen for at kopiere den!'}}</span>
          </button>
        </div>
      </div>`
      document.body.appendChild(textbox);
      textbox.focus();
      textbox.select();
      document.execCommand('copy');
      document.body.removeChild(textbox);
      this.hasCopied = true;
      setTimeout(() => { this.hasCopied = false }, 3000);
  }
}
