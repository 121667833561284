import { Component, OnInit } from '@angular/core';
import { LockerComponentStateService } from 'projects/bexcomponent-design/src/lib/locker/locker-state.service';
import { ModalService } from 'projects/bexcomponent-design/src/lib/_modal/modal.service';
import { ILockerComponentModel } from 'projects/bexcomponent-design/src/public-api';
import { UserLockModalComponent } from './user-lock-modal/user-lock-modal.component';
import { UserUnlockModalComponent } from './user-unlock-modal/user-unlock-modal.component';

@Component({
  selector: 'app-locker-presenter',
  templateUrl: './locker-presenter.component.html',
  styleUrls: ['./locker-presenter.component.css']
})
export class LockerPresenterComponent implements OnInit {
  docLink: string = 'https://incomit.atlassian.net/l/c/DMamm17o';

  lockKey: string = "lock1";
  lockerModel: ILockerComponentModel;

  constructor(
    private modalService: ModalService,
    private lockerStateService: LockerComponentStateService) { }

  ngOnInit() {


    this.lockerModel = this.lockerStateService.createLock(
      this.lockKey,
      ((cb) => {
        this.challengeLock((accepted: boolean) => {
          if (!accepted) return;

          cb(accepted);
        })

      }),
      ((cb) => { //always called if other tries to unlock 
        this.tryToUnLock(((accepted: boolean) => {
          console.log('un-lock function', accepted);
          if (!accepted) return;
          cb(accepted);
          //TODO: disable something
        }))
      }), true, false
    );


  }

  /**
   * Challenge to un-lock, if user does NOT accept the consequencs DO NOT unlock
   * @param unlockFunction 
   */
  tryToUnLock(unlockFunction: Function) {

    this.modalService.open(UserUnlockModalComponent,
      {},
      undefined,
      ((accepted: boolean) => unlockFunction(accepted)),
      ["incom-modal", "h-auto", "mx-3", "md:mx-auto"]
    );

  }

  /**
   * Challenge to lock, inform user about consequences
   * @param unlockFunction 
   */
  challengeLock(lockFunction: Function) {
    this.modalService.open(UserLockModalComponent,
      {},
      undefined,
      ((accepted: boolean) => lockFunction(accepted)),
      ["incom-modal", "h-auto", "mx-3", "md:mx-auto"]
    );
  }


  onTextChange($event: any) {
    if (this.lockerModel.isLocked) return;

    this.lock(null, true);
  }

  /**
   * Lock 
   * @param $event NOT used
   * @param preventPropagation specify whether or NOT initialized propagations (challenges) should be SKIP or performed
   */
  lock($event: any, preventPropagation: boolean = false): void {
    this.lockerStateService.lock(this.lockKey, preventPropagation);
  }

  /**
   * Unlock
   * @param $event NOT used
   * @param preventPropagation specify whether or NOT initialized propagations (challenges) should be SKIP or performed
   */
  unlock($event: any, preventPropagation: boolean = false): void {
    this.lockerStateService.unlock(this.lockKey, preventPropagation);
  }




}
