import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmoothHeightAnimDirective } from './directives.api';

const providers = [];

@NgModule({
    declarations: [SmoothHeightAnimDirective],
    imports: [
        CommonModule
    ],
    exports: [
        SmoothHeightAnimDirective
    ]
})
export class AnimationsModule { }

@NgModule({})
export class AnimationsSharedModule {
    static forRoot(): ModuleWithProviders<AnimationsModule> {
        return {
            ngModule: AnimationsModule,
            providers: providers
        }
    }
}
