<div class="w-full py-12 inline-block">
  <h1>
    Input felter
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="mt-10 w-full sm:w-1/2">
    <div class="bg-gray-400 p-5 inline-block w-full">
      <div class="bg-white shadow-md w-full py-4 px-2 rounded">

        <div class="w-full mb-5">
          <p class="text-gray-800 p-2">Select with label </p>
          <div class="select-wrapper input-border-radius bg-white inline-block sm:w-10/12 w-11/12">
            <select class=" input-border-radius input-base pr-8 pl-16 px-2" id="company" required [(ngModel)]="stuff">
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base w-11/12 default-label">Type:</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
          <fa-icon class="inline-block w-1/12 sm:w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateSelectWithLabelContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedSelectWithLabel"> Den er kopieret</p>

        <div class="w-full mb-5">
          <p class="text-gray-800 p-2">Regular select</p>
          <div class="select-wrapper input-border-radius bg-white inline-block sm:w-10/12 w-11/12" [ngClass]="{'active': stuff.length > 0}">
            <select class="input-border-radius input-base" id="company" required [(ngModel)]="stuff">
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base w-11/12">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
          <fa-icon class="inline-block w-1/12 sm:w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateSelectContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedSelect"> Den er kopieret</p>

        <div class="w-full mb-5">
          <p class="text-gray-800 p-2"> Disabled select</p>
          <div class="select-wrapper input-border-radius bg-white inline-block sm:w-10/12 w-11/12" [ngClass]="{'active': stuff.length > 0}">
            <select class="input-border-radius input-base" id="company" [(ngModel)]="stuff" disabled>
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base w-11/12">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
          <fa-icon class="inline-block w-1/12 sm:w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateDisabledSelectContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedDisabledSelect"> Den er kopieret</p>

        <div class="w-full">
          <p class="text-gray-800 p-2">Input</p>
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block w-10/12">
            <input id="header" class="input-border-radius focus:border-primary input-base" required type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateInputContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedInput">Den er kopieret</p>

        <div class="w-full">
          <p class="text-gray-800 p-2">Grå input</p>
          <div class="mb-5 input-wrapper gray-input-wrapper input-border-radius inline-block w-10/12">
            <input id="header" class="input-border-radius gray-input focus:border-primary input-base" required type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateGrayInputContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedGrayInput">Den er kopieret</p>

        <div class="w-full">
          <p class="text-gray-800 p-2">Disabled input</p>
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block w-10/12">
            <input id="header" class="input-border-radius input-base focus:border-primary" type="text" placeholder=" " disabled>
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateDisabledInputContent()"></fa-icon>
        </div>

        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedDisabledInput">Den er kopieret</p>
        <div class="w-full">
          <p class="text-gray-800 p-2">Input med icon</p>
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block w-10/12">
            <input id="header" class="input-border-radius input-base pr-8 focus:border-primary" type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
            <fa-icon class="input-icon" [icon]="['fal', 'search']"></fa-icon>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateInputWithIconContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedInputWithIcon">Den er kopieret</p>
        <p class="text-gray-800 p-2">Textarea</p>
        <div class="w-full flex">
          <div class="mb-5 textarea-wrapper bg-white inline-block w-10/12">
            <textarea id="description" required class=" textarea-base focus:border-primary textarea-border-radius" type="text" placeholder=" "></textarea>
            <label class="input-label-base " for="description">Beskriv fejlen her</label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateTextareaContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedTextarea">Den er kopieret</p>

        <p class="text-gray-800 p-2">Disabled textarea</p>
        <div class="w-full flex">

          <div class="mb-5 textarea-wrapper bg-white inline-block w-10/12">
            <textarea id="description" disabled class="textarea-base focus:border-primary textarea-border-radius" type="text" placeholder=" "></textarea>
            <label class="input-label-base" for="description">Beskriv fejlen her</label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateDisabledTextareaContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedDisabledTextarea">Den er kopieret</p>


        <p class="text-gray-800 p-2">Hr</p>
        <div class="w-full flex">

          <hr class="hr-base" />
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateHr()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedHr">Den er kopieret</p>
      </div>

      <div class="mt-5">

        <p class="text-gray-800 p-2">Header input</p>
        <div class="w-full flex">
          <div class="header-input-wrapper inline-block w-full input-border-radius w-10/12">
            <input id="header" class="input-base header-input " type="text" placeholder=" ">
            <label class="input-label-base px-0" for="header"> Søg på navn, interessentnr., adresse eller lejemålsnr. </label>
            <fa-icon [icon]="['fal','search']" class="input-icon "></fa-icon>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateHeaderInputContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedHeaderInput">Den er kopieret</p>
      </div>

      <div class="mt-5">

        <p class="text-gray-800 p-2">Select Header input</p>
        <div class="w-full flex">
          <div class="header-input-wrapper input-border-radius bg-white inline-block sm:w-10/12 w-11/12" [ngClass]="{'active': stuff.length > 0}">
            <select class="input-border-radius header-input input-base" id="company" required [(ngModel)]="stuff">
              <option class="px-2" value="" hidden></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base w-11/12">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon text-xl"></fa-icon>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateSelectHeaderContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedHeaderSelect">Den er kopieret</p>
      </div>

      <div class="mt-5">

        <p class="text-gray-800 p-2">Bigger header input</p>
        <div class="w-full flex">
          <div class="header-input-wrapper header-bigger inline-block w-full input-border-radius w-10/12">
            <input id="header" class="input-base header-input border-b focus:border-primary" type="text" placeholder=" ">
            <label class="input-label-base px-0 " for="header"> Navngiv dit registreringsskema </label>
          </div>
          <fa-icon class="inline-block w-2/12 text-right" [icon]="['fal', 'copy']" (click)="generateBiggerHeaderInputContent()"></fa-icon>
        </div>
        <p class="mb-10 font-medium text-xs" *ngIf="hasCopiedBiggerHeaderInput">Den er kopieret</p>
      </div>

    </div>
  </div>
</div>
