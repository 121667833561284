<div class="incom-modal-header text-black bg-white">
  <h2 class="pl-4 pr-4">
      Er du sikker?
  </h2>
  <fa-icon class="incom-modal-close-icon" (click)="close()" [icon]="['fal', 'times']"> </fa-icon>
</div>
<div class="incom-modal-content">
  <div class="mt-2">
      <p class="font-bold">Du er ved at låse denne handlingstype op.</p>
      <p>Hvis du vælger at accepter følgende, vil du miste dine ændringer når du har valgt en anden handlingstype fra listen.</p>
      <p>Bemærk at dette også kan have konsekvenser for dine undergrene, hvis de er afhængige af denne handlingstype</p>
  </div>
</div>
<div class="incom-modal-footer p-3">
  <button class="btn btn-deny btn-outline btn-border-radius float-left" (click)="close()">
      <fa-icon class="text-base mr-2 leading-none" [icon]="['far', 'times']"> </fa-icon><span
          class="font-medium first-letter-capitalize">Anullere</span>
  </button>
  <button class="btn btn-accept btn-outline btn-border-radius float-right disabled:opacity-50" (click)="confirm($event)">
      <fa-icon class="text-base mr-2 leading-none" [icon]="['far', 'check']"> </fa-icon><span
          class="font-medium first-letter-capitalize">Accepter</span>
  </button>
</div>