import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'line-fold-out',
    templateUrl: './line-fold-out.component.html',
    animations: [
        trigger('item', [
            transition(':enter',[
                style({ transform: 'translateX(+100%)', opacity: 0 }),  // initial
                animate('0.3s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(0)', opacity: 1 }))  // final
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0%)', opacity: 1 }),  // initial                
                animate('0.3s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(+100%)', opacity: 0 }))  // final                   
            ])
        ])
    ]

})
export class LineFoldOutComponent implements OnInit {
  @Input() width: string = "w-full"
  @Input() foldUp: boolean = false;
  @Input() breakpoint: string = "sm";
  constructor() { }

  ngOnInit() {
  }

}
