import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'custom-input',
    templateUrl: 'custom-input.component.html',
    styleUrls: ['custom-input.component.css']
})
export class customInputComponent implements OnChanges {
    @Input() id: number;
    @Input() color: string = "text-primary";
    @Input() style: string = "";
    @Input() bg: string = "bg-white";
    @Input() type: string = "";
    @Input() boxAlignment: string = "items-start"
    @Input() hasError: boolean = false
    @Input() indeterminate: boolean = false;
    checkMarkPosition: string = "top"
    displayColor: string;
    displayBg: string;
    errorColor: string = 'text-red-600';
    errorBg: string = 'bg-red-600';
    indeterminateClass: string = "";

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boxAlignment) {
            if (this.boxAlignment === "items-start") {
                this.checkMarkPosition = 'top'
            }

            else {
                this.checkMarkPosition = 'center'
            }
        }
        if (changes.hasError || this.color || this.bg) {
            if (this.hasError) {
                this.displayColor = this.errorColor;
                this.displayBg = this.errorBg;
            }
            else {
                this.displayColor = this.color;
                this.displayBg = this.bg;
            }
        }
        if (changes.indeterminate) {
            if (this.indeterminate) {
                this.indeterminateClass = "indeterminate-states";
            }
            else {
                this.indeterminateClass = "";
            }

        }

    }
}
