<div class="w-full py-12 inline-block">
  <h1>
    Bottom menu
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="mt-10 w-full relative bg-gray-400">
    <div class="container mx-auto ">
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>
      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>

      <div class="fixed bottom-2/10 inset-x-0 flex items-center justify-center">
        <div class=" bg-gray-900 text-white flex items-center justify-center shadow-lg  rounded-full px-4 py-2" (click)="generateStringContent()">
          <button class="inline-block cursor-pointer px-5 border-r border-white">
            <fa-icon [icon]="['fal','cog']"></fa-icon>
            <span class="pl-2">Sorteringsindstillinger</span>
          </button>
          <button class="inline-block cursor-pointer px-5">
            <fa-icon [icon]="['fal','copy']"></fa-icon>
            <span class="pl-2">{{hasCopied ? 'Den er kopieret' : 'Klik på Menuen for at kopiere den!'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
