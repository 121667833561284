import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-circular-percentage-presenter',
  host: {
    class: 'w-full inline-block',
  },
  templateUrl: './chart-circular-percentage-presenter.component.html',
  styleUrls: ['./chart-circular-percentage-presenter.component.css',]
})
export class ChartCircularPercentagePresenterComponent implements OnInit {

  docLink: string = 'https://incomit.atlassian.net/l/c/QnQBoCp3';

  types: string[] = ['green','orange', 'red', 'blue' ];


  selectedType: string = 'orange';
  chartValue: number = 30;
  chartText: string = '70.362,62 DKK';
  descriptionHeader: string = 'Planlagt og periodisk vedligeholdelse';
  descriptionTxt: string = '675.571,48 DKK';

  constructor() { }

  ngOnInit() {
  }

}
