
<div class="w-full py-12 inline-block">
  <h1>
    Ikoner
  </h1>
  <p class="font-medium mb-2">Indstillinger</p>
  <section class="pb-10 pt-4">
    <div class="w-full flex">
      <div class="form-group">
        <label class="block mb-1 font-medium text-gray-700 text-sm">Vælg ikonstil</label>
        <select [(ngModel)]="selectedIconStyle" class="border border-gray-600  h-8 rounded-full px-2 py-1 bg-white appearance-none leading-tight">
          <option *ngFor="let iconStyle of iconStyles" [value]="iconStyle.Name">
            {{iconStyle.Name}}
          </option>
        </select>
      </div>
      <div class="form-group ml-8">
        <label class="block mb-1 text-sm font-medium text-gray-700">Vælg farve</label>
        <select [(ngModel)]="selectedColor" class="border rounded-full border-gray-600 h-8 px-2 py-1 bg-white appearance-none leading-tight" [ngClass]="selectedColor">
          <option *ngFor="let color of colors" [value]="color.Name" class="text-white" [ngClass]="color.Name">{{color.Name}}</option>
        </select>
      </div>
    </div>
  </section>

  <div class="w-full py-10 bg-gray-500">
    <div class="mx-auto sm:w-11/12 w-full">
      <div class=" flex w-full flex-wrap ">
        <p class="mb-5 font-medium text-base w-full text-center ">{{hasCopiedIcon ? (selectedIcon + ' er kopieret') : 'Klik på et ikon for at kopiere den!'}}</p>
        <button class="flex-col flex items-center m-2 p-2 bg-white rounded-lg shadow-md w-32 cursor-pointer active:bg-gray-500" *ngFor="let icon of icons" (click)="generateIconContent(icon.Name)">
          <fa-icon class="text-4xl {{selectedColor}} bg-white p-1" [icon]="[selectedIconStyle, icon.Name]"></fa-icon>
          <span class="text-xs font-semibold">{{icon.Name}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
