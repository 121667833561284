import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'text-presenter',
  templateUrl: './text-presenter.component.html',
  styleUrls: ['./text-presenter.component.css']
})
export class TextPresenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
