import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'scrollspy-presenter',
  templateUrl: './scrollspy-presenter.component.html',
  styleUrls: ['./scrollspy-presenter.component.css']
})
export class ScrollspyPresenterComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort: CdkVirtualScrollViewport;
  constructor() { }

  ngOnInit() {
    for (var i = 0; i < 150; i++) {
      this.TestArray2.push({
        Name: 'Tekøkken' + i,
        Description: "Dolor erat lacus enim vel tempus dolor eget et tempus commodo ipsum cursus tincidunt aliquam phasellus et magna maecenas nunc maximus accumsan ut ut proin. Nulla metus euismod rutrum nulla euismod sollicitudin euismod maecenas tempus varius tortor mi nec mi enim ac eros et eu id leo eros congue ac. Dolor erat lacus enim vel tempus dolor eget et tempus commodo ipsum cursus tincidunt aliquam phasellus et magna maecenas nunc maximus accumsan ut ut proin. Nulla metus euismod rutrum nulla euismod sollicitudin euismod maecenas tempus varius tortor mi nec mi enim ac eros et eu id leo eros congue ac."
      })
    }
  }

  TestArray1 =
    ['køkken', 'entre', 'værelse1', 'værelse2', 'stue'
    ];

  TestArray2 = []


  currentSection = 'køkken';

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo2(element) {
    const selectedIndex = this.TestArray2.findIndex(elem => elem.Name === element.Name);
    if (selectedIndex > -1) {
      this.viewPort.scrollToIndex(selectedIndex, 'smooth');
    }
  }

  scrollTo1(section: string) {
    var element = document.querySelector('#' + section);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

}
