import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-chart-circular-percentage',
  templateUrl: './chart-circular-percentage.component.html',
  styleUrls: ['./chart-circular-percentage.component.css']
})
export class ChartCircularPercentageComponent implements OnInit {

  @Input() status: string = 'blue';
  @Input() value: number = 60;
  @Input() text: string = '-';


  @Input() x: string = '52%';
  @Input() y: string = '54%';


  constructor() { }

  ngOnInit() {
  }


}

