import { Injectable, ComponentFactoryResolver, Injector, Inject, TemplateRef, Type, ApplicationRef, EmbeddedViewRef, OnDestroy, ComponentRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalBase } from './IModalBase.interface';

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({
    providedIn: 'root',
})
export class ModalService implements OnDestroy {

    constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: any) { }
    private modals: any[] = [];

    /** Creates a modal of the given type, assigning the given paramaeters to their corresponding properties
     *  @returns A tuple containing first the base Modal instance followed by the ModalComponent instance allowing custom operations on these. Such as markDirty*/
    open(component: Type<ModalBase>, data: any, closeFunction: Function = () => { }, successFunction: Function = () => { }, customStyles: string[] = [], hideCloseIcon: boolean = false): [ModalBase, ModalComponent] {
        const guid = this.uuidv4();

        const modalToRender = this.setupModalForRendering(component, data, closeFunction, successFunction, guid);
        const wrapperModal = this.setupWrapperModalForRendering(modalToRender, guid, hideCloseIcon);

        this.appRef.attachView(wrapperModal.hostView);
        this.appRef.attachView(modalToRender.hostView);

        const modalElement = (modalToRender.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const domElem = (wrapperModal.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        if (modalToRender.instance.scrollStrategy == 'reposition') {
            this.fixModalScrollposition(domElem)
        }
        const modalBodyEl = domElem.querySelector(".incom-modal-body");
        this.setupStylingForModalElement(domElem, customStyles, modalBodyEl);

        modalBodyEl.append(modalElement);
        this.document.body.appendChild(domElem);

        this.modals.push({
            alias: guid,
            component: wrapperModal,
            childComponent: modalToRender
        });

        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', () => {
                this.modals.forEach(x => this.close(x.alias, false))
            });
        }
        return [modalToRender.instance, wrapperModal.instance];
    }

    /** Handles setting up the given styling, aswell as the base stuff for the given modal eleement*/
    private setupStylingForModalElement(domElem: HTMLElement, customStyles: string[], modalBodyEl: Element) {
        const backdrop = domElem.querySelector(".incom-modal-backdrop");
        const container = domElem.querySelector(".incom-modal-container");
        if (customStyles.some(x => x == "incom-modal-tab-overlay")) {
            backdrop.classList.add("hidden");
            container.classList.add("pointer-events-none", "overflow-hidden-important");
        }
        customStyles.forEach(x => {
            modalBodyEl.classList.add(x);
        });
    }

    /** Sets up the wrapper modal, by resolving the necessary factory for the base component and setting up the basic requirements like ID and the close function.*/
    private setupWrapperModalForRendering(modalToRender: ComponentRef<ModalBase>, guid: string, hideCloseIcon: boolean) {
        const factory = this.resolver.resolveComponentFactory(ModalComponent);
        const wrapperModal = factory.create(this.injector);
        wrapperModal.instance.hideCloseIcon = hideCloseIcon;
        wrapperModal.instance.close = () => modalToRender.instance.closed$.next(true);
        wrapperModal.instance.alias = guid;
        return wrapperModal;
    }

    /** Resolves the specific modalbase, creates the actual component and ensures that provided data is set and accessible in the resulting modal */
    private setupModalForRendering(component: Type<ModalBase>, data: any, closeFunction: Function, successFunction: Function, guid: string): ComponentRef<ModalBase> {
        const ModalToRenderfactory = this.resolver.resolveComponentFactory(component);
        const modalToRender = ModalToRenderfactory.create(this.injector);
        modalToRender.instance.data = data;
        modalToRender.instance.onClose = closeFunction;
        modalToRender.instance.onSuccess = successFunction;

        if (modalToRender.instance.scrollStrategy == 'block') {
            this.fixBodyScrollposition();
        }

        const closeSubscription = modalToRender.instance.closed$.subscribe(notSuccess => {
            this.close(guid, !notSuccess);
            modalToRender.instance.closed$.complete();
            closeSubscription.unsubscribe();
        });
        return modalToRender;
    }

    ngOnDestroy(): void {
        window.removeEventListener("popstate", () => {

            this.modals.forEach(x => this.close(x.alias, false))
        })
    }
    uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    /*
     Gets the scroll position and adds it after the body is fixed
     */
    fixBodyScrollposition() {
        const scrollY = `${window.scrollY}px`
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
    }

    /*
    Gets the scroll position and adds it to the modal
    */
    fixModalScrollposition(elm) {
        const modalwrapper = elm.querySelector(".incom-modal-container");
        const scrollY = `${window.scrollY}px`
        modalwrapper.style.height = 'auto';
        modalwrapper.style.position = 'absolute';
        modalwrapper.style.top = `${scrollY}`;
    }
    /*
    Gets the scroll position and adds it after the body is unfixed
    */
    returnWindowToFormerScrollposition() {
        const body = document.body;
        if (body.style.position == 'fixed') {
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }

    close(alias: string, success: boolean) {
        this.returnWindowToFormerScrollposition();
        const wrapper = this.modals.find(x => x.alias === alias);
        if (!wrapper) {
            console.error("Could not find the requested modal to close. Did you already close the modal?");
            return;
        }
        this.modals.splice(this.modals.indexOf(wrapper), 1);
        const domElem = (wrapper.component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const modalBodyEl = domElem.querySelector(".incom-modal-body");
        modalBodyEl.classList.add("closed");

        setTimeout(() => {
            this.document.body.removeChild(domElem);
            if (!success && wrapper.childComponent._component) {
                wrapper.childComponent._component.onClose();
            }
            this.appRef.detachView(wrapper.childComponent.hostView);
            this.appRef.detachView(wrapper.component.hostView);

        }, 300);
        window.removeEventListener("popstate", () => {

            this.modals.forEach(x => this.close(x.alias, false))
        })
    }
}
