import { Component, OnInit } from '@angular/core';
import { GrowlerService, GrowlerMessageType, GrowlerPosition } from '../../../../projects/bexcomponent-design/src/public-api';

@Component({
  selector: 'growl-presenter',
    templateUrl: './growl-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    },
})
export class GrowlPresenterComponent implements OnInit {
    selectedType: GrowlerMessageType = GrowlerMessageType.Info;
    duration: number = 4000;
    selectedPosition: GrowlerPosition = GrowlerPosition.Bottom;
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/490766337/Growls"
    selectedText: string = "Hvad skal der stå i din growl"

    constructor(private growlerService: GrowlerService) { }

    ngOnInit() {
    }
    openFeedback() {
        this.growlerService.growl(this.selectedText, this.selectedType, this.selectedPosition, this.duration);
    }

    types = [
        { Name: "Info", Type: GrowlerMessageType.Info },
        { Name: "Fejl", Type: GrowlerMessageType.Danger },
        { Name: "Advarsel", Type: GrowlerMessageType.Warning },
        { Name: "Succes", Type: GrowlerMessageType.Success },
    ]

    positions = [
        { Name: "Toppen til venstre", Place: GrowlerPosition.TopLeft },
        { Name: "Toppen i midten", Place: GrowlerPosition.Top },
        { Name: "Toppen til højre", Place: GrowlerPosition.TopRight },
        { Name: "Midten til venstre", Place: GrowlerPosition.MiddleLeft },
        { Name: "Midten i midten", Place: GrowlerPosition.Middle },
        { Name: "Midten til højre", Place: GrowlerPosition.MiddleRight },
        { Name: "Bunden til venstre", Place: GrowlerPosition.BottomLeft },
        { Name: "Bunden i midten", Place: GrowlerPosition.Bottom },
        { Name: "Bunden til højre", Place: GrowlerPosition.BottomRight },
    ]

    onPositionChange($event) {
        switch ($event) {
            case "0":
                this.selectedPosition = GrowlerPosition.TopLeft
                break;
            case "1":
                this.selectedPosition = GrowlerPosition.Top
                break;
            case "2":
                this.selectedPosition = GrowlerPosition.TopRight
                break;
            case "3":
                this.selectedPosition = GrowlerPosition.MiddleLeft
                break;
            case "4":
                this.selectedPosition = GrowlerPosition.Middle
                break;
            case "5":
                this.selectedPosition = GrowlerPosition.MiddleRight
                break;
            case "6":
                this.selectedPosition = GrowlerPosition.BottomLeft
                break;
            case "7":
                this.selectedPosition = GrowlerPosition.Bottom
                break;
            case "8":
                this.selectedPosition = GrowlerPosition.BottomRight
                break;

            default:
        }
    }
}
