<div class="w-full py-12 inline-block">
  <h1>
    Loaders
  </h1>
  <p class="font-medium mb-2">Indstillinger</p>
  <section>
      <div class="w-full flex items-start">
          <div class="form-group">
              <label class="block mb-1 font-medium text-gray-700 text-sm">Vælg delay (i ms)</label>
              <input [(ngModel)]="delay" type="number" placeholder="Vælg delay"
                     class="border rounded-full px-2 py-1 bg-white appearance-none leading-tight" />
          </div>
      </div>
      <div class="w-full flex mb-5">
          <div class="form-group">
              <label class="block mb-1 font-medium text-gray-700 text-sm">Vælg varighed (i ms)</label>
              <input [(ngModel)]="duration" type="number" placeholder="Vælg varighed"
                     class="border rounded-full px-2 py-1 bg-white appearance-none leading-tight" />
          </div>
      </div>
      <div class="select-wrapper input-border-radius bg-white inline-block mb-5 w-40" [ngClass]="{'active': loaderSize.length > 0}">
          <select [(ngModel)]="loaderSize" class="input-border-radius input-base">
              <option *ngFor="let size of loaderSizes" [value]="size.Value">{{size.Name}}</option>
          </select>
          <label class="input-label-base">Vælg størrelse</label>
          <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>
      <div class="select-wrapper input-border-radius bg-white inline-block mb-5 ml-5 w-40" [ngClass]="{'active': loaderType.length > 0}">
          <select [(ngModel)]="loaderType" class="input-border-radius input-base">
              <option *ngFor="let type of loaderTypes" [value]="type.Value">{{type.Name}}</option>
          </select>
          <label class="input-label-base">Vælg type</label>
          <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>
      <div class="select-wrapper input-border-radius bg-white inline-block mb-5 w-40 ml-5 active">
          <select [(ngModel)]="loaderBackground" class="input-border-radius input-base" (ngModelChange)="updateBackground($event)">
              <option *ngFor="let loaderBackgroundValue of loaderBackgroundValues" [value]="loaderBackgroundValue.Value">{{loaderBackgroundValue.Name}}</option>
          </select>
          <label class="input-label-base">Baggrund</label>
          <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>
      <div class="w-full flex">
          <div class="form-group">
              <button-component [type]="'primary'" [style]="'solid'" (click)="toggleLoader()" class="inline-block">
                  <fa-icon class="text-2xl mr-2 leading-none" [icon]="['fal', 'play']"> </fa-icon>
                  <span class="font-medium">Vis/Skjul loader</span>
              </button-component>
          </div>
      </div>
  </section>
  <p class="font-medium mb-2 mt-10">Implementation</p>

  <section class="p-3" [incomLoader]="'demo_area'" [loaderSize]="loaderSize" [showBackground]="loaderBackground" [loaderType]="loaderType">
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur est at ducimus incidunt rem nobis iure,
      libero rerum molestias quod sapiente quia. Tempore deserunt repellat eum suscipit distinctio deleniti
      nostrum.
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur est at ducimus incidunt rem nobis iure,
      libero rerum molestias quod sapiente quia. Tempore deserunt repellat eum suscipit distinctio deleniti
      nostrum.
    </p>
  </section>
</div>
