import { Component } from '@angular/core';

@Component({
  selector: 'icons',
    templateUrl: './icons.component.html',
    host: {
        class: 'w-full inline-block',
    },
})
export class IconsComponent {
    icons = [
        { Name: "ballot-check" },
        { Name: "ban" },
        { Name: "check" },
        { Name: "minus-circle" },
        { Name: "search" },
        { Name: "plus" },
        { Name: "calendar-exclamation" },
        { Name: "calendar-edit" },
        { Name: "save" },
        { Name: "long-arrow-right" },
        { Name: "long-arrow-left" },
        { Name: "user" },
        { Name: "user-edit" },
        { Name: "user-circle" },
        { Name: "print" },
        { Name: "times" },
        { Name: "eye" },
        { Name: "images" },
        { Name: "tools" },
        { Name: "clipboard-check" },
        { Name: "clipboard-list-check" },
        { Name: "mobile" },
        { Name: "envelope" },
        { Name: "file-pdf" },
        { Name: "user-hard-hat" },
        { Name: "hotel" },
        { Name: "hammer" },
        { Name: "key" },
        { Name: "angle-up" },
        { Name: "angle-down" },
        { Name: "check" },
        { Name: "arrows-alt" },
        { Name: "cog" },
        { Name: "trash" },
        { Name: "search" },
        { Name: "info-circle" },
        { Name: "calendar" },
        { Name: "bullhorn" },
        { Name: "exclamation-triangle" },
        { Name: "hourglass-half" },
        { Name: "comment-alt-lines" },
        { Name: "history" },
        { Name: "camera" },
        { Name: "home" },
        { Name: "question-circle" },
        { Name: "long-arrow-down" },
        { Name: "ellipsis-v" },
        { Name: "edit" },
        { Name: "bars" },
        { Name: "star" },
        { Name: "font-case" },
        { Name: "expand-arrows-alt" },
        { Name: "users-cog" },
        { Name: "toolbox" },
        { Name: "life-ring" },
        { Name: "map-marker-alt" },
        { Name: "file-alt" },
        { Name: "clipboard-list" },
        { Name: "file-invoice" },
        { Name: "address-book" },
        { Name: "archive" },
        { Name: "border-style" },
        { Name: "receipt" },

    ]

    colors = [
        { Name: "text-black" },
        { Name: "text-white" },
        { Name: "text-gray-300" },
        { Name: "text-primary" },
        { Name: "text-helpdesk" },
        { Name: "text-red-400" },
        { Name: "text-orange-400" },
        { Name: "text-yellow-400" },
        { Name: "text-green-400" },
        { Name: "text-teal-400" },
        { Name: "text-blue-400" },
        { Name: "text-indigo-400" },
        { Name: "text-purple-400" },
        { Name: "text-pink-400" },
    ]

    iconStyles = [
        { Name: "far" },
        { Name: "fal" },
        { Name: "fas" },
    ]
    hasCopiedIcon: boolean;
    selectedIconStyle: string = "far";
    selectedColor: string = 'text-green-400';
    selectedIcon: string;

    generateIconContent(icon: string) {
        this.selectedIcon = icon;
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = `
         <fa-icon class="text-base leading-none ${this.selectedColor}" [icon]="['${this.selectedIconStyle}', '${icon}']"></fa-icon>
        `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedIcon = true;
        setTimeout(() => { this.hasCopiedIcon = false }, 3000);
    }
}
