import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GrowlerPosition, IGrowl } from '../growler.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'growler-item',
    templateUrl: './growler-item.component.html',
    host: {
        class: 'w-full xl:w-1/4 lg:w-1/2 ',
    },
    animations: [
        trigger('growlAnimation', [
            transition('void => left', [
                style({ transform: 'translateX(-50%)', opacity: 0 }),  // initial
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(0)', opacity: 1 }))  // final
            ]),
            transition('left => void', [
                style({ transform: 'translateX(0%)', opacity: 1 }),  // initial                
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(-50%)', opacity: 0 }))  // final                   
            ]),
            transition('void => right', [
                style({ transform: 'translateX(+50%)', opacity: 0 }),  // initial
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(0)', opacity: 1 }))  // final
            ]),
            transition('right => void', [
                style({ transform: 'translateX(0%)', opacity: 1 }),  // initial                
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(+50%)', opacity: 0 }))  // final                   
            ]),
            transition('void => top', [
                style({ transform: 'translateY(-50%)', opacity: 0 }),  // initial
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateY(0)', opacity: 1 }))  // final
            ]),
            transition('top => void', [
                style({ transform: 'translateX(0%)', opacity: 1 }),  // initial                
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateY(-50%)', opacity: 0 }))  // final                   
            ]),
            transition('void => bottom', [
                style({ transform: 'translateY(+50%)', opacity: 0 }),  // initial
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateY(0)', opacity: 1 }))  // final
            ]),
            transition('bottom => void', [
                style({ transform: 'translateX(0%)', opacity: 1 }),  // initial                
                animate('0.4s  cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateY(+50%)', opacity: 0 }))  // final
            ])
            ]),
    ]
})
export class GrowlerItemComponent implements OnInit, OnDestroy {
    componentDestroyed$ = new Subject<any>();
    @Input() growl: IGrowl;
    @Output() deleteGrowl = new EventEmitter();
    isMobile: boolean;
    constructor(public breakpointObserver: BreakpointObserver) { }
    animateGrowl: string;
    alertIcon: string = "circle-info";
    ngOnInit() {

        this.breakpointObserver
            .observe(['(max-width: 641px)'])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isMobile = true;
                } else {
                  this.isMobile = false;
                }
            });
        this.setPositionAnimation(this.growl.position);
        this.setIconType(this.growl.messageType);
    }
    setIconType(messageType: string) {
        switch (messageType) {
            case "alert-danger":
              this.alertIcon = 'bell';
                break;
            case "alert-success":
              this.alertIcon = 'circle-check';
                break;
            case "alert-warning":
              this.alertIcon = 'circle-exclamation';
                break;
            default:
        }
    }
    setPositionAnimation(position: GrowlerPosition) {
        if (this.isMobile) {
           return this.animateGrowl = "bottom";
        }
        switch (position) {
            case GrowlerPosition.TopLeft:
            case GrowlerPosition.MiddleLeft:
            case GrowlerPosition.BottomLeft:
                this.animateGrowl = "left";
                break;
            case GrowlerPosition.TopRight:
            case GrowlerPosition.MiddleRight:
            case GrowlerPosition.BottomRight:
                this.animateGrowl = "right";
                break;
            case GrowlerPosition.Top:
                this.animateGrowl = "top";
                break;
            case GrowlerPosition.Bottom:
            case GrowlerPosition.Middle:
                this.animateGrowl = "bottom";
                break;
            default:
        }
    }
    ngOnDestroy() {
        this.componentDestroyed$.next({});
        this.componentDestroyed$.complete();
    }
}
