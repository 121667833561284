import { Component } from '@angular/core';

@Component({
    selector: 'custom-input-presenter',
    templateUrl: './custom-input-presenter.component.html',
    styleUrls: ['./custom-input-presenter.component.css']
})
export class CustomInputPresenterComponent {
    selectedType: string = "checkbox";
    selectedStyle: string = "solid";
    selectedText: string = "";
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/BOL/pages/346161161/Custom+input";
    selectedBgColor: string = "bg-white";
    selectedColor: string = "text-primary";
    hasCopied: boolean = false;
    checked: boolean = false;
    isIndeterminate: boolean = false;

    types = [
        { Name: "checkbox" },
        { Name: "radio" },
    ]

    checkedValues = [
        { Name: "Checked", Value: true },
        { Name: "Unchecked", Value: false },
    ]

    indeterminateValues = [
        { Name: "Ja", Value: true },
        { Name: "Nej", Value: false },
    ]


    bgColors = [
        { Name: "bg-black" },
        { Name: "bg-white" },
        { Name: "bg-gray-300" },
        { Name: "bg-red-400" },
        { Name: "bg-orange-400" },
        { Name: "bg-yellow-400" },
        { Name: "bg-green-400" },
        { Name: "bg-teal-400" },
        { Name: "bg-blue-400" },
        { Name: "bg-indigo-400" },
        { Name: "bg-purple-400" },
        { Name: "bg-pink-400" },
        { Name: "bg-primary" },
        { Name: "bg-transparent" },
    ]

    Colors = [
        { Name: "text-black" },
        { Name: "text-white" },
        { Name: "text-gray-300" },
        { Name: "text-red-400" },
        { Name: "text-orange-400" },
        { Name: "text-yellow-400" },
        { Name: "text-green-400" },
        { Name: "text-teal-400" },
        { Name: "text-blue-400" },
        { Name: "text-indigo-400" },
        { Name: "text-purple-400" },
        { Name: "text-primary" },
        { Name: "text-pink-400" },
        { Name: "text-transparent" },
    ]

    styles = [
        { Name: "outline" },
        { Name: "solid" },
    ]

    generateStringContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = '<custom-input [style]="\'' + this.selectedStyle + '\'" [color]="\'' + this.selectedColor + '\'" [bg]="\'' + this.selectedBgColor + '\'" [type]="\'' + this.selectedType + '\'"><input class="w-0 h-0 hidden"[checked]="' + this.checked + '" type="' + this.selectedType + '"><span class="ml-2">' + (this.selectedText != "" ? this.selectedText : "") + '</span></custom-input>';
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopied = true;
        setTimeout(() => { this.hasCopied = false }, 3000);
    }
}
