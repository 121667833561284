import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { ImageUploaderFileContainer } from "./image-uploader.component";

@Directive({
  selector: '[maxFileSize]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ImageUploaderFileSizeDirective, multi: true }]
})
export class ImageUploaderFileSizeDirective implements Validator {
  /*
   * Expected to be in MB
   */
  @Input() maxFileSize: number;

  validate(control: AbstractControl): { [key: string]: any; } {
    const val = control.value as ImageUploaderFileContainer;
    if (!val || !val.selectedFiles || val.selectedFiles.length === 0)
      return null;

    const currentSize = (val.selectedFiles.map(file => file.size).reduce((a, b) => a + b)) / 1024 / 1024;
    if (currentSize > this.maxFileSize)
      return { 'fileSize': true, 'maxFileSize': this.maxFileSize };

    return null;
  }

}
