import { Directive, TemplateRef } from "@angular/core";
import { IGroupedListCategoryItemModel, IGroupedListCategoryModel, ViewContext } from "../group-list.models";


// generic type for the view context
export interface GroupedListCategoryItemModelViewContext extends ViewContext<IGroupedListCategoryItemModel> {
  parent: IGroupedListCategoryModel;
}

@Directive({ selector: '[GroupedListCategoryItem]' })
export class GroupedListCategoryItemDirective {

  /**
   * The template ref is optional but it tells the IDE that this is a structural directive
   * and it tells the implicit object type
   */
  constructor(private templateRef: TemplateRef<IGroupedListCategoryItemModel>) {
  }
}