
<section class="  w-full inline-block py-12 ">
  <h1>
    Fonte
  </h1>
  <div class="bg-gray-300 w-full py-10">
    <h1 class="py-2">Dette er her en h1</h1>
    <h2 class="py-2">Dette er her en h2</h2>
    <h3 class="py-2">Dette er her en h3</h3>
    <h4 class="py-2">Dette er her en h4</h4>
  </div>
</section>


