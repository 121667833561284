import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-presenter',
  templateUrl: './toggle-presenter.component.html',
})
export class TogglePresenterComponent implements OnInit {
    hasCopiedToggle: boolean;
    selectedStyle: string = "default";
    selectedText: string = "Vælg tekst";
    selectedPreColor: string = "bg-white";
    selectedColor: string = "text-primary";
    selectedLineBgColor:string = "bg-gray-700"
    docLink: string = "https://incomit.atlassian.net/l/c/ufLW0wYq";
    constructor() { }

    ngOnInit() {

    }


    preColors = [
        { Name: "bg-black" },
        { Name: "bg-white" },
        { Name: "bg-gray-300" },
        { Name: "bg-red-400" },
        { Name: "bg-orange-400" },
        { Name: "bg-yellow-400" },
        { Name: "bg-green-400" },
        { Name: "bg-teal-400" },
        { Name: "bg-blue-400" },
        { Name: "bg-indigo-400" },
        { Name: "bg-purple-400" },
        { Name: "bg-pink-400" },
        { Name: "bg-primary" },
        { Name: "bg-transparent" },
    ]

    lineBgColors = [
        { Name: "bg-black" },
        { Name: "bg-white" },
        { Name: "bg-gray-300" },
        { Name: "bg-red-400" },
        { Name: "bg-orange-400" },
        { Name: "bg-yellow-400" },
        { Name: "bg-green-400" },
        { Name: "bg-teal-400" },
        { Name: "bg-blue-400" },
        { Name: "bg-indigo-400" },
        { Name: "bg-purple-400" },
        { Name: "bg-pink-400" },
        { Name: "bg-primary" },
        { Name: "bg-transparent" },
    ]

    Colors = [
        { Name: "text-black" },
        { Name: "text-white" },
        { Name: "text-gray-300" },
        { Name: "text-red-400" },
        { Name: "text-orange-400" },
        { Name: "text-yellow-400" },
        { Name: "text-green-400" },
        { Name: "text-teal-400" },
        { Name: "text-blue-400" },
        { Name: "text-indigo-400" },
        { Name: "text-purple-400" },
        { Name: "text-primary" },
        { Name: "text-pink-400" },
        { Name: "text-transparent" },
    ]

    styles = [
        { Name: "small-line" },
        { Name: "default" },
    ]


    generateToggleContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
            <lib-toggle [style]="'${this.selectedStyle}'" [color]="'${this.selectedColor}'" [preColor]="'${this.selectedPreColor}'" [lineBg]="'${this.selectedLineBgColor}'">
                <input class="w-0 h-0 opacity-0 hidden" tabindex="0" type='checkbox'>
                <span class="ml-2">${this.selectedText}</span>
            </lib-toggle>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedToggle = true;
        setTimeout(() => { this.hasCopiedToggle = false }, 3000);
    }
}
