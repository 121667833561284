<ul *ngFor="let category of model.categories" [ngClass]="categoryClass" [smoothHeight]="category.showChild">
    <li>
        <div class="relative cursor-pointer" (click)="toggleShowChildren($event, category)">
            <span *ngIf="useTransclusion; else useStandardCategoryTemplate">
                <ng-container *ngTemplateOutlet="categoryTemplateRef; context:getCategoryContext(category)">
                </ng-container>
            </span>
            <ng-template #useStandardCategoryTemplate>
                <span>{{category.name}}</span>
            </ng-template>
            <hr [hidden]="!category.showChild" class="border-t border-gray-400" />
            <div class="absolute inset-y-0 right-0 leading-none flex items-center cursor-pointer origin-center justify-end z-20 transform duration-200 w-2 transition-transform"
                [ngClass]="{'cursor-pointer' : category.showChild , 'pr-3' : !category.showChild}">
                <fa-icon
                    class="absolute inset-y-0 right-0 leading-none flex items-center text-xl cursor-pointer origin-center justify-end z-20 transform duration-200 transition-transform"
                    [ngClass]="{'rotate-0' : category.showChild, '-rotate-180' : !category.showChild }"
                    [icon]="['fal', 'angle-up']"> </fa-icon>
            </div>
        </div>
        <div [hidden]="!category.showChild" *ngIf="useTransclusion">
            <ng-container *ngTemplateOutlet="categoryExtendedTemplateRef; context:getCategoryExtendedContext(category)">
            </ng-container>
        </div>
        <div [hidden]="!category.showChild">
            <ul [ngClass]="categoryItemClass">
                <li *ngFor="let categoryItem of category.children; index as categoryItemIndex">
                    <div class="">
                        <span *ngIf="useTransclusion; else useStandardCategoryItemTemplate">
                            <ng-container
                                *ngTemplateOutlet="categoryChildTemplateRef; context:getCategoryItemContext(categoryItem, category)">
                            </ng-container>
                        </span>
                        <ng-template #useStandardCategoryItemTemplate>
                            <span>{{categoryItem.name}}</span>
                            <group-list *ngIf="categoryItem.subCategories && categoryItem.subCategories.length > 0;"
                                [model]="{categories : categoryItem.subCategories}" [useTransclusion]="useTransclusion">
                            </group-list>
                        </ng-template>
                    </div>
                </li>
            </ul>
        </div>
    </li>
</ul>