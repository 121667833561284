<section class="  w-full inline-block py-12 ">
  <h1>
    Accordion
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="bg-gray-300 w-full py-10">
    <div class="container mx-auto">
      <button-component [type]="'primary'" [style]="'solid'" (click)="openValue = !openValue" class="inline-block"><span class="font-medium mr-2">Åben accordion</span><fa-icon class="text-2xl  leading-none" [icon]="['fal', 'angle-down']"> </fa-icon></button-component>

      <accordion class="w-full mt-10 inline-block" [isOpen]="openValue" [openOnHeaderClick]="true" [enableValidation]="true">
        <div header>
          <h3>Accordion</h3>
        </div>
        <div body>
          <div>
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    112
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>

      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Accordion table</h3>
        </div>
        <div body>
          <div class="pt-3">
            <table class="table-auto w-full p-5">
              <thead>
                <tr>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Navn</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Hvor</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Resourcer</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Startdato</th>
                  <th class="px-4 py-2 text-left text-gray-500 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Intro to CSS
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    A Long and Winding Tour of the History of UI
                  </td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">112</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">
                    Adam
                  </td>
                </tr>
                <tr>
                  <td class="border-t border-b px-4 py-2">
                    <fa-icon class="pr-1" [icon]="['fal', 'calendar-edit']"> </fa-icon>
                    Into to JavaScript
                  </td>
                  <td class="border-t border-b px-4 py-2">Chris</td>
                  <td class="border-t border-b px-4 py-2">1,280</td>
                  <td class="border-t border-b px-4 py-2">Adam</td>
                  <td class="border-t border-b px-4 py-2">858</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </accordion>

      <accordion class="w-full mt-10 inline-block">
        <div header>
          <h3>Eksempel på accordion med accordion</h3>
        </div>
        <div body>
          <div class="pt-3">
            <p class="w-4/5 inline-block">
              Pellentesque tortor purus metus ut lacus pellentesque portaest ac vivamus gravida vivamus maximus tortor et quam elementum erat bibendum interdum facilisis tortor tincidunt erat tincidunt.

              Purus tincidunt hendrerit phasellus nisl placerat portaest cursus nisl nunc cursus suspendisse diam leo placerat morbi ipsum tortor sed amet accumsan tempus congue sem arcu.
            </p>
            <button-component [type]="'primary'" [style]="'solid'" (click)="nestedOpenValue = !nestedOpenValue" class="inline-block w-1/5"><fa-icon class="text-2xl mr-2 leading-none" [icon]="['fal', 'search']"> </fa-icon><span class="font-medium">Åben fold out</span></button-component>
            <fold-out [isOpen]="nestedOpenValue">
              <div>
                <p>
                  Pellentesque tortor purus metus ut lacus pellentesque portaest ac vivamus gravida vivamus maximus tortor et quam elementum erat bibendum interdum facilisis tortor tincidunt erat tincidunt.

                  Purus tincidunt hendrerit phasellus nisl placerat portaest cursus nisl nunc cursus suspendisse diam leo placerat morbi ipsum tortor sed amet accumsan tempus congue sem arcu.
                </p>
              </div>
            </fold-out>
          </div>
        </div>

      </accordion>


      <accordion class="w-full mt-10 inline-block" [enableValidation]="'true'">
        <div header>
          <h3>Eksempel på accordion form</h3>
        </div>
        <div body>
          <div class="pt-3">
            <p class="w-4/5 inline-block">
              Test
            </p>

            <form #f="ngForm" name="form">
              <input id="header" name="title" #title="ngModel" class="input-border-radius input-base focus:border-primary" [(ngModel)]="issue.title" type="text" placeholder=" " [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required>
              <label class="input-label-base" for="header">Overskrift</label>
              <p [hidden]="title.valid || title.pristine" class="text-red-500 text-xs">
                <fa-icon [icon]="['fal','info-circle']" class="inline-block"></fa-icon>
                <span class="font-semibold inline-block pl-1"> Du skal angive en overskrift</span>
              </p>
              <button-component [type]="'primary'" [style]="'solid'" (click)="nestedOpenValue = !nestedOpenValue" class="inline-block w-1/5"><fa-icon class="text-2xl mr-2 leading-none" [icon]="['fal', 'search']"> </fa-icon><span class="font-medium">Åben fold out</span></button-component>
              <fold-out [isOpen]="nestedOpenValue" [enableValidation]="'true'">
                <div>
                  <input id="header2" name="headline" #headline="ngModel" class="input-border-radius input-base focus:border-primary" [(ngModel)]="issue.headline" type="text" placeholder=" " [ngClass]="{ 'is-invalid': f.submitted && headline.invalid }" required>
                  <label class="input-label-base" for="header2">Overskrift 2</label>
                  <p [hidden]="headline.valid || headline.pristine" class="text-red-500 text-xs">
                    <fa-icon [icon]="['fal','info-circle']" class="inline-block"></fa-icon>
                    <span class="font-semibold inline-block pl-1"> Du skal angive en overskrift 2</span>
                  </p>
                </div>
              </fold-out>
            </form>
          </div>
        </div>

      </accordion>
      <button-component [type]="'accept'" [style]="'outline'" [hasError]="f.invalid" (click)="saveForm(f)" class="mt-5 inline-block">
        <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'save']">
        </fa-icon><span class="font-medium">Gem</span>
      </button-component>



    </div>
  </div>
  <p class="font-medium mb-2 mt-10">Snippet</p>
  <p class="text-gray-700">comAccordion</p>
</section>
