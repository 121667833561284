import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from "src/app/Login/authentication.service"


@Component({
  selector: 'components',
  templateUrl: 'components.component.html',
    styleUrls: ['components.component.css'],

})
export class ComponentsComponent implements OnInit {
    constructor(private authService: AuthenticationService) {  }
  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => {
      if (x) {
        this.loggedIn = true;
      }
    });
  }

  bonusText: string = "Eu sollicitudin vivamus cursus massa vivamus arcu suspendisse consectetur phasellus tortor cursus sit maximus condimentum vel portaest vivamus sed ut nisi diam dolor a quis. Eu sollicitudin vivamus cursus massa vivamus arcu suspendisse consectetur phasellus tortor cursus sit maximus condimentum vel portaest vivamus sed ut nisi diam dolor a quis. Eu sollicitudin vivamus cursus massa vivamus arcu suspendisse consectetur phasellus tortor cursus sit maximus condimentum vel portaest vivamus sed ut nisi diam dolor a quis. Eu sollicitudin vivamus cursus massa vivamus arcu suspendisse consectetur phasellus tortor cursus sit maximus condimentum vel portaest vivamus sed ut nisi diam dolor a quis."
  loggedIn: true;
}
