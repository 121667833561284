import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Loader } from "./loader";

@Injectable({
    providedIn: "root"
})
export class LoaderService {

    private _loaders: Loader[] = [];
    /**
     * Loader registry as stream.
     */
    public loaders$ = new BehaviorSubject<Loader[]>(this._loaders);

    /**
     * Adds a loader to the loader registry.
     * @param loaderAlias Alias of the loader. This should be unique. If an alias that already exists is entered, the existing loader will be returned.
     * @param loaderArea Area the loader should be active in. 
     * @param timeToLive The loader duration in milliseconds.
     * @param delay The delay before the loader should be shown.
     */
    addLoader(loaderAlias: string, loaderArea: string,timeToLive: number = 10000, delay: number = 350 ): Loader {
        let loader: Loader = null;

        const registeredLoaders = this._loaders.filter(l => l.alias.toUpperCase() === loaderAlias.toUpperCase());
        if (registeredLoaders.length > 0) {
            loader = registeredLoaders[0];
        } else {
            loader = new Loader(loaderAlias, loaderArea, timeToLive, delay);
            this._loaders.push(loader);
            setTimeout(() => {
                this.loaders$.next(this._loaders);
            }, loader.initialDelay);

            if (loader.maxTimeToLive) {
                setTimeout(() => {
                    if (loader)
                        this.removeLoader(loader)
                }, loader.initialDelay + loader.maxTimeToLive);
            }
        }

        return loader;
    }

    /**
     * Removes a loader from the loader registry.
     * @param loader Loader or loader alias. 
     */
    removeLoader(loader: Loader | string): void {
        let actualLoader: Loader = null;
        if (typeof loader === 'string') {
            const registeredLoaders = this._loaders.filter(l => l.alias.toUpperCase() === (loader as string).toUpperCase());
            if (registeredLoaders.length > 0) {
                actualLoader = registeredLoaders[0];
            }
        } else {
            actualLoader = (loader as Loader);
        }

        const index = this._loaders.indexOf(actualLoader);
        if (index > -1) {
            this._loaders.splice(index, 1);
            this.loaders$.next(this._loaders);
        }
    }
}

