export class Loader {
    constructor(loaderAlias: string, loaderArea: string, timeToLive: number, delay: number) {
        this.alias = loaderAlias;
        this.area = loaderArea;
        this.maxTimeToLive = timeToLive;
        this.initialDelay = delay;

        this.createTime = new Date();
    }

    alias: string;
    area: string;

    maxTimeToLive: number;
    initialDelay: number;
    createTime: Date;

    isValid(): boolean {
      return !this.hasExpired() && !this.isDelayed();
    }

    hasExpired(): boolean {
        const expTime = this.createTime.getTime() + this.initialDelay + this.maxTimeToLive;
        const currentTime = new Date().getTime();
        //If current time is greater than expiration time, the loader has expired and should be removed.
        return expTime < currentTime;
    }

    isDelayed(): boolean {
        const activationTime = this.createTime.getTime() + this.initialDelay;
        const currentTime = new Date().getTime();
        return currentTime < activationTime;
    }
}
