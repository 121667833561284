<div class="w-full py-12 inline-block">
  <h1>
    Locker (The locksmith)
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>

  <div class="flex flex-col space-y-10">

    <div class="flex flex-col">
      <p></p>
      <locker *ngIf="lockerModel" [model]="lockerModel"
        [description]="'Tryk her for at ændre den valgte handlingstype'">
      </locker>
      <div>
        <p>isLocked: {{lockerModel.isLocked}}</p>
        <p>allowUserlockAction: {{lockerModel.allowUserlockAction}}</p>
      </div>
    </div>

    <div class="flex space-x-2">
      <button [disabled]="!lockerModel.isLocked" class="btn bt-accept btn-outline" (click)="unlock($event)">Lås
        op!</button>
      <button [disabled]="lockerModel.isLocked" class="btn bt-accept btn-outline" (click)="lock($event)">Lås!</button>
    </div>

    <div class="flex space-x-2">
      <p>Prevent propagation</p>
      <button [disabled]="!lockerModel.isLocked" class="btn bt-accept btn-outline" (click)="unlock($event, true)">Lås
        op!</button>
      <button [disabled]="lockerModel.isLocked" class="btn bt-accept btn-outline" (click)="lock($event, true)">Lås!</button>
    </div>

    <div class="flex flex-col">
      
      <p>Hvis du ændre i teksten herunder vil låsen blive slået til, hvis den er låst op.</p>
      <p>Det er nødvenigt at trykke ud af feltet for at "trigger" ændringen</p>
      <div class="w-full input-wrapper input-border-radius bg-white inline-block">
        <input id="text" type="text" class="input-border-radius focus:border-primary input-base" type="text"
          placeholder=" " name="text" (change)="onTextChange($event)">
        <label class="input-label-base" for="text">Indsæt tekst</label>
      </div>
    </div>
   
  </div>
</div>