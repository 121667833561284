
/**
 * Generic models for directives
 * this allows us to resolve the viewcontext with a type 
*/
//reference: https://benjamin-maisonneuve1.medium.com/multiple-content-projections-in-angular-cc65f72ba519 
export interface ViewContext<T> {
  $implicit: T;
}

/**
 * Grouplist - Standard minimal models
*/

export interface IGroupedListComponentModel {
  categories: IGroupedListCategoryModel[];
}

export interface IGroupedListKeyModel {
  key: string;
  name: string;
  description: string;
  parentKey: string;
  parent: IGroupedListCategoryModel;
  //original ref
  ref: any;
  isFictive: boolean;
}

export interface IGroupedListCategoryModel extends IGroupedListKeyModel {
  showChild: boolean;
  children: IGroupedListCategoryItemModel[];
}

export interface IGroupedListCategoryItemModel extends IGroupedListKeyModel {
  isSubCategory: boolean;
  subCategories: IGroupedListCategoryModel[];
}
