import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { foldOutParent } from '../../../../../projects/bexcomponent-design/src/public-api';

@Component({
  selector: 'list-fold-out-item',
    templateUrl: './list-fold-out-item.component.html',
    animations: [
        foldOutParent
    ]
})
export class ListFoldOutItemComponent implements OnInit {
    @Input() item: any;
    foldThis: boolean = false;
    showMore: boolean = true
    constructor(public breakpointObserver: BreakpointObserver) { }

    ngOnInit() {
        this.breakpointObserver
            .observe(['(min-width: 1281px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.showMore = true;
                } else {
                    this.showMore = false;
                }
            });
  }

}
