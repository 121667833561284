<div class="growler fixed top-0 left-0 w-full h-full pointer-events-none inline-block z-40">
  <div class="top-0 left-0 w-full h-full flex absolute justify-end items-start flex-col-reverse px-4 pt-3">
    <ng-container *ngFor="let growl of topLeftGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)" [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-start items-center flex-col pt-3 px-4">
    <ng-container *ngFor="let growl of topGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)" [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class="top-0 left-0 w-full h-full flex absolute justify-start items-end flex-col px-4  pt-3">
    <ng-container *ngFor="let growl of topRightGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)" [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-center items-start flex-col-reverse px-4">
    <ng-container *ngFor="let growl of middleLeftGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)" [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-center items-center flex-col px-4">
    <ng-container *ngFor="let growl of middleGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)"  [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-center items-end flex-col-reverse px-4">
    <ng-container *ngFor="let growl of middleRightGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)"  [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-start items-start flex-col-reverse px-4">
    <ng-container *ngFor="let growl of bottomLeftGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)"  [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-start items-center flex-col-reverse px-4">
    <ng-container *ngFor="let growl of bottomGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)"  [growl]="growl"></growler-item>
    </ng-container>
  </div>
  <div class=" top-0 left-0 w-full h-full flex absolute justify-start items-end flex-col-reverse px-4">
    <ng-container *ngFor="let growl of bottomRightGrowls">
      <growler-item *ngIf="growl" @foldOutParent (deleteGrowl)="removeGrowl($event.id, $event.position)"  [growl]="growl"></growler-item>
    </ng-container>
  </div>

</div>
