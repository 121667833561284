import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'labeled-toggle',
  templateUrl: './labeled-toggle.component.html',
  styleUrls: ['./labeled-toggle.component.css']
})
export class LabeledToggleComponent implements OnInit {
    @Input() width: string = "w-40"
  constructor() { }

  ngOnInit() {
  }

}
