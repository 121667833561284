import { Subject } from "rxjs";

export interface ModalBase {

  closed$: Subject<boolean>;
  onClose: Function;
  onSuccess: Function;
    data: any;
    scrollStrategy: undefined | 'noop' | 'block' | 'reposition';
}
