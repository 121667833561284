import { Component, OnInit } from '@angular/core';
import { GrowlerService, GrowlerMessageType, GrowlerPosition, IGrowl } from './growler.service';
import { trigger, transition, query, animateChild } from '@angular/animations';

@Component({
    selector: 'app-growler',
    templateUrl: './growler.component.html',
    animations: [
        trigger('foldOutParent', [
            transition(':enter, :leave', [
                query('@*', animateChild())
            ])
        ]),
    ]
})
export class GrowlerComponent implements OnInit {

    private growlCount = 0;
    topLeftGrowls: Growl[] = [];
    topGrowls: Growl[] = [];
    topRightGrowls: Growl[] = [];
    middleLeftGrowls: Growl[] = [];
    middleGrowls: Growl[] = [];
    middleRightGrowls: Growl[] = [];
    bottomLeftGrowls: Growl[] = [];
    bottomGrowls: Growl[] = [];
    bottomRightGrowls: Growl[] = [];

    constructor(private growlerService: GrowlerService) {
        growlerService.growl = this.growl.bind(this);
    }

    ngOnInit() { }

    /**
    * Displays a growl message.
    *
    * @param  message - The message to display.
    * @param growlType - The type of message to display (a GrowlMessageType enumeration)
    * @return id - Returns the ID for the generated growl
    */
    growl(message: string, growlType: GrowlerMessageType = GrowlerMessageType.Info, position: GrowlerPosition = GrowlerPosition.Top, timeout: number = 4000): number {
        this.growlCount++;
        const bootstrapAlertType = GrowlerMessageType[growlType].toLowerCase();
        const messageType = `alert-${bootstrapAlertType}`;
        const growl = new Growl(this.growlCount, message, messageType, position, timeout, this);
        if (growlType == GrowlerMessageType.Danger) {
            growl.position = GrowlerPosition.Middle
        }
    
          switch (growl.position) {
              case GrowlerPosition.TopLeft:
                  this.topLeftGrowls.push(growl);
                  break;
              case GrowlerPosition.Top:
                  this.topGrowls.unshift(growl);
                  break;
              case GrowlerPosition.TopRight:
                  this.topRightGrowls.unshift(growl);
                  break;
              case GrowlerPosition.MiddleLeft:
                  this.middleLeftGrowls.unshift(growl);
                  break;
              case GrowlerPosition.Middle:
                  this.middleGrowls.unshift(growl);
                  break;
              case GrowlerPosition.MiddleRight:
                  this.middleRightGrowls.unshift(growl);
                  break;
              case GrowlerPosition.BottomLeft:
                  this.bottomLeftGrowls.unshift(growl);
                  break;
              case GrowlerPosition.Bottom:
                  this.bottomGrowls.unshift(growl);
                  break;
              case GrowlerPosition.BottomRight:
                  this.bottomRightGrowls.unshift(growl);
                  break;
              default:         
        }
        return growl.id;
    }

    removeGrowl(id: number, position: GrowlerPosition) {
        switch (position) {
            case GrowlerPosition.TopLeft:
                this.findItemInArrayAndRemove(this.topLeftGrowls, id);
                break;
            case GrowlerPosition.Top:
                this.findItemInArrayAndRemove(this.topGrowls, id);
                break;
            case GrowlerPosition.TopRight:
                this.findItemInArrayAndRemove(this.topRightGrowls, id);
                break;
            case GrowlerPosition.MiddleLeft:
                this.findItemInArrayAndRemove(this.middleLeftGrowls, id);
                break;
            case GrowlerPosition.Middle:
                this.findItemInArrayAndRemove(this.middleGrowls, id);
                break;
            case GrowlerPosition.MiddleRight:
                this.findItemInArrayAndRemove(this.middleRightGrowls, id);
                break;
            case GrowlerPosition.BottomLeft:
                this.findItemInArrayAndRemove(this.bottomLeftGrowls, id);
                break;
            case GrowlerPosition.Bottom:
                this.findItemInArrayAndRemove(this.bottomGrowls, id);
                break;
            case GrowlerPosition.BottomRight:
                this.findItemInArrayAndRemove(this.bottomRightGrowls, id);
                break;
            default:
        }
    }
    findItemInArrayAndRemove(array: Growl[], growlId: number) {
        array.forEach((growl: Growl, index: number) => {
            if (growl.id === growlId) {
                array.splice(index, 1);
                this.growlCount--;
            }
        });
    }
}

export class Growl implements IGrowl {

    enabled: boolean;
    timeoutId: number;
    constructor(public id: number,
        public message: string,
        public messageType: string,
        public position: GrowlerPosition,
        private timeout: number,
        private growlerContainer: GrowlerComponent) {
        this.show();
    }

    show(): void {
        window.setTimeout(() => {
            this.enabled = true;
            this.setTimeout();
        }, 0);
    }

    setTimeout(): void {
        window.setTimeout(() => {
            this.hide();
        }, this.timeout);
    }

    hide(): void {
        this.enabled = false;
        this.growlerContainer.removeGrowl(this.id, this.position);
    }
}
