import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowlerComponent } from './growler.component';
import { GrowlerService } from './growler.service';
import { GrowlerItemComponent } from './growler-item/growler-item.component';
import { FaIconComponent } from '../fa-icon/fa-icon.component';
const providers = [GrowlerService];

@NgModule({
    imports: [CommonModule, FaIconComponent],
    exports: [GrowlerComponent],
    providers: providers,
    declarations: [GrowlerComponent, GrowlerItemComponent]
})
export class GrowlerModule { }

@NgModule({})
export class GrowlSharedModule {
    static forRoot(): ModuleWithProviders<GrowlerModule> {
        return {
            ngModule: GrowlerModule,
            providers: providers,


        }
    }
}
