import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent {
    @Input() color: string = "text-primary";
    @Input() style: string = "";
    @Input() preColor: string = "bg-gray-300";
    @Input() lineBg: string = "bg-gray-800";
    @Input() size: string = ""

  constructor() { }


}
