import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'labeled-toggle-presenter',
    templateUrl: './labeled-toggle-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    },
})
export class LabeledTogglePresenterComponent implements OnInit {
    hasCopied: boolean = false;
    toggle: boolean = true;
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/557154309/Toggle+med+titel+-+Labeled-toggle";
  constructor() { }

  ngOnInit() {
  }
    generateStringContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = `
      <labeled-toggle>
        <ng-container>
          <input type="radio" class="switch-input hidden" name="view" [(ngModel)]="toggle" value="true" id="email" checked>
          <label for="email" class="switch-label relative z-10 float-left w-1/2 text-center cursor-pointer switch-label-off" [ngClass]="toggle == true ? 'text-white' : 'text-gray-700'">
            <fa-icon class="pr-1" [icon]="['fal','file-pdf']"></fa-icon>          
            E-mail    
          </label>

          <input type="radio" class="switch-input hidden" name="view" [(ngModel)]="toggle" value="false" id="letter">
          <label for="letter" class="switch-label relative z-10 float-left w-1/2 text-center cursor-pointer switch-label-on" [ngClass]="toggle == false ? 'text-white' : 'text-gray-700'">
            <fa-icon class="pr-1" [icon]="['fal','envelope']"></fa-icon>   
            Brev          
          </label>
        </ng-container>
      </labeled-toggle>
        `;
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopied = true;
        setTimeout(() => { this.hasCopied = false }, 3000);
    }

}
