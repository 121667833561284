import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { catchError, map, of, switchMap, take } from 'rxjs';

@Component({
    selector: 'fa-icon',
    templateUrl: './fa-icon.component.html',
    styleUrl: './fa-icon.component.css',
    standalone: true
})
export class FaIconComponent implements OnInit {
    @Input() icon: [IconStyle, string];
    @Input() size: IconSize = "md";

    cdnUrl = "https://icon-cdn.incomit.dk/"
    iconUrl: string;

    @ViewChild('iconContainer', { static: true }) iconContainer: ElementRef;

    constructor() { }

    ngOnInit(): void {
        const iconUrl = this.getIconUrl();
        this.loadExternalIcon(iconUrl);
    }

    /**
     * Loads an external icon from the given url.
     * The current icon container will be replaced with the new icon.
     * @param url The url to some external SVG to load in.
     */
    loadExternalIcon(url: string): void {
        if ("incomSvgHandler" in window === false) {
            console.error("The SvgHandler is not available in the window object. Make sure you have included the SVG handler script");
            console.log("The SvgHandler can be found in the Phoenix project in the svg-loader.js")
            return;
        }
        //@ts-ignore
        of(window.incomSvgHandler.getSvg(url)).pipe(
            switchMap(response => response),
            catchError(err => {
                console.error(`Failed to load external icon from ${url}`, err);
                return of('');
            }),
            take(1)
        ).subscribe(svgContent => {
            if (svgContent) {
                this.iconContainer.nativeElement.innerHTML = svgContent;
            }
        });
    }

    /**
     * Gets the current icon url based on the given icon style and name.
     * @returns The url to the icon.
     */
    getIconUrl(): string {
        switch (this.icon[0]) {
            case 'brands':
            case 'fab':
                return `${this.cdnUrl}brands/${this.icon[1]}.svg`;
            case 'duotone':
                return `${this.cdnUrl}duotone/${this.icon[1]}.svg`;
            case 'fal':
            case 'light':
                return `${this.cdnUrl}light/${this.icon[1]}.svg`;
            case 'far':
            case 'regular':
                return `${this.cdnUrl}regular/${this.icon[1]}.svg`;
            case 'fas':
            case 'solid':
                return `${this.cdnUrl}solid/${this.icon[1]}.svg`;
            case 'sharp-duotone-solid':
                return `${this.cdnUrl}sharp-duotone-solid/${this.icon[1]}.svg`;
            case 'sharp-light':
                return `${this.cdnUrl}sharp-light/${this.icon[1]}.svg`;
            case 'sharp-regular':
                return `${this.cdnUrl}sharp-regular/${this.icon[1]}.svg`;
            case 'sharp-solid':
                return `${this.cdnUrl}sharp-solid/${this.icon[1]}.svg`;
            case 'sharp-thin':
                return `${this.cdnUrl}sharp-thin/${this.icon[1]}.svg`;
            case 'thin':
                return `${this.cdnUrl}thin/${this.icon[1]}.svg`;
            default:
                console.error(`Couldn't create icon for style: ${this.icon[0]}. This style is currently not supported`);
                return '';
        }
    }
}

export type IconSize = "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
export type IconStyle = 'fal' | 'far' | 'fas' | 'fab' | 'brands' | 'duotone' | 'light' | 'regular' | 'sharp-duotone-solid' | 'sharp-light' | 'sharp-regular' | 'sharp-solid' | 'sharp-thin' | 'solid' | 'thin';
