<div class="relative inline-block md:flex md:items-start {{color}} z-10" [ngClass]="hDirection === 'left' ? 'flex-row-reverse' : 'flex-row'">
  <ng-content select="[mainButton]" [ngClass]="settingsOnMobile ? 'hidden md:block' : '' ">
  </ng-content>
  <button-component [type]="'accept'" [style]="settingsButtonType" class="md:ml-2" [ngClass]="settingsOnMobile ? 'block md:hidden' : 'hidden' " (click)="showButtons()">
    <fa-icon class="text-base mr-2 leading-none" [icon]="['fal', 'floppy-disk']"> </fa-icon>
    <span class="font-medium truncate pr-6 text-sm">{{settingsButtonName}}</span>
  </button-component>
  <fa-icon [icon]="['fal', icon]" class="absolute md:hidden inset-y-0 right-0 w-8 rounded-r-full cursor-pointer flex items-center justify-center border-l" (click)="showButtons()"></fa-icon>
  <fold-out class="absolute md:relative w-full select-none flex flex-col md:flex-row   md:left-0 multibuttons shadow-lg md:shadow-none mb-1 md:mb-0 bg-white md:bg-transparent" [ngClass]="foldUp ? 'bottom-full md:bottom-0' : 'top-full md:top-0'" [isOpen]="isOpen">
    <ng-content select="[buttons]">

    </ng-content>
  </fold-out>
</div>
