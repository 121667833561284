import { Component, Input } from '@angular/core';


@Component({
    selector: 'input-field-presenter',
    templateUrl: 'input-field-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    },
})
export class InputFieldPresenter {
    stuff = "";
    hasCopiedSelect: boolean = false;
    hasCopiedSelectWithLabel: boolean = false;
    hasCopiedDisabledSelect: boolean = false;
    hasCopiedTextarea: boolean = false;
    hasCopiedDisabledTextarea: boolean = false;
    hasCopiedHr: boolean = false;
    hasCopiedInput: boolean = false;
    hasCopiedDisabledInput: boolean = false;
    hasCopiedInputWithIcon: boolean = false;
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/373751809/Input+textarea+select"
    hasCopiedHeaderInput: boolean;
    hasCopiedBiggerHeaderInput: boolean;
    hasCopiedGrayInput: boolean;
    hasCopiedHeaderSelect: boolean;

    generateSelectContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="select-wrapper input-border-radius bg-white inline-block" [ngClass]="{'active': stuff.length > 0}">
            <select class="input-border-radius input-base" id="company" [(ngModel)]="stuff">
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedSelect = true;
        setTimeout(() => { this.hasCopiedSelect = false }, 3000);
    }
    generateDisabledSelectContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="select-wrapper input-border-radius bg-white inline-block" [ngClass]="{'active': stuff.length > 0}">
            <select class=" input-border-radius input-base" id="company" [(ngModel)]="stuff" disabled>
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedDisabledSelect = true;
        setTimeout(() => { this.hasCopiedDisabledSelect = false }, 3000);
    }

    generateSelectWithLabelContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="select-wrapper input-border-radius bg-white inline-block">
            <select class=" input-border-radius input-base pr-8 pl-16 px-2" id="company" [(ngModel)]="stuff">
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base default-label">Type:</label>          
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon> 
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedSelectWithLabel = true;
        setTimeout(() => { this.hasCopiedSelectWithLabel = false }, 3000);
    }
    generateSelectHeaderContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="header-input-wrapper input-border-radius bg-white inline-block sm:w-10/12 w-11/12" [ngClass]="{'active': stuff.length > 0}">
            <select class="input-border-radius header-input input-base" id="company" required [(ngModel)]="stuff">
              <option class="px-2" value=""></option>
              <option class="px-2 ">Krisen i syrien</option>
              <option class="px-2 ">Overophedning af kloden</option>
              <option class="px-2 ">Utilfredshed med madheksen</option>
            </select>
            <label for="company" class="input-label-base w-11/12">Hvad drejer din henvendelse sig om?</label>
            <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedHeaderSelect = true;
        setTimeout(() => { this.hasCopiedHeaderSelect = false }, 3000);
    }
    generateInputContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block">
            <input id="header" class="input-border-radius focus:border-primary input-base" type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedInput = true;
        setTimeout(() => { this.hasCopiedInput = false }, 3000);
    }

    generateGrayInputContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 input-wrapper gray-input-wrapper input-border-radius inline-block">
            <input id="header" class="input-border-radius gray-input focus:border-primary input-base" required type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedGrayInput = true;
        setTimeout(() => { this.hasCopiedGrayInput = false }, 3000);
    }


    generateDisabledInputContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block">
            <input id="header" class="input-border-radius input-base focus:border-primary" type="text" placeholder=" " disabled>
            <label class="input-label-base" for="header">Overskrift</label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedDisabledInput = true;
        setTimeout(() => { this.hasCopiedDisabledInput = false }, 3000);
    }

    generateInputWithIconContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 input-wrapper input-border-radius bg-white inline-block">
            <input id="header" class="input-border-radius input-base pr-8 focus:border-primary" type="text" placeholder=" ">
            <label class="input-label-base" for="header">Overskrift</label>
            <fa-icon class="input-icon" [icon]="['fal', 'search']"></fa-icon>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedInputWithIcon = true;
        setTimeout(() => { this.hasCopiedInputWithIcon = false }, 3000);
    }

    generateHeaderInputContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="header-input-wrapper inline-block w-full input-border-radius w-10/12">
            <input id="header" class="input-base header-input " type="text" placeholder=" ">
            <label class="input-label-base px-0" for="header"> Søg efter sektioner </label>
            <fa-icon [icon]="['fal','search']" class="input-icon "></fa-icon>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedHeaderInput = true;
        setTimeout(() => { this.hasCopiedHeaderInput = false }, 3000);
    }
    generateBiggerHeaderInputContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="header-input-wrapper header-bigger inline-block w-full input-border-radius w-10/12">
            <input id="header" class="input-base header-input border-b focus:border-primary" type="text" placeholder=" ">
            <label class="input-label-base px-0 " for="header"> Navngiv dit registreringsskema </label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedBiggerHeaderInput = true;
        setTimeout(() => { this.hasCopiedBiggerHeaderInput = false }, 3000);
    }

    generateTextareaContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 textarea-wrapper bg-white inline-block">
            <textarea id="description" class=" textarea-base focus:border-primary textarea-border-radius" type="text" placeholder=" "></textarea>
            <label class="input-label-base " for="description">Beskriv fejlen her</label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedTextarea = true;
        setTimeout(() => { this.hasCopiedTextarea = false }, 3000);
    }

    generateDisabledTextareaContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <div class="mb-5 textarea-wrapper bg-white inline-block">
            <textarea id="description" disabled class="textarea-base focus:border-primary textarea-border-radius" type="text" placeholder=" "></textarea>
            <label class="input-label-base" for="description">Beskriv fejlen her</label>
          </div>
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedDisabledTextarea = true;
        setTimeout(() => { this.hasCopiedDisabledTextarea = false }, 3000);
    }

    generateHr() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value =
            `
          <hr class="hr-base" />
            `
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedHr = true;
        setTimeout(() => { this.hasCopiedHr = false }, 3000);
    }
}
