import { Component, OnInit, Input, OnDestroy, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { smoothHeight } from '../directives/animations';
import { FormValidationService } from "incom-utility-library";
import { Subscription } from "rxjs";

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  animations: [smoothHeight]
})
export class AccordionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isOpen: boolean;
  @Input() openOnHeaderClick: boolean = true;
  @Input() enableValidation: boolean = false;
  icon: string = "angle-up";
  constructor(private validationService: FormValidationService, private element: ElementRef) { }
  openAccordion() {
    this.isOpen = !this.isOpen;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen) {
    }
  }
  ngOnInit() {
    if (this.enableValidation) {
      this.validationSubscription = this.validationService.invalidElements$.subscribe(x => {
        const hasInvalidElement = x.some(y => this.validationService.isParentsForInvalidElement(y, this.element.nativeElement));
        if (hasInvalidElement) {
          this.isOpen = hasInvalidElement;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this.enableValidation) {
      this.validationSubscription.unsubscribe();
    }
  }
  validationSubscription: Subscription;
}
