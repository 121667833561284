import { Directive, TemplateRef } from "@angular/core";
import { IGroupedListCategoryModel, ViewContext } from "../group-list.models";

// generic type for the view context
export interface GroupedListCategoryModelViewContext extends ViewContext<IGroupedListCategoryModel> {

}


@Directive({ selector: '[GroupedListCategory]' })
export class GroupedListCategoryDirective {

    /**
     * The template ref is optional but it tells the IDE that this is a structural directive
     * and it tells the implicit object type
     */
    constructor(private templateRef: TemplateRef<ViewContext<IGroupedListCategoryModel>>) {
    }
}
