<form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="pt-32 w-full sm:w-1/3 pl-32">
  <h2 class="font-bold mb-10 text-2xl leading-none">
    Incom component library
  </h2>
  <div class="mb-5 input-wrapper inline-block rounded-full bg-white w-full">
    <input id="username" name="username"
           class="w-full relative z-10 bg-transparent block border border-black py-2 px-2 h-10 rounded-full sm:text-sm text-base text-primary font-medium outline-none"
           type="text" placeholder=" " [(ngModel)]="model.username" required>
    <label class="font-medium text-black text-xs px-2 sm:text-sm block" for="username"> Navn </label>
  </div>

  <div class="mb-5 input-wrapper inline-block rounded-full bg-white w-full">
    <input *ngIf="!passwordRevealed" id="password" name="password"
           class="w-full relative z-10 bg-transparent block border border-black text-primary py-2 px-2 h-10 rounded-full sm:text-sm text-base font-medium outline-none"
           type="password" placeholder=" " [(ngModel)]="model.password" required>
    <input *ngIf="passwordRevealed" id="password" name="password"
           class="w-full relative z-10 bg-transparent block border border-black text-primary py-2 px-2 h-10 rounded-full sm:text-sm text-base font-medium outline-none"
           type="text" placeholder=" " [(ngModel)]="model.password" required>
    <label class="font-medium text-black text-xs px-2 sm:text-sm block" for="password"> Adgangskode </label>
    <a class="absolute inset-y-0 right-0 flex items-center pr-3 text-xs sm:text-sm text-gray-800 z-20 cursor-pointer" (click)="togglePasswordReveal()"> {{passwordRevealed ? 'Skjul': 'Vis'}} </a>
  </div>

  <div class="w-full text-left">
    <button class="btn btn-primary btn-solid inline-block" type="submit">
      <span class="font-medium">Log ind</span>
      <fa-icon class="text-base ml-2 leading-none" [icon]="['fal', 'long-arrow-alt-right']"> </fa-icon>
    </button>
  </div>
</form>
