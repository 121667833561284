import { Component, OnInit } from '@angular/core';
import { trigger, transition, query, stagger, animateChild, style, animate } from '@angular/animations';

@Component({
    selector: 'button-presenter',
    templateUrl: './button-presenter.component.html',
    styleUrls: ['./button-presenter.component.css'],
    host: {
        class: 'w-full inline-block',
    },
    animations: [
        trigger('list', [
            transition(':enter', [
                query('@items', stagger(150, animateChild()))
            ]),
        ]),
        trigger('items', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)', opacity: 0 }),  // initial
                animate('0.3s  cubic-bezier(0.19, 1, 0.22, 1)',
                    style({ transform: 'translateY(0)', opacity: 1 }))  // final
            ])
        ])
    ]
})
export class ButtonPresenterComponent implements OnInit {

    selectedType: string = "accept";
    selectedStyle: string = "solid";
    selectedIcon: string = "ballot-check";
    selectedIconStyle: string = "fal";
    docLink: string = "https://incomit.atlassian.net/wiki/spaces/IP/pages/343539713/Knapper"
    iconSelectorOpen: boolean = false;
    selectedText: string = "Knap text"
    iconMarkup: string = '<fa-icon class="text-base mr-2 leading-none" [icon]="[\'' + this.selectedIconStyle + '\', \'' + this.selectedIcon + '\']" > </fa-icon>'
    hasCopied: boolean = false;
    hasIcon: boolean = true;
    hasCopiedChosen: boolean;

/*Specified buttons*/
    createBtn: string =`
        <button class="btn btn-accept btn-outline btn-border-radius">
          <span class="font-medium mr-2">Opret</span>
          <fa-icon class="text-base leading-none" [icon]="['fal', 'plus']"></fa-icon>
        </button>
    `
    cancelBtn: string = `
          <button class="btn btn-deny btn-outline btn-border-radius">
            <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'times']"></fa-icon>
            <span class="font-medium">Annuller</span>
          </button>
    `
    goToBtn: string = `
            <button class="btn btn-accept btn-outline btn-border-radius">
              <span class="font-medium mr-2">Næste</span>
              <fa-icon class="text-base leading-none" [icon]="['fal', 'long-arrow-right']"></fa-icon>
            </button>
    `
    goBackBtn: string = `
            <button class="btn btn-deny btn-outline btn-border-radius">
              <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'long-arrow-left']"></fa-icon>
              <span class="font-medium">Tilbage</span>
            </button>
    `
    saveBtn: string = `
               <button class="btn btn-accept btn-outline btn-border-radius">
              <fa-icon class="text-base leading-none mr-2" [icon]="['fal', 'save']"></fa-icon>
              <span class="font-medium">Gem plan</span>
            </button>
    `


    types = [
        { Name: "accept" },
        { Name: "deny" },
        { Name: "info" },
        { Name: "primary" },
        { Name: "error" }
    ]

    hasIconOptions = [
        { Name: "Ja", Value: true },
        { Name: "Nej", Value: false },
    ]
    styles = [
        { Name: "outline" },
        { Name: "solid" },
    ]

    icons = [
        { Name: "ballot-check" },
        { Name: "ban" },
        { Name: "check" },
        { Name: "minus-circle" },
        { Name: "search" },
        { Name: "plus" },
        { Name: "calendar-exclamation" },
        { Name: "calendar-edit" },
        { Name: "save" },
        { Name: "long-arrow-right" },
        { Name: "long-arrow-left" },
        { Name: "user" },
        { Name: "user-edit" },
        { Name: "user-circle" },
        { Name: "print" },
        { Name: "times" },
        { Name: "eye" },
        { Name: "images" },
        { Name: "tools" },
        { Name: "clipboard-check" },
        { Name: "clipboard-list-check" },
        { Name: "mobile" },
        { Name: "envelope" },
        { Name: "file-pdf" },
        { Name: "user-hard-hat" },
        { Name: "hotel" },
        { Name: "hammer" },
        { Name: "key" },
        { Name: "angle-up" },
        { Name: "angle-down" },
        { Name: "check" },
        { Name: "arrows-alt" },
        { Name: "cog" },
        { Name: "trash" },
        { Name: "search" },
        { Name: "info-circle" },
        { Name: "calendar" },
        { Name: "bullhorn" },
        { Name: "exclamation-triangle" },
        { Name: "hourglass-half" },
        { Name: "comment-alt-lines" },
        { Name: "history" },
        { Name: "camera" },
        { Name: "home" },
        { Name: "question-circle" },
        { Name: "long-arrow-down" },
        { Name: "ellipsis-v" },
        { Name: "edit" },
        { Name: "bars" },
        { Name: "star" },
        { Name: "font-case" },
        { Name: "expand-arrows-alt" },
        { Name: "users-cog" },
        { Name: "toolbox" },
        { Name: "life-ring" },
        { Name: "map-marker-alt" },
        { Name: "file-alt" },
        { Name: "clipboard-list" },
        { Name: "file-invoice" },
        { Name: "address-book" },
        { Name: "archive" },

    ]


    iconStyles = [
        { Name: "far" },
        { Name: "fal" },
        { Name: "fas" },
    ]

    constructor() { }

    ngOnInit() {
    }

    generateIconMarkup() {
        this.iconMarkup = '<fa-icon class="text-base mr-2 leading-none" [icon]="[\'' + this.selectedIconStyle + '\', \'' + this.selectedIcon + '\']" > </fa-icon>'
    }

    generateStringContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = '<button-component [type]="\'' + this.selectedType + '\'" [style]="\'' + this.selectedStyle + '\'">' + (this.hasIcon ? this.iconMarkup : '') + '<span class="font-medium" >' + this.selectedText + '</span></button-component> ';
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopied = true;
        setTimeout(() => { this.hasCopied = false }, 3000);
    }

    generateFormButtonStringContent() {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = '<button class="btn btn-' + this.selectedType + ' btn-' + this.selectedStyle + '">' + (this.hasIcon ? this.iconMarkup : '') + '<span class="font-medium" >' + this.selectedText + '</span></button>';
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopied = true;
        setTimeout(() => { this.hasCopied = false }, 3000);
    }


    generateChosenStringContent(button:string ) {
        let textbox = document.createElement('textarea')
        textbox.style.position = "fixed";
        textbox.value = button;
        document.body.appendChild(textbox);
        textbox.focus();
        textbox.select();
        document.execCommand('copy');
        document.body.removeChild(textbox);
        this.hasCopiedChosen = true;
        setTimeout(() => { this.hasCopied = false }, 3000);
    }

}
