<div class="w-full py-12 inline-block">
  <h1>
    Custom input
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <p class="font-medium mb-2">Indstillinger</p>
  <section>
    <div class="flex w-full flex-wrap">

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-40" [ngClass]="{'active': selectedType.length > 0}">
        <select [(ngModel)]="selectedType" tabindex="0" class="input-border-radius input-base focus:border-primary">
          <option *ngFor="let type of types" [value]="type.Name">{{type.Name}}</option>
        </select>
        <label class="input-label-base">Vælg type</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedStyle.length > 0}">
        <select [(ngModel)]="selectedStyle" class="input-border-radius input-base">
          <option *ngFor="let style of styles" [value]="style.Name">{{style.Name}}</option>
        </select>
        <label class="input-label-base">Vælg stil</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40 active">
        <select [(ngModel)]="checked" class="input-border-radius input-base">
          <option *ngFor="let option of checkedValues" [ngValue]="option.Value">{{option.Name}}</option>
        </select>
        <label class="input-label-base">Skal den være checked</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-48 active">
        <select [(ngModel)]="isIndeterminate" class="input-border-radius input-base">
          <option *ngFor="let indevalue of indeterminateValues" [ngValue]="indevalue.Value">{{indevalue.Name}}</option>
        </select>
        <label class="input-label-base">Skal den være indeterminate?</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

    </div>
    <div class="flex w-full mt-8 flex-wrap">

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 sm:w-64 w-full {{selectedBgColor}}" [ngClass]="{'active': selectedBgColor.length > 0}">
        <select [(ngModel)]="selectedBgColor" class="input-border-radius input-base">
          <option *ngFor="let bgColor of bgColors" [value]="bgColor.Name" class="text-white" [ngClass]="bgColor.Name">{{bgColor.Name}}</option>
        </select>
        <label class="input-label-base">Vælg baggrundsfarve (unchecked)</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>
      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 sm:w-64 w-full ml-3" [ngClass]="{'active': selectedColor.length > 0}">
        <select [(ngModel)]="selectedColor" class="input-border-radius input-base {{selectedColor}}">
          <option *ngFor="let color of Colors" [value]="color.Name" class="text-white" [ngClass]="color.Name">{{color.Name}}</option>
        </select>
        <label class="input-label-base">Vælg outline farve og baggrund når checked </label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>
    </div>
    <div class="flex w-full mt-8">
      <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
        <input [(ngModel)]="selectedText" type="text" placeholder=" " class="input-border-radius focus:border-primary input-base" />
        <label class="input-label-base">Tilføj text</label>
      </div>
    </div>
  </section>
  <p class="font-medium mb-2 mt-10">Implementation</p>
  <section class=" bg-gray-400 inline-block p-5 text-sm leading-loose">
    <custom-input [style]="selectedStyle" [boxAlignment]="'items-center'" [color]="selectedColor" [bg]="selectedBgColor" [type]="selectedType" [indeterminate]="isIndeterminate" class="text-sm ">
      <input class="w-0 h-0 opacity-0" tabindex="0" [checked]="checked" type='{{selectedType}}'>
      <span class="ml-2">{{selectedText}}</span>
    </custom-input>
  </section>
  <div class="text-primary">
    <fa-icon (click)="generateStringContent()" class="text-xl" [icon]="[hasCopied ? 'fas' : 'fal', 'copy']"></fa-icon>
  </div>
  <p class="mb-5 font-medium text-xs">{{hasCopied ? 'Den er kopieret' : 'Klik på ovenstående knap for at kopier input!'}}</p>

  <p class="font-medium mb-2 mt-10">Snippet</p>
  <p class="text-gray-700">comCustomInput</p>
</div>
