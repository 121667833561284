import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';
import { FaIconComponent } from '../fa-icon/fa-icon.component';

const providers = [ModalService];

@NgModule({
    declarations: [ModalComponent],
    imports: [
        CommonModule,
        FaIconComponent
    ],
    providers: providers,
    exports: [ModalComponent]
})
export class ModalModule {
}
@NgModule({})
export class ModalSharedModule {
    static forRoot(): ModuleWithProviders<ModalModule> {
        return {
            ngModule: ModalModule,
            providers: providers,

        }
    }
}
