<div class="w-full py-12  inline-block">
  <h1>
    Cards
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <p class="font-medium mb-2">Indstillinger</p>
  <section>
    <div class="w-full flex flex-wrap">
      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-40" [ngClass]="{'active': selectedType.length > 0}">
        <select [(ngModel)]="selectedType" (change)="updateMarkup()" class="input-border-radius input-base">
          <option *ngFor="let type of types" [value]="type.Name">{{type.Name}}</option>
        </select>
        <label class="input-label-base">Vælg type</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedStyle.length > 0}">
        <select [(ngModel)]="selectedStyle" class="input-border-radius input-base">
          <option *ngFor="let style of styles" [value]="style.Name">{{style.Name}}</option>
        </select>
        <label class="input-label-base">Vælg stil</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40 active">
        <select [(ngModel)]="withFavIcon" class="input-border-radius input-base">
          <option *ngFor="let options of favIconOptions" [ngValue]="options.Value">{{options.Name}}</option>
        </select>
        <label class="input-label-base">Med favorit icon</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedColor.length > 0}">
        <select [(ngModel)]="selectedColor" class="input-border-radius input-base {{selectedColor}}">
          <option *ngFor="let Color of Colors" [value]="Color.Name" class="text-white" [ngClass]="Color.Name">{{Color.Name}}</option>
        </select>
        <label class="input-label-base">Vælg bundfarve</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': hasIcon}">
        <select [(ngModel)]="hasIcon" class="input-border-radius input-base">
          <option *ngFor="let options of hasIconOptions" [ngValue]="options.Value">{{options.Name}}</option>
        </select>
        <label class="input-label-base">Med icon</label>
        <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
      </div>

    </div>
    <div class="flex w-full mt-8" *ngIf="hasIcon">
      <div class="form-group">
        <div class="icon-picker-box relative">
          <fa-icon class="text-2xl text-green-500 bg-white" [icon]="[selectedIconStyle, selectedIcon]" (click)="iconSelectorOpen = !iconSelectorOpen"></fa-icon>
          <div class="absolute border rounded-lg left-0 top-0 p-3 bg-white z-20" *ngIf="iconSelectorOpen">
            <fa-icon class=" top-0 right-0 absolute w-5 h-5 flex items-center justify-center" [icon]="['fal', 'times']" (click)="iconSelectorOpen = !iconSelectorOpen"></fa-icon>
            <fa-icon *ngFor="let icon of icons" class="text-2xl text-green-500 bg-white p-2" (click)="[selectedIcon = icon.Name, iconSelectorOpen = false ]" [icon]="[selectedIconStyle, icon.Name]"></fa-icon>
          </div>
        </div>
      </div>

      <div class="select-wrapper input-border-radius bg-white inline-block mb-3 ml-3 w-40" [ngClass]="{'active': selectedIconStyle.length > 0}">
        <select [(ngModel)]="selectedIconStyle" class="input-border-radius input-base">
          <option *ngFor="let iconStyle of iconStyles" [value]="iconStyle.Name">
            {{iconStyle.Name}}
          </option>
        </select>
        <label class="input-label-base">Vælg ikonstil</label>
      </div>

    </div>
    <div class="w-full flex mt-8">

      <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
        <input [(ngModel)]="selectedBodyText" type="text" placeholder=" " class="input-border-radius focus:border-primary input-base" />
        <label class="input-label-base">Vælg body text</label>
      </div>

      <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40 pl-3" *ngIf="selectedType != 'big'">
        <input [(ngModel)]="selectedFooterText" type="text" placeholder=" " class="input-border-radius focus:border-primary input-base" />
        <label class="input-label-base">Vælg footer text</label>
      </div>

    </div>
  </section>
  <p class="font-medium mb-2 mt-10">Implementation</p>

  <section class=" bg-gray-400 w-full   flex p-2 sm:p-5 -mx-2  flex-wrap">


    <card [type]="selectedType" [color]="selectedColor" [style]="selectedStyle" (click)="generateStringContent()" class="inline-block card-base px-2 mb-5">
      <div card-icon>
        <fa-icon [icon]="['fas', 'star']" [ngClass]="isFavorit ? 'text-orange-500' : 'text-white'" class="absolute left-0 top-0 p-1 text-base" (click)="isFavorit = !isFavorit" *ngIf="withFavIcon"></fa-icon>
        <fa-icon [icon]="[selectedIconStyle, selectedIcon]" *ngIf="hasIcon"></fa-icon>
      </div>
      <div card-body>
        <p class="card-body-base">{{selectedBodyText}}</p>
      </div>
      <div card-footer *ngIf="selectedType == 'small'">
        <p class="card-footer-base">{{selectedFooterText}}</p>
      </div>
    </card>



    <card [type]="selectedType" [color]="selectedColor" [style]="selectedStyle" (click)="generateStringContent()" class="inline-block card-base px-2 mb-5">
      <div card-icon>  
        <fa-icon [icon]="['fas', 'star']" [ngClass]="isFavorit ? 'text-orange-500' : 'text-white'" class="absolute left-0 top-0 p-1 text-base" (click)="isFavorit = !isFavorit" *ngIf="withFavIcon"></fa-icon>
        <fa-icon [icon]="[selectedIconStyle, selectedIcon]" *ngIf="hasIcon"></fa-icon>
      </div>
      <div card-body>
        <p class="card-body-base">{{selectedBodyText}}</p>
      </div>
      <div card-footer *ngIf="selectedType == 'small'">
        <p class="card-footer-base">{{selectedFooterText}}</p>
      </div>
    </card>
    <card [type]="selectedType" [color]="selectedColor" [style]="selectedStyle" (click)="generateStringContent()" class="card-base inline-block px-2">
      <div card-icon>
        <fa-icon [icon]="['fas', 'star']" [ngClass]="isFavorit ? 'text-orange-500' : 'text-white'" class="absolute left-0 top-0 p-1 text-base" (click)="isFavorit = !isFavorit" *ngIf="withFavIcon"></fa-icon>
        <fa-icon [icon]="[selectedIconStyle, selectedIcon]" *ngIf="hasIcon"></fa-icon>
      </div>
      <div card-body>
        <p class="card-body-base">{{selectedBodyText}}</p>
      </div>
      <div card-footer *ngIf="selectedType == 'small'">
        <p class="card-footer-base">{{selectedFooterText}}</p>
      </div>
    </card>
  </section>
  <p class="mb-5 font-medium text-xs">{{hasCopied ? 'Den er kopieret' : 'Klik på Card for at kopiere den!'}}</p>

  <p class="font-medium mb-2 mt-10">Snippet</p>
  <p class="text-gray-700">comCard</p>
</div>
