<div class="incom-modal-header text-black bg-white">
  <h2 class="pl-4 pr-4">
      Du har lavet en ændring
  </h2>
  <fa-icon class="incom-modal-close-icon" (click)="close()" [icon]="['fal', 'times']"> </fa-icon>
</div>
<div class="incom-modal-content">
  <div class="mt-2">
      <p class="font-bold">Du har lavet en ændring.</p>
      <p>Som gør at elementet bliver låst. Er du klar over konsenkvenserne?</p>
  </div>
</div>
<div class="incom-modal-footer p-3">
  <button class="btn btn-accept btn-outline btn-border-radius float-right disabled:opacity-50" (click)="confirm($event)">
      <fa-icon class="text-base mr-2 leading-none" [icon]="['far', 'check']"> </fa-icon><span
          class="font-medium first-letter-capitalize">Ok</span>
  </button>
</div>