import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalBase } from '../../../../projects/bexcomponent-design/src/lib/_modal/IModalBase.interface';
@Component({
    selector: 'modal-test',
    templateUrl: './modaltest.component.html',
    host: {
        class: 'flex h-full flex-col',
    },
})
export class ModalTestComponent implements ModalBase {
    scrollStrategy: undefined | "noop" | "block" | 'reposition' = 'block';

    constructor() { }

    close() {
        this.closed$.next(false);
        this.onClose();
    }
    success() {
        this.closed$.next(true);
        this.onSuccess();
    }

    isFavorit: boolean;
    onClose: Function;
    onSuccess: Function;
    data: any;
    closed$: Subject<boolean> = new Subject;
}
