import { Component, OnInit } from '@angular/core';
import { LoginModel } from '../../Login/loginModel';

@Component({
  selector: 'login-presenter',
    templateUrl: './login-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    },
  styleUrls: ['./login-presenter.component.css']
})
export class LoginPresenterComponent implements OnInit {
    passwordRevealed: boolean = false;
    activeTab: string = 'password';
  constructor() { }

  ngOnInit() {
  }
    model = new LoginModel();
    togglePasswordReveal(): void {
        this.passwordRevealed = !this.passwordRevealed;
    }
}
