import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { FaIconComponent } from '../../projects/bexcomponent-design/src/lib/fa-icon/fa-icon.component';



@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ComponentsModule,
        FaIconComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
