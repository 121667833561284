/**
 * Grouplist extension - Checked logic 
 * LAST UPDATED 07-04-2022 by MSOHN@eg.dk
 * This implementation is an abstraction to the group-list component.
 * And extends the functionality by adding a `checkbox` funcionality to a grouped list.
 * Extend to the `GroupListCheckedComponent` and use
 * `onCategoryCheckBoxChange` on category changed 
 * `onCategoryItemCheckBoxChange` on category item changed 
 * ex. of use: 
 * <input type="checkbox" [(ngModel)]="category.visualChecked"  (change)="onCategoryCheckBoxChange($event, category)" />
 * <input type="checkbox" [(ngModel)]="categoryItem.visualChecked"  (change)="onCategoryItemCheckBoxChange($event, category)" />
 * 
 * Ex.
 * A:  If part of a node tree is checked the root parent node will be marked as indeterminate checked.
 * B:  If all of a node tree is checked the root parent node will be marked as (full)checked
 * C:  If all of a node tree is checked the root parent node will be marked as (full)checked
 * Ad: If all of a sub node tree is checked the closest parent will be marked as (full)checked 
 * 
 * A (indeterminate checked) gategory
 * |- Aa (x)
 * |- Ab (x)
 * |- Ac (indeterminate checked) categoryItem with as sub-category
 *    |- ACa (x)
 *    |- ACb  
 * |- Ad (checked) categoryItem with as sub-category
 *    |- ADa (x)
 *    |- ADb (x)
 * 
 * B (checked) gategory
 * |- Ba (x)
 * |- Bb (x)
 * |- Bc (x)
 * |- Bd (x)
 * 
 * C (checked) gategory
 * |- Ca (x)
 * |- Cb (c)
 * |- Cc (checked) categoryItem with as sub-category
 *    |- CCa (x)
 *    |- CCb (x)
 */

import { IGroupedListCategoryItemModel, IGroupedListComponentModel, IGroupedListKeyModel } from "../../group-list.models";
import { IGroupedListCheckedCategoryItemModelExtended, IGroupedListCheckedCategoryModelExtended } from "./group-list-checked.models";


export class GroupListCheckedComponent {
    model: IGroupedListComponentModel;


    /**
     * Use this method on category checkbox change
     * This method ensures child and parents to be updated correctly 
     * and updates checked counts
     * @param $event not in use currently
     * @param category extended check category model
     */
    onCategoryCheckBoxChange($event: any, category: IGroupedListCheckedCategoryModelExtended): void {

        category.checked = category.visualChecked;
        category.checkedIndeterminate = false;
        this.setCheckedStatusForCategory(category, category.checked);

        const parents = this.tryResolveParentsBy(category);
        this.tryResolveCategoryCheckedByParents(parents);
        //fallback to current category if parents is null
        //parents will be null, if the current category is root
        this.updateCountForRootCategory([category, ...parents]);
    }


    /**
     * Use this method on category child item checkbox change
     * This method ensures child and parents to be updated correctly 
     * and updates checked counts
     * @param $event not in use currently
     * @param category extended check category child item model
     */
    onCategoryItemCheckBoxChange($event: any, categoryItem: IGroupedListCheckedCategoryItemModelExtended): void {
        categoryItem.checked = categoryItem.visualChecked;
        const parents = this.tryResolveParentsBy(categoryItem);
        this.tryResolveCategoryCheckedByParents(parents);
        this.updateCountForRootCategory(parents);
    }

    /**
     * Set checked value for all childs for a giving category
     * @param category extended check category model
     * @param checked true/false
     * @returns 
     */
    setCheckedStatusForCategory(category: IGroupedListCheckedCategoryModelExtended, checked: boolean): void {
        if (!(category || category.children)) return;

        category.children.forEach((child: IGroupedListCheckedCategoryItemModelExtended) => {
            child.checked = checked;
            child.checkedIndeterminate = false;
            child.visualChecked = checked;

            if (!child.subCategories) return;
            child.subCategories.forEach((subCategory: IGroupedListCheckedCategoryModelExtended) => {
                subCategory.visualChecked = checked;
                subCategory.checked = checked;
                subCategory.checkedIndeterminate = false;
                this.setCheckedStatusForCategory(subCategory, checked);
            });
        });
    }

    /**
     * Update count from last parent, we assume this is the root parent.
     * @param parents parent collection where last is root
     */
    updateCountForRootCategory(parents: IGroupedListCheckedCategoryModelExtended[]): void {
        const rootParent = parents[parents.length - 1];
        this.updateCountForCategory(rootParent);
    }

    /**
     * Recursively resolve checked count from parent to childs
     * @param category category to set count of checked nodes
     * @returns count of checked nodes for given hierarchy
     */
    updateCountForCategory(category: IGroupedListCheckedCategoryModelExtended): number {
        let count = 0;
        if (category && category.children) {

            category.children.forEach((child: IGroupedListCheckedCategoryItemModelExtended) => {
                if (!child.isSubCategory && child.visualChecked) {
                    count++;
                }

                if (child.isSubCategory) {
                    child.subCategories.forEach((subCategory: IGroupedListCheckedCategoryModelExtended) => {
                        count += this.updateCountForCategory(subCategory);
                    });
                }
            });

            category.checkedCount = count;

        }
        return count;
    }



    /**
     * Try to resolve parents from node
     * @param node Find parents for node
     * @returns A collection of parents, where first should be closest
     */
    tryResolveParentsBy(node: IGroupedListKeyModel): IGroupedListCheckedCategoryModelExtended[] {
        let parent = node.parent;
        const parents: IGroupedListCheckedCategoryModelExtended[] = [];

        while (parent) {
            parents.push(parent as IGroupedListCheckedCategoryModelExtended);
            parent = parent.parent;
        }
        return parents;
    }



    /**
     * Try resolve check status for a collection of parents in order by hierarchy level
     * !IMPORTANT - SHOULD BE sorted in highest level ASC, we have to resolve level from down to top! 
     * @param parents 
     */
    tryResolveCategoryCheckedByParents(parents: IGroupedListCheckedCategoryModelExtended[]): void {
        for (let parent of parents) {
            this.tryResolveCategoryChecked(parent);
        }
    }

    /**
     * Try resolve hierarchy level check status for child nodes
     * If all child is checked then mark parentRef with checked
     * If any childs is checked and NOT all mark the parentRef as indenterminate checked.
     * @param parentRef parent to resolve
     */
    tryResolveCategoryChecked(parentRef: IGroupedListCheckedCategoryModelExtended): void {
        const [allChildChecked, anyChildChecked] = this.hasCategoryAllOrAnyChecked(parentRef);
        parentRef.checkedIndeterminate = !allChildChecked && anyChildChecked;
        parentRef.checked = allChildChecked;
        parentRef.visualChecked = parentRef.checked || parentRef.checkedIndeterminate;
    }

    /**
     * Has category all or any child nodes checked
     * @param parentRef Category
     * @returns A boolean tuple [allChildChecked, anyChildChecked]
     */
    hasCategoryAllOrAnyChecked(parentRef: IGroupedListCheckedCategoryModelExtended): [boolean, boolean] {
        let hasChildAnyChecked =  this.checkIfCategoryChildrenHasAnyChecked(parentRef.children);
        let hasSubCategoriesChecked = this.checkIfSubCategoriesHasAnyChecked(parentRef.children);
        const anyChildChecked: boolean = hasChildAnyChecked || hasSubCategoriesChecked;

        let hasChildEveryChecked = this.checkIfCategoryChildrenHasAllChecked(parentRef.children);
        let hasSubCategoriesEveryChecked = this.checkIfSubCategoriesHasEveryChecked(parentRef.children);
        const allChildChecked = hasChildEveryChecked && hasSubCategoriesEveryChecked;

        return [allChildChecked, anyChildChecked];
    }



    /**
     * Check if any children on a category is selected/marked
     * @param children A collection of category items
     * @returns true if any category items is checked
     */
    checkIfCategoryChildrenHasAnyChecked(children: IGroupedListCategoryItemModel[]): boolean {
        if(!children || children.length == 0) return false;
        return children.some((child: IGroupedListCheckedCategoryItemModelExtended) => {
            return child.checked && !child.isSubCategory;
        });
    }


    /**
     * Check if all children on a category is selected/marked
     * @param children A collection of category items
     * @returns true if any category items is checked
     */
    checkIfCategoryChildrenHasAllChecked(children: IGroupedListCategoryItemModel[]): boolean {
        if(!children || children.length == 0) return false;
        return children.every((child: IGroupedListCheckedCategoryItemModelExtended) => {
            return child.checked || child.isSubCategory; //if item has sub categories act as non-item
        });
    }


    /**
     * Check if any sub-category in the collection of category items is selected
     * @param children A collection of category items
     * @returns true if any category items is checked
     */
    checkIfSubCategoriesHasAnyChecked(children: IGroupedListCategoryItemModel[]): boolean {
        if(!children || children.length == 0) return false;
        return children.some((child: IGroupedListCheckedCategoryItemModelExtended) => {
            return child.isSubCategory && child.subCategories.some((subCategory: IGroupedListCheckedCategoryModelExtended) => {
                return subCategory.checked || subCategory.checkedIndeterminate;
            });
        });
    }

    /**
     * Check if all sub-category in the collection of category items is selected
     * @param children A collection of category items
     * @returns true if any category items is checked
     */
    checkIfSubCategoriesHasEveryChecked(children: IGroupedListCategoryItemModel[]): boolean {
        if(!children || children.length == 0) return false;
        return children.every((child: IGroupedListCheckedCategoryItemModelExtended) => {
            if (!child.isSubCategory)
                return true;

            return child.subCategories.every((subCategory: IGroupedListCheckedCategoryModelExtended) => {
                return subCategory.checked;
            });
        });
    }

   
} 
