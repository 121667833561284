/**
 * Grouplist extension - Listen for node clicks
 */
import { Component, EventEmitter, Output } from "@angular/core";
import { IGroupedListComponentModel, IGroupedListKeyModel } from "../../group-list.models";

@Component({ template: "" })
export class GroupListSelectorComponent {
    model: IGroupedListComponentModel;

    @Output()
    onNodeClickEmitter = new EventEmitter<IGroupedListKeyModel>();

    onNodeClick(node: IGroupedListKeyModel){    
        this.onNodeClickEmitter.emit(node);
    }
}
