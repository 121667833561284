import { Injectable } from '@angular/core';

@Injectable()
export class GrowlerService {
    growl: (message: string, growlType?: GrowlerMessageType, position?: GrowlerPosition, timeout?: number) => number;
}

export enum GrowlerMessageType {
    Success,
    Danger,
    Warning,
    Info
}
export enum GrowlerPosition {
    TopLeft,
    Top,
    TopRight,
    MiddleLeft,
    Middle,
    MiddleRight,
    BottomLeft,
    Bottom,
    BottomRight
}
export interface IGrowl {
    enabled: boolean;
    timeoutId: number;
    show(): void;
    setTimeout(): void;
    hide(): void;
    messageType: string;
    message: string;
    position: GrowlerPosition;
}
