import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldPresenter } from './input-field/input-field-presenter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsComponent } from './components.component'
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ButtonPresenterComponent } from './button-presenter/button-presenter.component';
import { CardPresenterComponent } from './card-presenter/card-presenter.component';
import { CustomInputPresenterComponent } from './custom-input-presenter/custom-input-presenter.component';
import { ScrollspyPresenterComponent } from './scrollspy-presenter/scrollspy-presenter.component';
import { ModalPresenterComponent } from './modal-presenter/modal-presenter.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModalTestComponent } from './modal-presenter/modaltest.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionPresenterComponent } from './accordion-presenter/accordion-presenter.component';
import { BEXComponentDesignModule } from '../../../projects/bexcomponent-design/src/public-api';
import { IncomUtilityLibraryModule } from "incom-utility-library";
import { ListFoldOutPresenterComponent } from './list-fold-out-presenter/list-fold-out-presenter.component';
import { LoaderPresenterComponent } from './loader-presenter/loader-presenter.component';
import { FoldOutPresenterComponent } from './fold-out-presenter/fold-out-presenter.component';
// import { AngularMyDatePickerModule } from 'angular-mydatepicker'; //DEPRECATED - legacy view renders is no longer supported. Leave this line until date picker is migrated.
// import { DatepickerPresenterComponent } from './datepicker-presenter/datepicker-presenter.component';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { IconsComponent } from './icons/icons.component';
import { AuthGuard } from '../Login/auth.guard';
import { LoginWithPasswordComponent } from "src/app/Login/login-with-password.component";
import { AuthenticationService } from "src/app/Login/authentication.service";
import { LoginPresenterComponent } from './login-presenter/login-presenter.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TextPresenterComponent } from './text-presenter/text-presenter.component';
import { GrowlPresenterComponent } from './growl-presenter/growl-presenter.component';
import { ListFoldOutItemComponent } from './list-fold-out-presenter/list-fold-out-item/list-fold-out-item.component';
import { LabeledTogglePresenterComponent } from './labeled-toggle-presenter/labeled-toggle-presenter.component';
import { MultiButtonPresenterComponent } from './multi-button-presenter/multi-button-presenter.component';
// import { SliderPresenterComponent } from './slider-presenter/slider-presenter.component';
import { TogglePresenterComponent } from './toggle-presenter/toggle-presenter.component';
import { ChartCircularPercentagePresenterComponent } from './chart-circular-percentage-presenter/chart-circular-percentage-presenter.component';
import { LockerPresenterComponent } from './locker-presenter/locker-presenter.component';
import { UserLockModalComponent } from './locker-presenter/user-lock-modal/user-lock-modal.component';
import { UserUnlockModalComponent } from './locker-presenter/user-unlock-modal/user-unlock-modal.component';
import { GroupListPresenterComponent } from './group-list-presenter/group-list-presenter.component';
import { GroupListCheckedPresenterComponent } from './group-list-checked-presenter/group-list-checked-presenter.component';
import { FaIconComponent } from '../../../projects/bexcomponent-design/src/lib/fa-icon/fa-icon.component';

const componentRoutes: Routes = [
    { path: 'buttons', component: ButtonPresenterComponent, canActivate: [AuthGuard] },
    { path: 'cards', component: CardPresenterComponent, canActivate: [AuthGuard] },
    { path: 'custom-input', component: CustomInputPresenterComponent, canActivate: [AuthGuard] },
    { path: 'scrollspy', component: ScrollspyPresenterComponent, canActivate: [AuthGuard] },
    { path: 'inputfield', component: InputFieldPresenter, canActivate: [AuthGuard] },
    { path: 'modal-presenter', component: ModalPresenterComponent, canActivate: [AuthGuard] },
    { path: 'accordion-presenter', component: AccordionPresenterComponent, canActivate: [AuthGuard] },
    { path: 'list-fold-out-presenter', component: ListFoldOutPresenterComponent, canActivate: [AuthGuard] },
    { path: 'loader-presenter', component: LoaderPresenterComponent, canActivate: [AuthGuard] },
    { path: 'fold-out-presenter', component: FoldOutPresenterComponent, canActivate: [AuthGuard] },
    // { path: 'datepicker-presenter', component: DatepickerPresenterComponent, canActivate: [AuthGuard] },
    { path: 'bottom-menu', component: BottomMenuComponent, canActivate: [AuthGuard] },
    { path: 'icons', component: IconsComponent, canActivate: [AuthGuard] },
    { path: 'text-presenter', component: TextPresenterComponent },
    { path: 'login', component: LoginWithPasswordComponent },
    { path: 'login-presenter', component: LoginPresenterComponent },
    { path: 'growl-presenter', component: GrowlPresenterComponent },
    { path: 'labeled-toggle-presenter', component: LabeledTogglePresenterComponent },
    { path: 'multi-button-presenter', component: MultiButtonPresenterComponent },
    // { path: 'slider-presenter', component: SliderPresenterComponent },
    { path: 'toggle-presenter', component: TogglePresenterComponent },
    { path: 'chart-circular-percentage', component: ChartCircularPercentagePresenterComponent },
    { path: 'locker-presenter', component: LockerPresenterComponent },
    { path: 'group-list-presenter', component: GroupListPresenterComponent },
    { path: 'group-list-checked-presenter', component: GroupListCheckedPresenterComponent },

    {
        path: '',
        redirectTo: 'buttons',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ScrollingModule,
        BrowserAnimationsModule,
        BEXComponentDesignModule,
        // AngularMyDatePickerModule,
        LayoutModule,
        IncomUtilityLibraryModule,
        RouterModule.forRoot(componentRoutes, {}),
        FaIconComponent
    ],
    providers: [
        AuthGuard,
        AuthenticationService
    ],
    declarations: [
        LoginWithPasswordComponent,
        InputFieldPresenter,
        ComponentsComponent,
        ButtonPresenterComponent,
        CardPresenterComponent,
        CustomInputPresenterComponent,
        ScrollspyPresenterComponent,
        ModalPresenterComponent,
        ModalTestComponent,
        AccordionPresenterComponent,
        ListFoldOutPresenterComponent,
        LoaderPresenterComponent,
        FoldOutPresenterComponent,
        // DatepickerPresenterComponent,
        BottomMenuComponent,
        IconsComponent,
        LoginPresenterComponent,
        TextPresenterComponent,
        GrowlPresenterComponent,
        ListFoldOutItemComponent,
        LabeledTogglePresenterComponent,
        MultiButtonPresenterComponent,
        // SliderPresenterComponent,
        TogglePresenterComponent,
        ChartCircularPercentagePresenterComponent,
        LockerPresenterComponent,
        UserLockModalComponent,
        UserUnlockModalComponent,
        GroupListPresenterComponent,
        GroupListCheckedPresenterComponent
    ],
    exports: [
        ComponentsComponent
    ]
})
export class ComponentsModule {
}
