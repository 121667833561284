import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-presenter',
    templateUrl: './card-presenter.component.html',
    host: {
        class: 'w-full inline-block',
    },
  styleUrls: ['./card-presenter.component.css']
})
export class CardPresenterComponent implements OnInit {
  selectedIcon: string = "ballot-check";
  selectedIconStyle: string = "far";
  selectedBodyText: string = "";
  selectedFooterText: string = "";
  docLink: string = "https://incomit.atlassian.net/wiki/spaces/BOL/pages/343900163/Cards"
  iconSelectorOpen: boolean = false;
  selectedType: string = "big";
  selectedStyle: string = "solid";
  selectedColor: string = "text-gray-700";
  hasCopied: boolean = false;
  hasIcon: boolean = true;
  withFavIcon: boolean = true;
  footerMarkup:string = ''
  iconMarkup: string = '<fa-icon [icon]="[\'' + this.selectedIconStyle + '\', \'' + this.selectedIcon + '\']" ></fa-icon>'
  favIconHTML: string = '<fa-icon [icon]="[\'fas\', \'star\']" [ngClass]="isFavorit ? \'text-orange-500\' : \'text-white\'" class="absolute left-0 top-0 p-1 text-base" (click)="isFavorit = !isFavorit"></fa-icon>'

  types = [
    { Name: "big" },
    { Name: "small" },
  ]

  favIconOptions = [
    { Name: "Ja", Value: true },
    { Name: "Nej", Value: false },
  ]
  hasIconOptions = [
    { Name: "Ja", Value: true },
    { Name: "Nej", Value: false },
  ]

  Colors = [
    { Name: "text-black" },
    { Name: "text-white" },
    { Name: "text-gray-300" },
    { Name: "text-primary" },
    { Name: "text-helpdesk" },
    { Name: "text-red-400" },
    { Name: "text-orange-400" },
    { Name: "text-yellow-400" },
    { Name: "text-green-400" },
    { Name: "text-teal-400" },
    { Name: "text-blue-400" },
    { Name: "text-indigo-400" },
    { Name: "text-purple-400" },
    { Name: "text-pink-400" },
  ]

  styles = [
    { Name: "outline" },
    { Name: "solid" },
  ]

  icons = [
    { Name: "ballot-check" },
    { Name: "ban" },
    { Name: "check" },
    { Name: "minus-circle" },
    { Name: "search" },
  ]

  iconStyles = [
    { Name: "far" },
    { Name: "fal" },
    { Name: "fas" },
  ]
  constructor() { }

  updateMarkup() {
    if (this.selectedType == "small") {

        this.footerMarkup = '<div card-footer> <p class="card-footer-base"> ' + this.selectedFooterText + ' </p> </div>'
    }
    else {
      this.footerMarkup = ""
    }

  }

  ngOnInit() {
  }
  generateStringContent() {
    this.updateMarkup()
      let textbox = document.createElement('textarea')
      textbox.style.position = "fixed";
      textbox.value = '<card class="card-base inline-block px-2 mb-5" [type]="\'' + this.selectedType + '\'"  [style]="\'' + this.selectedStyle + '\'"  [color]="\'' + this.selectedColor + '\'"><div card-icon>' + (this.hasIcon ? this.iconMarkup : "") + (this.withFavIcon ? this.favIconHTML : "") + '</div><div card-body> <p class="card-body-base">' + this.selectedBodyText + '</p > </div>' + this.footerMarkup + '</card > ';
    document.body.appendChild(textbox);
    textbox.focus();
    textbox.select();
    document.execCommand('copy');
    document.body.removeChild(textbox);
    this.hasCopied = true;
    setTimeout(() => { this.hasCopied = false }, 3000);
  }
}
