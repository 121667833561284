import { Component, Input, OnChanges, SimpleChanges, HostListener } from "@angular/core";

@Component({
    selector: 'button-component',
    templateUrl: 'button.component.html'
})

export class buttonComponent implements OnChanges {
    @Input() type: string = "accept";
    @Input() style: string;
    @Input() hasError: boolean = false
    errorClass: string = "";
    constructor() { }
    @HostListener('click')
    clickout(): void {
        if (this.hasError && this.errorClass === "") {
            this.errorClass = " submit-error";
            setTimeout(() => { this.errorClass = "" }, 750);
        }
    }

    buttonType: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type || changes.style) {
            if (this.type && this.style) {
                this.buttonType = 'btn-' + this.type + ' ' + 'btn-' + this.style;
            }
        }
    }

}
