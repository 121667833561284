
<div class="w-full py-12 inline-block">
  <h2 class="font-bold mb-2 text-2xl leading-none">
    list options
  </h2>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-semibold"> Se dokumentation</p>
  </a>
  <div class="mt-10 sm:p-10 p-3 bg-gray-300">
    <section class="mx-auto mt-5 container">
      <div class="p-3 sm:p-5 lg:p-8 mt-5 bg-white rounded-lg shadow-md">
        <ul class="inline-block pb-5 md:mb-0 md:pb-0 list-base md:pt-2 px-0">
          <li class="list-reset text-sm xl:flex text-gray-600  items-center leading-loose overflow-hidden hidden py-2">
            <div class="w-3/12">Spørgsmål</div>
            <div class="w-2/12">Type</div>
            <div class="w-2/12">Følgespørgsmål</div>
            <div class="w-1/12">Resourcer</div>
            <div class="w-2/12">Oprettet af</div>
            <div class="w-1/12"> </div>

          </li>
          <li *ngFor="let item of model;  index as i; let last = last" class="list-reset leading-loose xl:border-t border-gray-600 relative">
            <list-fold-out-item  [item]="item" class="w-full flex"></list-fold-out-item>
          </li>

        </ul>
      </div>
    </section>
  </div>
</div>
