<div class="w-full py-12 inline-block">
  <h1>
    Percentage chart
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>


  <section>
    <p class="font-medium mb-2">Settings</p>
    <div class="w-full flex">

    

      <div class="w-1/3 flex flex-row flex-wrap justify-evenly">

        <div class="flex flex-col text-center">
          <lib-chart-circular-percentage [status]="selectedType" [value]="chartValue" [text]="chartText"></lib-chart-circular-percentage>
          <div class="w-full">
            <p class="truncate text-lg">{{descriptionHeader}}</p>
            <p>
              <span>{{descriptionTxt}}</span>
              <span class="inline-block pl-2">
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="select-wrapper input-border-radius bg-white inline-block mb-3 w-40"
          [ngClass]="{'active': selectedType}">
          <select [(ngModel)]="selectedType" tabindex="0" class="input-border-radius input-base focus:border-primary">
            <option *ngFor="let type of types" [value]="type">{{type}}</option>
          </select>
          <label class="input-label-base">Vælg type</label>
          <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
        </div>


        <div class="flex w-full mt-8">
          <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
            <input [(ngModel)]="chartValue" type="number" placeholder=" "
              class="input-border-radius focus:border-primary input-base" />
            <label class="input-label-base">Chart Value</label>
          </div>
        </div>

        <div class="flex w-full mt-8">
          <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-40">
            <input [(ngModel)]="chartText" type="text" placeholder=" "
              class="input-border-radius focus:border-primary input-base" />
            <label class="input-label-base">Chart text</label>
          </div>
        </div>


        <div class="flex w-full mt-8">
          <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-96">
            <input [(ngModel)]="descriptionHeader" type="text" placeholder=" "
              class="input-border-radius focus:border-primary input-base" />
            <label class="input-label-base">Description header</label>
          </div>
        </div>

        <div class="flex w-full mt-8">
          <div class="mb-3 input-wrapper input-border-radius bg-white inline-block w-96">
            <input [(ngModel)]="descriptionTxt" type="text" placeholder=" "
              class="input-border-radius focus:border-primary input-base" />
            <label class="input-label-base">Description Text</label>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="mt-20">
    <p class="font-medium mb-2">Examples</p>
    <div class="w-full flex flex-col">
      <div class="flex flex-row flex-wrap justify-evenly">

        <div class="flex flex-col text-center w-1/2 sm:w-1/4">
          <lib-chart-circular-percentage [status]="'green'" [value]="45" [text]="'55.237,38 DKK'">
          </lib-chart-circular-percentage>
          <div class="w-full">
            <p class="truncate text-lg">Almindelig vedligeh. i alt</p>
            <p>
              <span>70.362,62 DKK</span>
              <span class="inline-block pl-2">
                <!-- <help-text class="w-2/12 pl-2 fa-icon-primary-color inline-block" [header]="percentageModel.name"
                [textDescription]="percentageModel.accountHelperText" [placement]="'bottom'" [iconName]="'info-circle'"
                [includeHeadline]="'true'"></help-text> -->
              </span>
            </p>

          </div>

        </div>
        <div class="flex flex-col text-center w-1/2 sm:w-1/4">
          <lib-chart-circular-percentage [status]="'green'" [value]="55" [text]="'828.428,52 DKK'">
          </lib-chart-circular-percentage>
          <div class="w-full">
            <p class="truncate text-lg">Planlagt og periodisk vedligeholdelse</p>
            <p>
              <span>675.571,48 DKK</span>
              <span class="inline-block pl-2">
                <!-- <help-text class="w-2/12 pl-2 fa-icon-primary-color inline-block" [header]="percentageModel.name"
                [textDescription]="percentageModel.accountHelperText" [placement]="'bottom'" [iconName]="'info-circle'"
                [includeHeadline]="'true'"></help-text> -->
              </span>
            </p>

          </div>

        </div>
        <div class="flex flex-col text-center w-1/2 sm:w-1/4">
          <lib-chart-circular-percentage [status]="'red'" [value]="100" [text]="'-33.193,58 DKK'">
          </lib-chart-circular-percentage>
          <div class="w-full">
            <p class="truncate text-lg">114 - Renholdelse i alt</p>
            <p>
              <span>268.853,58 DKK</span>
              <span class="inline-block pl-2">
                <!-- <help-text class="w-2/12 pl-2 fa-icon-primary-color inline-block" [header]="percentageModel.name"
                [textDescription]="percentageModel.accountHelperText" [placement]="'bottom'" [iconName]="'info-circle'"
                [includeHeadline]="'true'"></help-text> -->
              </span>
            </p>

          </div>

        </div>

      </div>
    </div>
  </section>
</div>