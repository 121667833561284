<section class="  w-full inline-block py-12 ">
  <h1>
    Growls
  </h1>
  <a [href]="docLink" class="text-primary mb-4 inline-block" target="_blank">
    <p class="font-medium"> Se dokumentation</p>
  </a>
  <div class="w-full flex mt-10">
    <div class="mb-3 textarea-wrapper textarea-border-radius bg-white inline-block md:w-1/3 w-full">
      <textarea [(ngModel)]="selectedText" type="text" placeholder=" " class="textarea-border-radius focus:border-primary textarea-base" ></textarea>
      <label class="input-label-base">Skriv text til growl</label>
    </div>
  </div>
  <div class="w-full flex flex-col">

    <div class="select-wrapper input-border-radius bg-white inline-block mb-3 md:w-1/3 w-full" [ngClass]="{'active': selectedType}">
      <select [(ngModel)]="selectedType" class="input-border-radius input-base" >
        <option *ngFor="let type of types" [value]="type.Type">{{type.Name}}</option>
      </select>
      <label class="input-label-base">Vælg growl type</label>
      <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
    </div>

    <div class="select-wrapper input-border-radius bg-white inline-block mb-3 md:w-1/3 w-full active">
      <select [(ngModel)]="selectedPosition" class="input-border-radius input-base" (ngModelChange)="onPositionChange($event)">
        <option *ngFor="let position of positions" [value]="position.Place">{{position.Name}}</option>
      </select>
      <label class="input-label-base">Vælg position på skærmen</label>
      <fa-icon [icon]="['fal','angle-down']" class="input-icon"></fa-icon>
    </div>

    <div class="w-full flex">
      <div class="mb-3 input-wrapper input-border-radius bg-white inline-block md:w-1/3 w-full">
        <input [(ngModel)]="duration" type="number" placeholder=" " class="input-border-radius focus:border-primary input-base" />
        <label class="input-label-base">Varighed i milisekunder</label>
      </div>
    </div>
  </div>
  <div class="w-full py-5">
    <div class="w-full">
      <button-component [type]="'primary'" [style]="'solid'" (click)="openFeedback()" class="inline-block w-1/5"><fa-icon class="text-2xl mr-2 leading-none" [icon]="['fal', 'search']"> </fa-icon><span class="font-medium">Vis growl</span></button-component>
    </div>
  </div>
</section>
